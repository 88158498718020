import { Button, ButtonSize, ButtonType, ButtonVariant } from '@xemplo/button';
import { PAYRUN_COMPONENTS } from '@xemplo/gp-constants';
import { AmendmentStatusIds, AmendmentTypes } from '@xemplo/gp-types';
import { Prompt } from '@xemplo/prompts';
import { RoleRenderer } from '@xemplo/role-renderer';
import { AuthorisationModule } from '@xemplo/role-types';
import { useRoleAuthorisation } from '@xemplo/roles-query';

import { PayrunResubmitAmendmentProps } from './payrun-resubmit-amendment.types';
import { useResubmitPrompt } from './use-resubmit-prompt';

export const PayrunResubmitAmendmentTestId = {
  ResubmitButton: 'resubmit-button',
};

export function PayrunResubmitAmendment(props: Readonly<PayrunResubmitAmendmentProps>) {
  const { amendment } = props;

  const { promptProps, promptVisible, setPromptVisible } = useResubmitPrompt({
    ...props,
  });

  const { componentDisabled } = useRoleAuthorisation(AuthorisationModule.payrun);
  return (
    <RoleRenderer
      module={AuthorisationModule.payrun}
      component={PAYRUN_COMPONENTS.AMENDMENT_SUBMIT}
      additionalRules={[
        () =>
          amendment.amendmentStatusId === AmendmentStatusIds.REJECTED &&
          amendment.amendmentTypeId === AmendmentTypes.PAYRUN,
      ]}
    >
      <Button
        ariaLabel="resubmit amendment"
        onClick={() => setPromptVisible(true)}
        testId={PayrunResubmitAmendmentTestId.ResubmitButton}
        variant={ButtonVariant.Light}
        type={ButtonType.Primary}
        size={ButtonSize.Medium}
        disabled={componentDisabled(PAYRUN_COMPONENTS.AMENDMENT_SUBMIT)}
      >
        Resubmit
      </Button>
      <Prompt
        {...promptProps}
        open={promptVisible}
        onCloseCallback={() => setPromptVisible(false)}
      />
    </RoleRenderer>
  );
}

export default PayrunResubmitAmendment;
