import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "antd";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import {
  errorDisplay,
  getCurrentTableSortInfo,
  getSortOrderRequestValue,
  mustBeArray,
  trimWhiteSpaces,
} from "../../../libs/utilities";
import AssignPayrollAdminList from "./AssignPayrollAdminList";
import useDebounce from "../../../Common/UseDebounce";

interface AssignPayrollAdminFormProps {
  list: Array<any>;
  assignedPayrollAdminList: Array<any>;
  handleChange: (e: any) => void;
  listPSPUsers: (e: any) => Promise<any>;
  handleAssignChange: (e: any) => void;
}

const AssignPayrollAdminForm = (props: AssignPayrollAdminFormProps) => {
  const signal = axios.CancelToken.source();
  const [filters, setFilters] = useState({ name: "" });
  const [loading, setLoading] = useState(true);
  const [sortInfo, setSortInfo] = useState({ field: "", order: "" });
  const [payrollProvdersList, setPayrollProvidersList] = useState<Array<any>>(
    []
  );
  const { assignedPayrollAdminList, listPSPUsers, handleAssignChange } = props;
  const debouncedNameFilter = useDebounce(filters.name, 500);

  useEffect(() => {
    loadPayrollProviderUsersList();
    return () => {
      signal.cancel("Api is being canceled");
    };
  }, [sortInfo.order, debouncedNameFilter]);

  const getRequestParams = () => {
    let requestParams: any = {
      options: {
        per_page: 0,
        ...getCurrentTableSortInfo({ sortInfo }),
        q: {
          name: trimWhiteSpaces(filters?.name),
        },
      },
    };
    if (trimWhiteSpaces(filters?.name)) {
      requestParams.q = {
        name: trimWhiteSpaces(filters?.name),
      };
    }
    return requestParams;
  };

  const loadPayrollProviderUsersList = () => {
    setLoading(true);
    return listPSPUsers({
      ...getRequestParams(),
      cancelToken: signal.token,
    }).then((resp) => {
      setLoading(false);
      let data = mustBeArray(resp.data).filter((o) => o.enabled && o.activated);
      if (resp.status) {
        setPayrollProvidersList(data);
      } else {
        errorDisplay(resp?.data?.validationErrors);
      }
      return resp;
    });
  };

  const handleFilter = () => {
    console.log("");
  };

  const handleChange = (value?: string) => {
    setFilters({ name: value || "" });
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortInfo({
      order: getSortOrderRequestValue({ sorter }),
      field: sorter?.field,
    });
  };

  return (
    <React.Fragment>
      <Row gutter={DEFAULT_GUTTER}>
        <Col span={12}>
          <div className="mb-2 pl-2">Users</div>
          <SingleFieldTextBoxFilter
            placeholder="Search User"
            value={filters?.name}
            onChange={(value) => handleChange(value)}
            onSearch={handleFilter}
            ignoreStatus={true}
            className="w-full  mb-6"
          />
        </Col>
      </Row>
      <AssignPayrollAdminList
        list={payrollProvdersList}
        loading={loading}
        handleTableChange={handleTableChange}
        sortInfo={sortInfo}
        handleAssignChange={handleAssignChange}
        assignedPayrollAdminList={assignedPayrollAdminList}
      />
    </React.Fragment>
  );
};

export default AssignPayrollAdminForm;
