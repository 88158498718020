import React from "react";
import { Tooltip, Modal } from "antd";
import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import AmendmentDeclineReason from "./amendmentDeclineReason";
import ReSubmitDetails from "./ReSubmitDetails";
import {
  AMENDMENT_RESUBMITTED_STATUS,
  AMENDMENT_APPROVED_STATUS,
  AMENDMENT_APPROVED_ONLY_STATUS,
  AMENDMENT_DECLINED_STATUS,
} from "../../../constants/payrun";
import {
  getAmendmentStatusId,
  isAmendmentAppliedToPayroll,
  isAmendmentAutomated,
} from "../../../libs";

type Props = {
  approveAmendment: (data: any, e: any) => void;
  handleDecline: (data: any, e: any) => void;
  data: any;
};

const AmendmentModalTableAction = ({
  approveAmendment,
  handleDecline,
  data,
}: Props) => {
  const showDeclineReason = (amendment: any, event: any) => {
    event.stopPropagation();
    Modal.warning({
      title: "Amendment has been rejected",
      content: (
        <AmendmentDeclineReason description={amendment.rejectDescription} />
      ),
      width: 700,
    });
  };

  const showReSubmitReason = (amendment: any, event: any) => {
    event.stopPropagation();
    Modal.warning({
      title: "Amendment has been resubmitted",
      content: (
        <ReSubmitDetails description={amendment.resubmissionDescription} />
      ),
      width: 600,
    });
  };

  const actionOptions = (data: any) => {
    return !isAmendmentAutomated({ amendment: data }) ||
      !isAmendmentAppliedToPayroll({ amendment: data }) ? (
      <React.Fragment>
        <Tooltip title="Approve" placement="bottom">
          <CheckOutlined
            onClick={(e) => approveAmendment(data, e)}
            className={`${
              AMENDMENT_APPROVED_STATUS.includes(getAmendmentStatusId(data))
                ? "Visible-Disabled"
                : ""
            } color-success text-xl hand`}
          />{" "}
          &nbsp;
        </Tooltip>
        <Tooltip title="Reject" placement="bottom">
          <StopOutlined
            rotate={90}
            className={`${
              getAmendmentStatusId(data) === AMENDMENT_DECLINED_STATUS
                ? "Visible-Disabled"
                : ""
            } color-danger text-xl hand`}
            onClick={(e) => handleDecline(data, e)}
          />
        </Tooltip>
      </React.Fragment>
    ) : (
      <React.Fragment />
    );
  };

  if (!data) return null;

  switch (getAmendmentStatusId(data)) {
    case AMENDMENT_APPROVED_ONLY_STATUS:
      return (
        <React.Fragment>
          <span className="color-success text-sm italic pr-2">Approved</span>
          {actionOptions(data)}
        </React.Fragment>
      );
    case AMENDMENT_DECLINED_STATUS:
      return (
        <React.Fragment>
          <span
            className="color-danger text-sm italic pr-2"
            onClick={(e) => showDeclineReason(data, e)}
          >
            Rejected
          </span>
          {actionOptions(data)}
        </React.Fragment>
      );
    case AMENDMENT_RESUBMITTED_STATUS:
      return (
        <React.Fragment>
          <span
            className="text-sm italic color-warning pr-2"
            onClick={(e) => showReSubmitReason(data, e)}
          >
            Resubmitted
          </span>
          {actionOptions(data)}
        </React.Fragment>
      );
    case undefined:
      return null;
    default:
      return <React.Fragment>{actionOptions(data)}</React.Fragment>;
  }
};

export default AmendmentModalTableAction;
