//@ts-nocheck
import React, { Component } from "react";
import PayrollProviderView from "../PayRun/PayrollProvider/index";
import AdminView from "../PayRun/Admin/index";
import _ from "lodash";
import PayrunInvoice from "../PayRun/Supervisor/invoice";
import CompanyUser from "../User/user";
import PayrunDetail from "../../components/payrun/status/amendment";
import RGL, { WidthProvider } from "react-grid-layout";
import TileContainer from "../../../Common/TileComponent";
import { Loading, If } from "../../../Common/ui";
import VarianceIndex from "../variance";
import DashboardSettings from "../../components/dashboard/DashboardSettings";
import GeneralAmendmentIndex from "../GeneralAmendment/Index";
import PendingGeneralAmendmentsDash from "../../components/generalAmendment/PendingGeneralAmendmentsDash";
import CompletedAmendmentsDash from "../../components/generalAmendment/CompletedAmendmentsDash";
import CompanyIndex from "../Company/Index";
import PayscheduleAdmin from "../Payschedule/index";
import BusinessUnitIndex from "../BusinessUnit/Index";
import BusinessUnitDetail from "../BusinessUnit/Detail";
import {
  PERMANENT_AMENDMENT_TYPE,
  PAYRUN_AMENDMENT_TYPE,
  REOCCURRING_AMENDMENT_TYPE,
} from "../../../constants/payrun";
import CompanyDetail from "../Company/Detail";
import BillingServiceProviderIndex from "../bsp";
import PayrollProviderDetail from "../PayrollProvider/Detail";
import {
  BUSINESS_UNIT_MENU_ITEM,
  COMPANY_LIST_CONTAINER,
  COMPANY_MENU_ITEM,
  DEFAULT_HOME_ROUTE,
  GLOBAL_PAYROLL_APPLICATION_NAME,
  PAYRUN_MENU_ITEM,
  PAYRUN_LIST_CONTAINER,
  PAYRUN_AMENDMENT_LIST_CONTAINER,
  PAYSCHEDULE_MENU_ITEM,
  PAYSCHEDULE_LIST_CONTAINER,
  PAYROLL_PROVIDER_MENU_ITEM,
  USER_MENU_ITEM,
  INVOICE_MENU_ITEM,
  BILLING_PROVIDER_MENU_ITEM,
  BILLING_PROVIDER_LIST_CONTAINER,
  VARIANCE_MENU_ITEM,
  VARIANCE_LIST_CONTAINER,
  GENERAL_AMENDMENT_MENU_ITEM,
  GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER,
  GENERAL_AMENDMENT_PENDING_LIST_CONTAINER,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import {
  getSelectedDashboardList,
  findAuthorizationClaims,
  updateAuthorizationClaims,
  getSearchParamName,
  getCurrentAuthorizationDom,
  conditionalParameter,
  delay,
  mustBeArray,
  parseItems,
  isEmpty,
} from "../../../libs";
import { message } from "antd";
import DashboardEmptyCard from "../../components/dashboard/DashboardEmptyCard";
import { withRouter } from "../../../hooks";
import { DashboardItemPinnedContainerType } from "../../../types/dashboard";

const ReactGridLayout = WidthProvider(RGL);

type OwnState = any;

type State = OwnState & typeof CustomDashboard.defaultProps;
type Props = any;

const MAX_ERROR_COUNT = 3;

class CustomDashboard extends Component<Props, State> {
  static defaultProps = {
    className: "layout",
    items: 50,
    cols: 12,
    rowHeight: 50,
  };
  constructor(props: any) {
    super(props);
    this.state = {
      invoiceLoading: false,
      payrunLoading: false,
      totalPayunUpdated: false,
      showPaymentModal: false,
      activeDashboard: "Default",
      activeDashboardData: {},
      groupedSelectedDashboardList: {},
      rowsList: [],
      tilesList: [],
      layout: [],
      pageLoading: true,
      autoAdjust: true,
      isDashboardEmpty: false,
      errorCount: 0,
    };
    this.updateDashboardLayout = this.updateDashboardLayout.bind(this);
  }
  componentDidMount() {
    this.getDashboardComponents({
      dashboardList: this.props.dashboardList ?? [],
    });
  }
  UNSAFE_componentWillReceiveProps(newProps: any) {
    const searchField = getSearchParamName(window?.location?.search);
    const activeDashboard = searchField ? searchField["?dashboard"] : "Default";
    // Reload updated dashboard list - when components are added or removed or pinned
    if (
      newProps.dashboardList !== this.props.dashboardList ||
      activeDashboard !== this.state.activeDashboard
    ) {
      this.getDashboardComponents({
        dashboardList: newProps.dashboardList,
        listUpdated: false,
      });
    }
  }
  getDashboardComponents = ({ dashboardList, listUpdated = false }: any) => {
    if (this.state.errorCount >= MAX_ERROR_COUNT) {
      message.error('Something is causing a loop and its crashing the CustomDashboard');
      return null;
    }

    const searchField = new URLSearchParams(window?.location?.search);
    const activeDashboard = searchField.get("dashboard") ?? 'Default';
    if (dashboardList && dashboardList.length === 0) {
      this.setState({
        errorCount: this.state.errorCount + 1,
      })

      return null;
    }

    const activeDashboardData = dashboardList.find(d => d.name.trim() === activeDashboard.trim());
    const isDashboardEmpty = isEmpty(activeDashboardData?.pinnedContainers);
    let groupedSelectedDashboardList = _.groupBy(
      mustBeArray(activeDashboardData?.pinnedContainers),
      (o) => o?.position?.row
    );
    this.setState({
      isDashboardEmpty,
      groupedSelectedDashboardList,
      activeDashboard,
      activeDashboardData,
    });

    //Difference between user triggered event calling this function vs calling this function on page load
    if (!listUpdated) {
      const settings = parseItems(activeDashboardData?.settings);
      if (settings) {
        this.setState({ autoAdjust: settings.autoAdjust }); //Current supported vertical adjustment of components
      }

      this.generateLayout({
        tilesList: activeDashboardData?.pinnedContainers,
      });
      this.props.updateHeader({
        header: {
          title: activeDashboard,
          module: "",
          enableBack: false,
          entity: "",
          action: "",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.Dashboard,
        },
      });
    } else {
      this.setState({ tilesList: activeDashboardData?.pinnedContainers });
    }
  };

  generateLayout({ tilesList }: any) {
    let layout = mustBeArray(tilesList).map((item: any, i: any) => {
      return {
        w: item?.size?.width,
        h: item?.size?.height,
        x: item?.position?.col,
        y: item?.position?.row,
        i: item.targetEndpoint,
        minH: 2,
        minW: 2,
        moved: false,
        static: !!(!item.resizeable && !item.draggable),
        isDraggable: item.draggable,
        isResizable: item.resizeable,
      };
    });
    this.setState(
      { layout, selectedDashboardList: mustBeArray(tilesList) },
      () => {
        this.setState({ pageLoading: false });
      }
    );
  }

  updateDashboardLayout = ({ newLayout }: any) => {
    let currentList = [...this.state.selectedDashboardList];
    currentList = currentList.map((item) => {
      let layOutValue = mustBeArray(newLayout).find(
        (o) => o.i === item.targetEndpoint
      );
      return {
        ...item,
        size: {
          height: layOutValue?.h,
          width: layOutValue?.w,
        },
        position: {
          row: layOutValue?.y,
          col: layOutValue?.x,
        },
      };
    });
    this.props.dashboardActionHandler({
      action: "update",
      id: this.state.activeDashboardData?.id,
      payload: {
        ...this.state.activeDashboardData,
        pinnedContainers: currentList,
      },
    });
  };
  isItemSame = (item1: any = {}, item2: any = {}) => {
    if (
      item1.w === item2.w &&
      item1.h === item2.h &&
      item1.x === item2.x &&
      item1.y === item2.y &&
      item1.isResizable === item2.isResizable &&
      item1.isDraggable === item2.isDraggable
    )
      return true;
    return false;
  };
  onLayoutChange = (layout: any) => {
    let layoutChanged = false;
    mustBeArray(this.state.layout).forEach((item: any) => {
      if (
        !this.isItemSame(
          item,
          _.find(layout, (o) => o.i === item.i)
        )
      ) {
        return (layoutChanged = true);
      }
    });
    if (!_.isEmpty(mustBeArray(layout)) && layoutChanged) {
      this.updateDashboardLayout({ newLayout: layout });
    }
  };
  handleAutoAdjust = () => {
    const settings = JSON.stringify({ autoAdjust: !this.state.autoAdjust });
    this.setState({ autoAdjust: !this.state.autoAdjust }, () => {
      delay(600).then(() => {
        let templist = [...this.state.selectedDashboardList];

        templist = templist.map((item, i) => {
          let layOutValue = _.find(
            mustBeArray(this.state.layout),
            (o) => o.i === item.targetEndpoint
          );
          return {
            ...item,
            size: {
              height: layOutValue?.h,
              width: layOutValue?.w,
            },
            position: {
              row: layOutValue?.y,
              col: layOutValue?.x,
            },
          };
        });
        this.props.dashboardActionHandler({
          action: "update",
          id: this.state.activeDashboardData?.id,
          payload: {
            ...this.state.activeDashboardData,
            settings,
            pinnedContainers: templist,
          },
        });
      });
    });
  };

  handleDelete = () => {
    const activeDashboard = getSelectedDashboardList({
      dashboardList: this.props.dashboardList,
    });
    const { member } = this.props;
    let currentDashboard = { ...activeDashboard };
    this.props
      .dashboardActionHandler({
        id: activeDashboard?.id,
        action: "delete",
      })
      .then((resp) => {
        this.setState({ submitLoading: false });
        if (resp.status) {
          message.success("Dashboard deleted successfully.");
          this.props.router.navigate(DEFAULT_HOME_ROUTE);
          mustBeArray(activeDashboard?.pinnedContainers).forEach(
            (container) => {
              const currentAuthorizationDOM =
                getCurrentAuthorizationDom(member);
              const currentDOMComponents = _.find(
                currentAuthorizationDOM,
                (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
              );
              const claim = findAuthorizationClaims({
                claims: currentDOMComponents?.components,
                name: container?.name,
              });
              const tempPersonalizations = structuredClone(
                claim?.personalizations
              );
              if (tempPersonalizations) {
                tempPersonalizations[1].pinnedTo = _.filter(
                  mustBeArray(tempPersonalizations[1].pinnedTo),
                  (o) => o !== currentDashboard?.id
                );
                const updatedDOM = updateAuthorizationClaims({
                  claims: currentAuthorizationDOM,
                  value: tempPersonalizations,
                  label: "personalizations",
                  name: claim?.name,
                });
                this.props.updateAuthorizationClaim({
                  claim: updatedDOM,
                  payload: {
                    personaComponents: [
                      { ...claim, personalizations: tempPersonalizations },
                    ],
                  },
                  update: true,
                });
              }
            }
          );
        }
      });
  };

  render() {
    const { currentAuthorizationDOM } = this.props;
    const applicationClaims = _.find(
      mustBeArray(currentAuthorizationDOM),
      (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
    );
    const userMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: USER_MENU_ITEM,
    });
    const payrunMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: PAYRUN_MENU_ITEM,
    });
    const invoiceMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: INVOICE_MENU_ITEM,
    });
    const varianceMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: VARIANCE_MENU_ITEM,
    });
    const generalAmendmentMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: GENERAL_AMENDMENT_MENU_ITEM,
    });
    const companyMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: COMPANY_MENU_ITEM,
    });
    const payScheduleMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: PAYSCHEDULE_MENU_ITEM,
    });
    const businessUnitMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: BUSINESS_UNIT_MENU_ITEM,
    });
    const billingServiceProviderMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: BILLING_PROVIDER_MENU_ITEM,
    });
    const payrollProviderMenuItem = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({ data: applicationClaims, field: "components" })
      ),
      name: PAYROLL_PROVIDER_MENU_ITEM,
    });

    const DashboardMapper = {
      payrunListContainer: {
        componentName: { PAYRUN_LIST_CONTAINER },
        component: (
          <AdminView
            dashboardView={true}
            {...this.props}
            pageClaims={payrunMenuItem}
          />
        ),
      },
      payrunAmendmentStatusListContainer: {
        componentName: "payrunAmendmentStatusListContainer",
        component: (
          <PayrollProviderView
            dashboardView={true}
            {...this.props}
            pageClaims={payrunMenuItem}
            listPayrunAmendments={this.props.listPayrun}
            type="amendment"
          />
        ),
      },
      payrunPrepareStatusListContainer: {
        componentName: "payrunPrepareStatusListContainer",
        component: (
          <PayrollProviderView
            {...this.props}
            pageClaims={payrunMenuItem}
            listPayrunPrepare={this.props.listPayrun}
            dashboardView={true}
            type="prepare"
          />
        ),
      },
      payrunApprovalStatusListContainer: {
        componentName: "payrunApprovalStatusListContainer",
        component: (
          <PayrollProviderView
            dashboardView={true}
            {...this.props}
            listPayrunApproval={this.props.listPayrun}
            pageClaims={payrunMenuItem}
            type="approval"
          />
        ),
      },
      payrunDraftStatusListContainer: {
        componentName: "payrunDraftStatusListContainer",
        component: (
          <PayrollProviderView
            dashboardView={true}
            {...this.props}
            listPayrunApproval={this.props.listPayrun}
            pageClaims={payrunMenuItem}
            type="draft"
          />
        ),
      },
      payrunProcessStatusListContainer: {
        componentName: "payrunProcessStatusListContainer",
        component: (
          <PayrollProviderView
            dashboardView={true}
            {...this.props}
            listPayrunProcess={this.props.listPayrun}
            pageClaims={payrunMenuItem}
            type="process"
          />
        ),
      },
      payrunCompletedStatusListContainer: {
        componentName: "payrunCompletedStatusListContainer",
        component: (
          <PayrollProviderView
            dashboardView={true}
            {...this.props}
            listPayrunCompleted={this.props.listPayrun}
            pageClaims={payrunMenuItem}
            type="completed"
          />
        ),
      },
      payrunRejectedStatusListContainer: {
        componentName: "payrunRejectedStatusListContainer",
        component: (
          <PayrollProviderView
            dashboardView={true}
            {...this.props}
            pageClaims={payrunMenuItem}
            type="rejected"
          />
        ),
      },
      invoicePaidListContainer: {
        componentName: "invoicePaidListContainer",
        component: (
          <PayrunInvoice
            dashboardView={true}
            type="paid"
            {...this.props}
            pageClaims={invoiceMenuItem}
          />
        ),
      },
      invoiceUnPaidListContainer: {
        componentName: "invoiceUnPaidListContainer",
        component: (
          <PayrunInvoice
            dashboardView={true}
            type="unPaid"
            {...this.props}
            pageClaims={invoiceMenuItem}
          />
        ),
      },
      userCompanyListContainer: {
        componentName: "userCompanyListContainer",

        component: (
          <CompanyUser
            dashboardView={true}
            type="company"
            {...this.props}
            pageClaims={userMenuItem}
          />
        ),
      },
      userPayrollServiceProviderListContainer: {
        componentName: "userPayrollServiceProviderListContainer",
        component: (
          <CompanyUser
            dashboardView={true}
            type="psp"
            {...this.props}
            pageClaims={userMenuItem}
          />
        ),
      },
      payrollProviderPayrollConnectionListContainer: {
        componentName: "payrollProviderPayrollConnectionListContainer",
        component: (
          <PayrollProviderDetail
            dashboardView={true}
            type="connection"
            {...this.props}
            pageClaims={payrollProviderMenuItem}
          />
        ),
      },
      payrunAmendmentListContainer: {
        componentName: PAYRUN_AMENDMENT_LIST_CONTAINER,
        component: (
          <PayrunDetail
            dashboardView={true}
            {...this.props}
            pageClaims={payrunMenuItem}
          />
        ),
      },
      varianceListContainer: {
        componentName: VARIANCE_LIST_CONTAINER,
        component: (
          <VarianceIndex
            dashboardView={true}
            {...this.props}
            pageClaims={varianceMenuItem}
          />
        ),
      },
      generalAmendmentPayrunAmendmentListContainer: {
        componentName: GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER,
        component: (
          <GeneralAmendmentIndex
            dashboardView={true}
            {...this.props}
            pageClaims={generalAmendmentMenuItem}
            type={PAYRUN_AMENDMENT_TYPE}
          />
        ),
      },
      generalAmendmentAdhocAmendmentListContainer: {
        componentName: "generalAmendmentAdhocAmendmentListContainer",
        component: (
          <GeneralAmendmentIndex
            dashboardView={true}
            {...this.props}
            pageClaims={generalAmendmentMenuItem}
            type={PERMANENT_AMENDMENT_TYPE}
          />
        ),
      },
      generalAmendmentReoccurringAmendmentListContainer: {
        componentName: "generalAmendmentReoccurringAmendmentListContainer",

        component: (
          <GeneralAmendmentIndex
            dashboardView={true}
            {...this.props}
            pageClaims={generalAmendmentMenuItem}
            type={REOCCURRING_AMENDMENT_TYPE}
          />
        ),
      },
      generalAmendmentListContainer: {
        componentName: "generalAmendmentListContainer",
        component: (
          <GeneralAmendmentIndex
            dashboardView={true}
            {...this.props}
            pageClaims={generalAmendmentMenuItem}
            type={"generalAmendment"}
          />
        ),
      },
      generalAmendmentPendingListContainer: {
        componentName: GENERAL_AMENDMENT_PENDING_LIST_CONTAINER,
        component: (
          <PendingGeneralAmendmentsDash
            {...this.props}
            pageClaims={generalAmendmentMenuItem}
          />
        ),
      },
      generalAmendmentCompletedListContainer: {
        componentName: GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER,
        component: (
          <CompletedAmendmentsDash
            {...this.props}
            pageClaims={generalAmendmentMenuItem}
          />
        ),
      },
      companyListContainer: {
        componentName: COMPANY_LIST_CONTAINER,
        component: (
          <CompanyIndex
            dashboardView={true}
            {...this.props}
            pageClaims={companyMenuItem}
          />
        ),
      },
      payScheduleListContainer: {
        componentName: PAYSCHEDULE_LIST_CONTAINER,
        component: (
          <PayscheduleAdmin
            dashboardView={true}
            {...this.props}
            pageClaims={payScheduleMenuItem}
          />
        ),
      },
      businessUnitListContainer: {
        componentName: "businessUnitListContainer",
        component: (
          <BusinessUnitIndex
            dashboardView={true}
            {...this.props}
            pageClaims={businessUnitMenuItem}
          />
        ),
      },
      billingServiceProviderListContainer: {
        componentName: BILLING_PROVIDER_LIST_CONTAINER,
        component: (
          <BillingServiceProviderIndex
            dashboardView={true}
            {...this.props}
            pageClaims={billingServiceProviderMenuItem}
          />
        ),
      },
      companyUserListContainer: {
        componentName: "companyUserListContainer",
        component: (
          <CompanyDetail
            dashboardView={true}
            type="companyUser"
            {...this.props}
            pageClaims={companyMenuItem}
          />
        ),
      },
      companyPayScheduleListContainer: {
        componentName: "companyPayScheduleListContainer",
        component: (
          <CompanyDetail
            dashboardView={true}
            type="companyPaySchedule"
            {...this.props}
            pageClaims={companyMenuItem}
          />
        ),
      },
      companyBusinessUnitListContainer: {
        componentName: "companyBusinessUnitListContainer",
        component: (
          <CompanyDetail
            dashboardView={true}
            type="companyBusinessUnit"
            {...this.props}
            pageClaims={companyMenuItem}
          />
        ),
      },
      businessUnitPayScheduleListContainer: {
        componentName: "businessUnitPayScheduleListContainer",
        component: (
          <BusinessUnitDetail
            dashboardView={true}
            type="paySchedule"
            {...this.props}
            getPayschedule={this.props.listPayschedules}
            pageClaims={businessUnitMenuItem}
          />
        ),
      },
      businessUnitUserListContainer: {
        componentName: "businessUnitUserListContainer",
        component: (
          <BusinessUnitDetail
            dashboardView={true}
            type="user"
            {...this.props}
            getPayschedule={this.props.listPayschedules}
            pageClaims={businessUnitMenuItem}
          />
        ),
      },
    };
    const groupedTiles = _.groupBy(
      this.state.selectedDashboardList,
      (o) => o?.position?.row
    );
    return (
      <If
        condition={this.state.pageLoading}
        then={<Loading />}
        else={
          <>
            <DashboardSettings
              handleAutoAdjust={this.handleAutoAdjust}
              autoAdjust={this.state.autoAdjust}
              handleDelete={this.handleDelete}
              dashboardList={this.props.dashboardList}
              activeDashboardName={this.state.activeDashboard}
            />
            <If
              condition={this.state.isDashboardEmpty}
              then={
                <div className="flex justify-center mt-12 w-full">
                  <DashboardEmptyCard />
                </div>
              }
            />
            <div className="clearfix" />
            <ReactGridLayout
              layout={this.state.layout}
              onLayoutChange={this.onLayoutChange}
              updateDashboardLayout={this.updateDashboardLayout}
              activeDashboardId={this.state.activeDashboardId}
              {...this.props}
              compactType={this.state.autoAdjust ? "vertical" : null}
            >
              {Object.values(groupedTiles).map((childComponent) => {
                // Organizing components by their column orders
                const sortedChildComponent = mustBeArray(childComponent).sort(
                  (a1: any, a2: any) => {
                    return (
                      a1.position.col - a2.position.col ||
                      a1.sortOrder - a2.sortOrder
                    );
                  }
                );
                return mustBeArray(sortedChildComponent).map(
                  (item: DashboardItemPinnedContainerType, index) => (
                    <div key={item.targetEndpoint}>
                      <TileContainer
                        lg={24}
                        sm={24}
                        md={24}
                        itemKey={index}
                        dashboardView
                        member={this.props.member}
                        ignoreStatus
                        dashboardClaims={item?.personalizations}
                        targetEndpoint={item?.targetEndpoint}
                        componentTitle={item?.componentTitle}
                        filterApplied={item?.filterApplied}
                        resizeable={item.resizeable}
                      >
                        {DashboardMapper[item?.name]?.component}
                      </TileContainer>
                    </div>
                  )
                );
              })}
            </ReactGridLayout>
          </>
        }
      />
    );
  }
}

export default withRouter(CustomDashboard);
