import { PaginationState } from '@tanstack/react-table';

const BASE_KEY = 'XEMPLO-TABLE';

export const DEFAULT_PAGINATION: PaginationState = {
  pageIndex: 0,
  pageSize: 10,
};

export const ROW_SELECTION_KEY = `${BASE_KEY}-built-in-row-selection`;
export const OVERALL_ROW_SELECTION_KEY = `${BASE_KEY}-built-in-overall-row-selection`;
