//@ts-nocheck
import { Form, Modal } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import {
  RateField,
  AppliesToField,
  ChangeTaskField,
  ChangeTaskSubTypesField,
  IsRecurringField,
  RecurringEspecialFields,
  UnitField,
} from "../../../Common/amendments";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import {
  FormResponsiveGridWrapper,
  If,
  TextAreaField,
} from "../../../Common/ui";
import {
  ALLOWANCE_CHANGE_TYPE_ID,
  PAYRUN_AMENDMENT_TYPE_ID,
  REOCCURRING_AMENDMENT_TYPE_ID,
  SCHEDULED_PAYRUN_ID,
  SCHEDULED_PAYRUN_TYPE_ID,
} from "../../../constants/payrun";
import {
  changeTypeHasSubTypes,
  generalAmendmentsFormFieldsAreValid,
  getIsoDateFormat,
} from "../../../libs";
import {
  errorHandler,
  getConditionalResponse,
  mustBeArray,
  parseItems,
} from "../../../libs/utilities";
import { getAmendmentTaskByType } from "../../actions/payrun";
import { LumpSumField } from "../../pages/GeneralAmendment/LumpSum/LumpSumField";
import {
  TaxCalculationMethodField,
  TaxCalculationMethodOptions,
} from "../../pages/GeneralAmendment/LumpSum/TaxCalculationMethodField";
import { PayPeriodsField } from "../../pages/GeneralAmendment/LumpSum/PayPeriodsField";
import { LumpSumOptions } from "../../../types";
import { ChangeTaskTypes } from "@xemplo/gp-types";

const AddAmendment = (props: any) => {
  const { visible, payrun } = props;
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [subTypesList, setSubTypeList] = useState<Array<any>>([]);

  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [changeTaskList, setChangeTaskList] = useState<any>(null);
  const [amendmentSelected, setAmendmentSelected] = useState<number>(
    PAYRUN_AMENDMENT_TYPE_ID
  );

  // Loading for selection dropdowns
  const [changeTaskLoading, setChangeTaskLoading] = useState<boolean>(false);

  const [state, updateState] = React.useState({
    loading: false,
    disabled: false,
    allDisabled: false,
    userList: [],
    userListLoading: false,
    question: {},
    selectedAmendment: {},
    rate: "",
    reoccurringAmendmentPeriodsList: [],
    holidays: {},
    endDate: undefined,
  });

  // Handle the base compoenent state, similar to a class component
  const setState = (newState) => {
    updateState({
      ...state,
      ...newState,
    });
  };

  // Get amendment task based on amendment type
  useEffect(() => {
    form.resetFields(["amendmentTaskTypeId"]);
    resetSubFields();
    setState({ changeTaskLoading: true });
    props
      .getAmendmentTaskByType({
        changeTypeId: amendmentSelected,
      })
      .then((response: any) => {
        setState({ changeTaskLoading: false });
        if (response.status) {
          setChangeTaskList(
            mustBeArray(response?.data).filter(
              (changeTask) =>
                changeTask.changeTaskValueId !== ChangeTaskTypes.PAY_INSTRUCTION
            )
          );
        } else {
          errorHandler({ response, hasValidationErrors: true });
        }
      });
  }, [amendmentSelected]);

  // Get employee list based on business unit on visibility change so the list is updated
  useEffect(() => {
    if (visible) {
      setState({ userListLoading: true });
      props
        .listClientsEmployees({
          options: {},
          id: payrun.businessUnitID,
        })
        .then((response: any) => {
          setState({ userListLoading: false });
          if (response.status) {
            setState({ userList: mustBeArray(response.data) });
          } else {
            errorHandler({ response, hasValidationErrors: true });
          }
        });
    }
  }, [visible]);

  const handleCreate = (e) => {
    e.preventDefault();
    setState({ loading: true });
    form
      .validateFields()
      .then((values) => {
        if (
          !generalAmendmentsFormFieldsAreValid({
            values,
            changeTaskList,
            displayAdditionalReoccurringFields:
              displayAdditionalReoccurringFields(),
          })
        ) {
          //If form not valid - break
          return setState({ loading: false });
        }
        values.amount = values?.rate ?? 0;
        values.assignTo = getConditionalResponse({
          condition: selectedUsers.includes("all"),

          resp1: 0,
          resp2: state.userList.filter((o) =>
            selectedUsers.includes(`${o.id}`)
          ),
        });
        values.amendmentSummary = "";
        values.amendmentTypeId = amendmentSelected;
        values.businessUnitID = props.payrun?.businessUnitID;
        values.amendmentSubTypeId = values?.changeTaskSubType;
        values.payrunId = props.payrun?.payrunId;
        if (values.isReoccurring) {
          values.recurringDurationTypeId = values?.durationType;
          values.recurringExpiryDate = getIsoDateFormat({
            date: values?.endDate,
          });
          values.recurringMaximumAmount = values.recurringMaximumAmount ?? null;
        } else {
          values.amendmentTypeId = PAYRUN_AMENDMENT_TYPE_ID;
          values.payrunType = SCHEDULED_PAYRUN_TYPE_ID;
        }
        values.units = values.units ?? null;
        values.LumpSumCalculationMethod =
          values.LumpSumCalculationMethod ?? null;
        values.LumpSumNumberOfPayPeriods =
          values.LumpSumNumberOfPayPeriods ?? null;

        //Remove lump sum and rate value from payload - BE doesn't need it
        const { lumpSum, rate, ...payload } = values;
        props.onOk(payload).then((resp) => {
          setState({ loading: false });
          if (resp.status) {
            form.resetFields();
          }
        });
      })
      .catch((info) => {
        setState({ loading: false });
        return;
      });
  };

  const handleChange = (value, e) => {
    let selectedUsersTmp;

    if (Array.isArray(value) && Array.isArray(e)) {
      selectedUsersTmp = e.reduce((a, b) => {
        return [...a, b.key];
      }, []);
    } else {
      selectedUsersTmp = [e.key];
    }

    setSelectedUsers(selectedUsersTmp);
    if (selectedUsersTmp?.includes("all")) {
      setState({ disabled: true });
    } else if (selectedUsersTmp && !_.isEmpty(selectedUsersTmp)) {
      setState({ allDisabled: true });
    } else {
      setState({ allDisabled: false, disabled: false });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setState({ loading: false });
    props.onCancel();
  };

  const resetSubFields = () => {
    form.resetFields([
      "assignTo",
      "changeTaskSubType",
      "rate",
      "units",
      "endDate",
      "durationType",
      "recurringMaximumAmount",
      "note",
      "lumpSum",
      "LumpSumCalculationMethod",
      "LumpSumNumberOfPayPeriods",
    ]);
  };

  /**
   * Remove Lump sum fields from the formValues object
   */
  const resetLumpSumFormValues = () => {
    setFormValues(
      ({
        lumpSum,
        LumpSumCalculationMethod,
        LumpSumNumberOfPayPeriods,
        ...rest
      }) => rest
    );
  };
  const resetUsersSelectedAndSubTask = (e) => {
    resetSubFields();

    resetLumpSumFormValues();
    setState({ allDisabled: false, disabled: false });

    let selectedAmendment = _.find(
      changeTaskList,
      (o) => o.changeTaskValueId === e
    );

    setState({ selectedAmendment });

    if (selectedAmendment?.amountApplicable) {
      let template = parseItems(selectedAmendment.template);
      let question = _.find(
        mustBeArray(template?.questions),
        (o) => o.name === "Amount"
      );
      if (question) {
        question.enabled = true;
      }

      setState({ question: question || {} });
    }
  };

  const handleInputValueChange = (amount) => {
    setState({ question: { ...state.question, value: amount } });
  };

  // Set selected amendment type(recurring or payrun)
  useEffect(() => {
    form.resetFields([
      "amendmentTaskTypeId",
      "lumpSum",
      "LumpSumCalculationMethod",
      "LumpSumNumberOfPayPeriods",
    ]);
    resetLumpSumFormValues();
    setFormValues({ ...formValues, amendmentTaskTypeId: null });
    setAmendmentSelected(
      formValues.isReoccurring
        ? REOCCURRING_AMENDMENT_TYPE_ID
        : PAYRUN_AMENDMENT_TYPE_ID
    );
  }, [formValues.isReoccurring]);

  const displayAdditionalReoccurringFields = () => {
    return (
      form?.getFieldValue("isReoccurring") &&
      changeTypeHasSubTypes({ type: formValues.amendmentTaskTypeId })
    );
  };

  const onDurationTypeChange = (value: number) => {
    form.resetFields(["endDate", "maxAmount"]);
    setState({
      durationType: value,
    });
  };
  const updateSubTypeList = (list: Array<any>) => {
    setSubTypeList(list);
  };

  const selectedChangeTaskType = useMemo(() => {
    return changeTaskList?.find(
      (o) => o.changeTaskValueId === form?.getFieldValue("amendmentTaskTypeId")
    );
  }, [form?.getFieldValue("amendmentTaskTypeId"), changeTaskList]);

  const showLumpSumField = useMemo(() => {
    return (
      form.getFieldValue("amendmentTaskTypeId") === ALLOWANCE_CHANGE_TYPE_ID &&
      !form?.getFieldValue("isReoccurring")
    );
  }, [
    form.getFieldValue("amendmentTaskTypeId"),
    form?.getFieldValue("isReoccurring"),
  ]);
  return (
    <Modal
      forceRender
      visible={visible}
      onCancel={handleCancel}
      okText="Save"
      closable
      title="New Amendment"
      centered
      className="default-form-margin createModal pb-0"
      width={800}
      footer={[
        <SimpleButton
          key="submit"
          type="primary"
          loading={state.loading}
          onClick={handleCreate}
          buttonText="Save"
          ignoreStatus={true}
          className="mr-4"
          size="large"
        />,
        <SimpleButton
          key="back"
          type=""
          onClick={handleCancel}
          buttonText="Cancel"
          ignoreStatus={true}
          size="large"
        />,
      ]}
    >
      <Form
        form={form}
        onValuesChange={(values) => setFormValues({ ...formValues, ...values })}
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
      >
        <If
          condition={payrun?.payrunTypeID === SCHEDULED_PAYRUN_ID}
          then={<IsRecurringField />}
        />
        <If
          condition={
            form?.getFieldValue("isReoccurring") &&
            changeTypeHasSubTypes({
              type: form?.getFieldValue("amendmentTaskTypeId"),
            })
          }
          then={
            <FormResponsiveGridWrapper
              componentOne={
                <ChangeTaskField
                  required
                  onChange={resetUsersSelectedAndSubTask}
                  list={changeTaskList}
                  loading={changeTaskLoading}
                />
              }
              componentTwo={
                <If
                  condition={displayAdditionalReoccurringFields()}
                  then={
                    <RecurringEspecialFields
                      getType={true}
                      holidays={state.holidays}
                      fields={formValues}
                      onDurationTypeChange={onDurationTypeChange}
                      handleChangeValue={handleInputValueChange}
                      question={state.question}
                    />
                  }
                />
              }
            />
          }
          else={
            <ChangeTaskField
              required
              onChange={resetUsersSelectedAndSubTask}
              list={changeTaskList}
              loading={changeTaskLoading}
            />
          }
        />
        <If
          condition={changeTypeHasSubTypes({
            type: formValues.amendmentTaskTypeId,
          })}
          then={
            <If
              condition={form?.getFieldValue("isReoccurring")}
              then={
                <FormResponsiveGridWrapper
                  componentOne={
                    <ChangeTaskSubTypesField
                      task={selectedChangeTaskType}
                      required
                      businessUnitId={payrun?.businessUnitID}
                      updateSubTypeList={updateSubTypeList}
                    />
                  }
                  componentTwo={
                    <If
                      condition={displayAdditionalReoccurringFields()}
                      then={
                        <RecurringEspecialFields
                          getValue={true}
                          holidays={state.holidays}
                          fields={formValues}
                          onDurationTypeChange={onDurationTypeChange}
                          handleChangeValue={handleInputValueChange}
                          question={state.question}
                        />
                      }
                    />
                  }
                />
              }
              else={
                <ChangeTaskSubTypesField
                  task={selectedChangeTaskType}
                  required
                  businessUnitId={payrun?.businessUnitID}
                  updateSubTypeList={updateSubTypeList}
                />
              }
            />
          }
        />
        <UnitField required={true} />
        <RateField
          question={state.question}
          handleChange={handleInputValueChange}
        />
        {/** Tax caclulation method */}
        {showLumpSumField && (
          <LumpSumField
            handleChangeValue={() => {
              form.resetFields([
                "LumpSumCalculationMethod",
                "LumpSumNumberOfPayPeriods",
              ]);
            }}
            required={
              formValues.amendmentTaskTypeId === ALLOWANCE_CHANGE_TYPE_ID
            }
          />
        )}
        {form.getFieldValue("lumpSum") === LumpSumOptions.Yes && (
          <TaxCalculationMethodField required />
        )}
        {form.getFieldValue("LumpSumCalculationMethod") ===
          TaxCalculationMethodOptions.A && <PayPeriodsField required />}

        <AppliesToField
          selectedAmendmentTaskType={selectedChangeTaskType}
          onChange={handleChange}
          userList={state.userList}
          loading={state.userListLoading}
          setFields={setState}
          setSelectedUsers={setSelectedUsers}
          required
        />
        <TextAreaField className="mb-3" required={false} />
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = { getAmendmentTaskByType };

export default connect(null, mapDispatchToProps)(AddAmendment);
