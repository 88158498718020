import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { BodySmallMedium, Colour } from '@xemplo/style-constants';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: ${Colour.Gray[100]};
  transition: all 0.1s ease-out;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 24px;
  height: 32px;
  ${BodySmallMedium};
  color: ${Colour.Gray[400]};

  &.has-selected {
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
  }

  + table {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 16px;
`;

export const SelectedActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 48px;

  &.list-table {
    margin-right: 0;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 16px;
  background: ${Colour.Black[30]};
  margin-inline: 24px;
  &.light {
    background: ${Colour.White[30]};
  }
`;

export const Selector = styled.span`
  color: ${Colour.Gray[400]};
  cursor: pointer;
  &:hover {
    color: ${Colour.Gray[700]};
  }
  &.selected {
    color: ${Colour.White[80]};
    &:hover {
      color: ${Colour.White[100]};
    }
  }
`;
