import { Dispatch, SetStateAction } from 'react';

import { EIMSClientConfig } from './auth.types';
import { Environment } from './env.types';

export const SupportedApps = {
  TtbApprover: 'ttb-approver',
  AppStart: 'app-start',
  GP: 'gp',
} as const;
export type SupportedApps = (typeof SupportedApps)[keyof typeof SupportedApps];

export type XemploInternalSettings = {
  /**
   * It determines the application baseUrl.
   * Typically used when routing rules uses url segments instead
   * of sub-domains. It defaults to `/`.
   */
  baseUrl?: string;

  /**
   * Internal app global state
   * DO NOT SET THIS VALUE ON APP BOOTSTRAP
   */
  dsPersonaId?: string;

  /**
   * Internal app global state
   * DO NOT SET THIS VALUE ON APP BOOTSTRAP
   */
  setDsPersonaId: Dispatch<SetStateAction<string | null>>;

  /**
   * This is the HR Instance URL
   * The HR has custom domains for each client and this is resolved
   * during the select persona flow.
   *
   * DO NOT SET THIS VALUE ON APP BOOTSTRAP
   */
  instanceUrl?: string;

  /**
   * Internal app global state
   * DO NOT SET THIS VALUE ON APP BOOTSTRAP
   */
  setInstanceUrl: Dispatch<SetStateAction<string | null>>;

  resetPersona: () => void;

  /**
   * Handles checking for instance url and creating a new URL
   */
  makeHrUrl: (endpoint: string) => string;

  /**
   * Represents the same value obtained by VITE_EIMS_URL
   * with normalized name to be easier to consume within the app
   */
  eimsUrl: string;

  /**
   * Represents the same value obtained by VITE_DS_URL
   * with normalized name to be easier to consume within the app
   */
  dsUrl: string;

  /**
   * Represents the same value obtained by VITE_RELEASE_CHANNEL
   * with normalized name to be easier to consume within the app
   */
  releaseChannel: Environment;

  /**
   * Represents the same value obtained by VITE_APP_NAME
   * with normalized name to be easier to consume within the app
   */
  appName: SupportedApps;

  /**
   * Represents the same value obtained by VITE_CLOUD_ROLE
   * with normalized name to be easier to consume within the app
   */
  cloudRole: string;

  /**
   * Represents the same value obtained by VITE_INSTRUMENTATION_KEY
   * with normalized name to be easier to consume within the app
   */
  instrumentationKey: string;

  /**
   * Represents the same value obtained by VITE_SENTRY_DSN
   * with normalized name to be easier to consume within the app
   */
  sentryDsn: string;

  /**
   * Some apps like GP have an independent API URL.
   * This is used to set the API URL for the app.
   */
  applicationApiUrl?: string;

  /**
   * We have some implementations that will navigate to the app-start, use this to set it.
   * Should find a better way to deal with this, but for this current iteration, it will cause
   * a lot more refactoring than scoped.
   */
  startAppUrl?: string;
};

export type XemploBaseAppSettings = {
  VITE_DS_URL: string;
  VITE_EIMS_URL: string;
  VITE_RELEASE_CHANNEL: Environment;
  /** @deprecated we are not going to use App Insights for FE anymore */
  VITE_INSTRUMENTATION_KEY: string;
  VITE_APP_NAME: SupportedApps;
  VITE_CLOUD_ROLE: string;
  EIMS_CLIENT_CONFIG: EIMSClientConfig;
  APP_CLIENT_ID: string;
  VITE_SENTRY_DSN: string;
};

export type XemploAppSettings = XemploInternalSettings & XemploBaseAppSettings;

export const TimeFrameType = {
  Weekly: 1,
  Fortnightly: 2,
  Monthly: 3,
} as const;

export type TimeFrameType = (typeof TimeFrameType)[keyof typeof TimeFrameType];
