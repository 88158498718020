import React from "react";
import { BasicQuestion, Question } from "../../../../types";
import InputTypeNumberDefault from "../../../InputTypeNumberDefault";
import { formatNumber } from "../../../../libs";
import Buttons from "./Buttons";
import If from "../../If";
import InputTextAndNumberOnly from "../../InputTextAndNumberOnly";

type Props = {
  question: Question;
  handleChange: (value: any) => void;
  addQuestion: (field: string) => void;
  removeQuestion: (field: string, index: number) => void;
};

const KeyPairDynamic = ({
  question,
  handleChange,
  addQuestion,
  removeQuestion,
}: Props) => (
  <>
    {question.questions.map((q: BasicQuestion, index: number) => {
      return (
        <div className="grid grid-cols-3 gap-4 mb-4 items-center" key={index}>
          <div className="col-span-2">
            <InputTextAndNumberOnly
              handleChange={handleChange}
              value={q?.name ?? ""}
              index={index}
              threshold={q?.threshold}
            />
          </div>
          <div className="col-span-1 inline-flex items-center">
            $
            <InputTypeNumberDefault
              min={q?.threshold?.minAmount}
              max={q?.threshold?.maxAmount}
              formatter={(value: string) => formatNumber(value)}
              className="ml-2"
              disabled={q.name.length === 0}
              question={q}
              handleChange={(value: number) =>
                handleChange({
                  value,
                  type: "value",
                  index,
                })
              }
            />
            <If
              condition={!!q.subLabel}
              then={<label className="ml-2">{q.subLabel}</label>}
            />
            <Buttons
              index={index}
              isLast={index === question.questions.length - 1}
              name={question.name}
              addQuestion={addQuestion}
              removeQuestion={removeQuestion}
              total={question.questions.length}
            />
          </div>
        </div>
      );
    })}
  </>
);

export default KeyPairDynamic;
