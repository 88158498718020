export interface Amendment {
  amendmentId: string;
  payrunId?: string | null;
  payrunName?: string | null;
  amendmentSummary: string;
  requestedDate: Date;
  actionedDate?: Date | null;
  requestedBy?: string | null;
  actionedBy?: string | null;
  /** @deprecated use amendmentStatusId instead */
  amendmentStatusID?: AmendmentStatusIds;
  amendmentStatusId?: AmendmentStatusIds;
  amendmentStatusName: string;
  isActive: boolean;
  /** @deprecated use amendmentTaskTypeId instead */
  amendmentTaskTypeID?: ChangeTaskTypes;
  amendmentTaskTypeId?: ChangeTaskTypes;
  amendmentTaskTypeName: string;
  assignTo: string | null;
  amendmentTypeName: string;
  amendmentTypeId: AmendmentTypes;
  rejectionReasonID?: number | null;
  rejectDescription: string | null;
  amount?: number | null;
  resubmissionReasonID?: number | null;
  resubmissionDescription?: string | null;
  payscheduleID?: string | null;
  payscheduleName?: string | null;
  note?: string | null;
  lastActivityDate: Date;
  businessUnitId: string;
  businessUnitName: string;
  amendmentSubTypeName?: string | null;
  amendmentSubTypeId?: number | null;
  recurringDurationTypeId?: number | null;
  recurringMaximumAmount?: number | null;
  recurringExpiryDate?: Date | null;
  adhocEffectiveDate: Date | null;
  adhocPayDate?: Date | null;
  amendmentPriority?: number | null;
  schedule?: string;
  startDate: Date;
  endDate: Date | null;
  occurencesCounter: number;
  /** @deprecated use amendmentStatusInPPSId instead */
  amendmentStatusInPPSID?: number;
  amendmentStatusInPPSId?: number;
  amendmentStatusInPPSName: string;
  isIntegrated: boolean;
  amendmentKeypayStatusId: number | null;
  amendmentKeypayStatusName: string | null;
  isPending: boolean;
  payRunStatusId?: number | null;
  payRunStatusName: string;
  units?: number | null;
  lumpSumCalculationMethod?: string | null;
  lumpSumNumberOfPayPeriods?: number | null;
}
export interface RejectionReason {
  RejectionReason: string;
  User: {
    Date: Date;
    EmailAddress: string;
    UserID: string;
    UserName: string;
  };
}

export interface RequestedBy {
  Date: Date;
  EmailAddress: string;
  UserID: string;
  UserName: string;
}

export interface AssignTo {
  id: string;
  firstName: string;
  lastName: string;
}
export enum AmendmentTypes {
  PAYRUN = 1,
  PERMANENT = 2,
  RECURRING = 3,
}

export enum ChangeTaskTypes {
  ALLOWANCE = 1,
  DEDUCTION = 2,
  SALARY = 3,
  TERMINATION = 7,
  PAY_INSTRUCTION = 11,
}

export enum UploadCsvTypes {
  MANUAL = 1,
  VARIABLE = 2,
}

export type ChangeTask = {
  id: number;
  changeTaskValueId: ChangeTaskTypes;
  name: string;
  changeTypeId: number;
  multiplicity: boolean;
  amountApplicable: boolean;
  isEnabled: boolean;
  isIntegrated: boolean;
};

export type ChangeSubTask = {
  id: number;
  name: string;
  changeTaskValueId: number;
  changeTaskValue: string;
  externalBusinessId: number;
  templateUrl: string | null;
};

export type AmendmentCreate = {
  payrunId?: string | null;
  amendmentTaskTypeId: ChangeTaskTypes;
  amendmentTypeId: AmendmentTypes;
  businessUnitId: string | null;
  note: string | null;
  amendmentSubTypeId: number | null;
  terminationType?: number;
  recurringDurationTypeId?: number | null;
  recurringMaximumAmount?: number | null;
  recurringExpiryDate?: string | null;
  adhocEffectiveDate: string | null;
  adhocPayDate?: string | null;
  amendmentSummary: string | null;
  amendmentAssigneeAndAmount: AmendmentAssigneeAndAmount[];
  units?: number | null;
  LumpSumCalculationMethod?: string | null;
  LumpSumNumberOfPayPeriods?: number | null;
};

export interface PayInstructionPayload {
  payrunId?: string;
  businessUnitId: string;
  amendmentTaskTypeId: number;
  amendmentTypeId: number;
  note: string;
  amendmentAssigneeAndAmountString?: string; //stringify AmendmentAssigneeAndAmount[];
  adhocEffectiveDate?: Date | string | null;
  adhocPayDate?: Date | string | null;
  attachment?: File | null;
}

export interface EditAmendmentPayload {
  amendmentSubTypeId?: number | null;
  amount?: number | null;
  units?: number | null;
  note?: string | null;
  recurringMaximumAmount?: number | null;
  recurringExpiryDate?: string | null; //date
  isLumpSumPayments?: boolean | null;
  lumpSumCalculationMethod?: string | null;
  lumpSumNumberOfPayPeriods?: number | null;
}

export interface EditTerminationPayload {
  note?: string | null;
  /**
   * NOTE: The endpoint allows this, but it should not be used - it will wipe the replace the entire summary json including questionnaire answers
   */
  amendmentSummary?: string;
}

export interface EditPayInstructionPayload {
  amount?: number | null;
  note?: string | null;
  attachment?: File | null;
}

export interface AmendmentAssigneeAndAmount {
  assignToId: string;
  amount: number | null;
  units: number | null;
}

export const AmendmentStatusIds = {
  CREATED: 1,
  SUBMITTED: 2,
  APPROVED: 3,
  REJECTED: 4,
  PREPARED: 5,
  UPDATED: 6,
  DUE: 7,
  RESUBMITTED: 8,
  ARCHIVED: 9,
  DRAFT: 10,
  COMPLETE: 11,
};

export type AmendmentStatusIds =
  (typeof AmendmentStatusIds)[keyof typeof AmendmentStatusIds];

export const AmendmentKeypayStatusIds = {
  Created: 0,
  InProgress: 1,
  PartialSuccess: 2,
  Success: 3,
  Fail: 4,
} as const;
export type AmendmentKeypayStatusIds =
  (typeof AmendmentKeypayStatusIds)[keyof typeof AmendmentKeypayStatusIds];

export interface AmendmentSummaryQuestion {
  name: string;
  label: string;
  subLabel: string;
  guide?: string;
  type?: AmendmentQuestionType;
  threshold?: {
    minDay?: number;
    maxDay?: number;
    minNumber?: number;
    maxNumber?: number;
    minAmount?: number;
    maxAmount?: number;
  };
  enabled: boolean;
  required: boolean;
  value?: string;
  default?: boolean;
  questions?: AmendmentSummaryQuestion[];
}

export const AmendmentQuestionType = {
  Currency: 'Currency',
  Sub: 'Sub',
  KeyPairDynamic: 'KeyPairDynamic',
  Number: 'Number',
  Date: 'Date',
} as const;
export type AmendmentQuestionType =
  (typeof AmendmentQuestionType)[keyof typeof AmendmentQuestionType];

export interface AmendmentSummary {
  title: string;
  header: string;
  guidance: string;
  questions: AmendmentSummaryQuestion[];
}
export interface AmendmentChangeLog {
  id: string;
  amendmentStatusName: string;
  changeStatusDateTime: Date;
  userInfo: string;
  details: null | string;
}

export interface AmendmentFile {
  amendmentId: string;
  businessUnitId: string;
  displayName: string;
  id: string;
  originalFileName: string;
}

export interface CreateTerminationPayload {
  assignToId?: string | null;
  amendmentTaskTypeId?: number | null;
  amendmentTypeId?: number | null;
  amendmentSubTypeId?: number | null;
  businessUnitId?: string | null;
  note?: string | null;
  amendmentSummary: string | null;
}
