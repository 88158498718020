import styled from 'styled-components';

export * from './menu-item/menu-item.styles';

export const TabMenuWrapper = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100%;
  flex-grow: 0;
`;

export const ShowMoreIcon = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 32px;
`;
