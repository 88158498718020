import { getAmendmentStatusId } from "../../../libs";
import StatusBadge from "../StatusBadge";
const AmendmentStatusBadges = ({ amendment }: any) => {

  return (
    <StatusBadge statusID={getAmendmentStatusId(amendment)} />
  );
};

export default AmendmentStatusBadges;
