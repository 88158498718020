import { SetStateAction } from 'react';
import { captureException } from '@sentry/core';

import { SetStateFactory, StateFactory } from './use-persistent-state.types';

export function tryParseJson<T>(value: string): T | undefined {
  try {
    return JSON.parse(value) as T;
  } catch (error) {
    captureException(error, {
      extra: {
        message: 'Error hydrating persistent-state value from sessionStorage',
        function: 'usePersistentState',
      },
      level: 'debug',
      tags: { flow: 'persistent-state' },
    });
    return undefined;
  }
}

export function tryParseBoolean(value: string): boolean | null {
  if (value === 'true' || value === 'false') {
    return Boolean(value);
  }

  return null;
}

export function tryParseNumber(value: string): number | null {
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) {
    return null;
  }

  return parsedValue;
}

export function handleInitialState<T>(initialState: T | (() => T)): T {
  return typeof initialState === 'function'
    ? (initialState as StateFactory<T>)()
    : initialState;
}

export function handleSetStateAction<T>(
  action: SetStateAction<T>,
  prevState: T | undefined
): T | undefined {
  return typeof action === 'function'
    ? (action as SetStateFactory<T | undefined>)(prevState)
    : action;
}

export function hydrateStorageValue<T>(key: string) {
  const value = sessionStorage.getItem(key);
  if (!value) return undefined;

  const boolValue = tryParseBoolean(value);
  if (boolValue !== null) return boolValue as T;

  const numberValue = tryParseNumber(value);
  if (numberValue !== null) return numberValue as T;

  return tryParseJson(value) as T;
}
