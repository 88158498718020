import { BadgeColour } from '@xemplo/badge';
import { AmendmentStatusIds } from '@xemplo/gp-types';

export const getAmendmentStatusBadgeColor = (value?: AmendmentStatusIds): BadgeColour => {
  switch (value) {
    case AmendmentStatusIds.CREATED:
    case AmendmentStatusIds.ARCHIVED:
      return BadgeColour.LightGray;
    case AmendmentStatusIds.SUBMITTED:
    case AmendmentStatusIds.RESUBMITTED:
    case AmendmentStatusIds.UPDATED:
      return BadgeColour.LightBlue;
    case AmendmentStatusIds.APPROVED:
    case AmendmentStatusIds.COMPLETE:
      return BadgeColour.LightGreen;
    case AmendmentStatusIds.REJECTED:
    case AmendmentStatusIds.DUE:
      return BadgeColour.LightRed;
    case AmendmentStatusIds.DRAFT:
    case AmendmentStatusIds.PREPARED:
      return BadgeColour.LightOrange;
    default:
      return BadgeColour.LightGray;
  }
};
