//@ts-nocheck
import { Table } from "antd";
import { useMemo } from "react";
import {
  getVarianceAmountAndTax,
  getVarianceAmountColWidth,
  getVarianceColumns,
  getVarianceLabelClassname,
} from "../../../libs";
import { currencyFormater, mustBeArray } from "../../../libs/utilities";
import VarianceFees from "./FeesView";
import VarianceSubPayItems from "./SubPayItemsView";
import VariancePayItems from "./PayItemsView";
import VarianceGrossIncomeAndNetWage from "./GrossIncomeAndNetWage";

const VarianceList = (props: any) => {
  const { showTax, data, loading, periods } = props;
  // Get Total Periods
  const getTotalPeriods = () => {
    let periodsLength = mustBeArray(data?.[0]?.payrunSummary).length;
    return periodsLength >= 6 ? 6 : periodsLength;
  };

  const totalAvailablePeriod = useMemo(() => {
    return getTotalPeriods();
  }, [data]);

  const getAmount = ({
    record,
    position,
    field,
    paymentItemName,
    subPayItemName,
    feeIndex,
    type,
  }: any) => {
    let flexWidth = getVarianceAmountColWidth({
      position,
      totalAvailablePeriod,
    });
    const amountAndTaxes = getVarianceAmountAndTax({
      record,
      position,
      field,
      paymentItemName,
      subPayItemName,
      feeIndex,
      type,
      totalAvailablePeriod,
      showTax,
    });
    if (type === "feeOptionName" && showTax) {
      return (
        <div className={`flex-1-1-${flexWidth} text-right`}>
          <div>{currencyFormater(amountAndTaxes?.amount)}</div>
          <div className="text-xs font-normal">
            {currencyFormater(amountAndTaxes?.taxAmount)}
          </div>
        </div>
      );
    }
    return (
      <div className={`flex-1-1-${flexWidth} text-right`}>
        {currencyFormater(amountAndTaxes?.amount)}
      </div>
    );
  };

  // Table Columns
  const columns = useMemo(() => {
    return getVarianceColumns({ totalAvailablePeriod, periods });
  }, [totalAvailablePeriod, data]);

  const labelClassName = useMemo(() => {
    return getVarianceLabelClassname({ totalAvailablePeriod });
  }, [totalAvailablePeriod]);
  return (
    <Table
      dataSource={mustBeArray(data)}
      loading={loading}
      className=" default-no-shadow table-footer-0"
      rowKey="id"
      columns={columns}
      pagination={
        props.dashboardView ? false : { pageSize: 10, hideOnSinglePage: true }
      }
      scroll={{ x: "max-content" }}
      defaultExpandAllRows={false}
      expandedRowRender={(record, l) => {
        return (
          <div key={l}>
            {/* Gross Income */}
            <VarianceGrossIncomeAndNetWage
              totalAvailablePeriod={totalAvailablePeriod}
              record={record}
              key="grossIncome"
              field="total"
              title="Gross Income"
              getAmount={getAmount}
              labelClassName={labelClassName}
            />

            {/* Sub Pay Items */}
            <VarianceSubPayItems
              key="varianceSubPayItems"
              subPayItems={
                record.payrunSummary?.[0]?.payrollProcessing?.earningsLine
              }
              record={record}
              totalAvailablePeriod={totalAvailablePeriod}
              getAmount={getAmount}
              labelClassName={labelClassName}
            />
            {/* Pay Items */}
            {mustBeArray(record.payrunSummary?.[0]?.payItems).map(
              (recordData: any, i: any) => {
                return (
                  <VariancePayItems
                    key={`${i}payItems`}
                    record={record}
                    data={recordData}
                    totalAvailablePeriod={totalAvailablePeriod}
                    getAmount={getAmount}
                    labelClassName={labelClassName}
                  />
                );
              }
            )}
            {/* Fees */}
            {mustBeArray(record.payrunSummary?.[0]?.fees).map(
              (feeData: any, i: any) => {
                return (
                  <VarianceFees
                    totalAvailablePeriod={totalAvailablePeriod}
                    feeIndex={i}
                    data={feeData}
                    record={record}
                    key={`summaryFees${i}`}
                    getAmount={getAmount}
                    labelClassName={labelClassName}
                    showTax={showTax}
                  />
                );
              }
            )}
            {/* Net Wage */}
            <VarianceGrossIncomeAndNetWage
              totalAvailablePeriod={totalAvailablePeriod}
              record={record}
              key="employeeNetEarnings"
              field="employeeNetEarnings"
              title="Net Wage"
              getAmount={getAmount}
              labelClassName={labelClassName}
            />
          </div>
        );
      }}
    />
  );
};
export default VarianceList;
