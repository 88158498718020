import React from "react";
import { ListSelectField } from "../../ui";

type Props = {
  required: boolean;
  onChange: (e: any, itemSelected: any) => void;
  list: any;
  loading?: boolean;
};

const ChangeTaskField = ({
  required,
  onChange,
  list,
  loading = false,
}: Props) => {
  const handleOnChange = (changeTaskValueId: number) => {
    const itemSelected = list.find(
      (item: any) => item.changeTaskValueId === changeTaskValueId
    );

    onChange(changeTaskValueId, itemSelected?.name);
  };
  return (
    <ListSelectField
      label="Change Task"
      name="amendmentTaskTypeId"
      className="mb-4  col-start-1"
      required={required}
      ruleMsg="Please select amendment type"
      onChange={handleOnChange}
      list={list}
      idKey="changeTaskValueId"
      loading={loading}
    />
  );
};

export default ChangeTaskField;
