//@ts-nocheck
import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, Modal } from "antd";
import { errorDisplay } from "../../../../libs/utilities";

const FormItem = Form.Item;

type State = any;

class AddPayrollConnection extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      confirmLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      schedules: [],
      businessunits: [],
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      disableAPIKey: false,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { connection } = this.props;
    if (
      newProps.connection !== connection &&
      newProps.action !== this.props.action &&
      newProps.action === "edit"
    ) {
      this.props.form.setFieldsValue({
        name: newProps.connection.name,
        integrationURL: newProps.connection.integrationURL,
        apiKey: newProps.connection.apiKey,
        whiteLabelUserApiKey: newProps.connection.whiteLabelUserApiKey,
      });
    }
  }

  handleSubmit = (e) => {
    this.setState({ confirmLoading: true });
    e.preventDefault();
    this.setState({
      buttonProps: { loading: true, text: "Connecting .." },
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        this.setState({ confirmLoading: false });
        return;
      }
      values.payrollSystemID = 1;
      this.props.addPayrollConnection(values).then((resp) => {
        if (resp.status) {
          this.props.form.resetFields();
          this.setState({ confirmLoading: false });
        } else {
          errorDisplay(resp && resp.data, true);
          this.setState({ confirmLoading: false });
        }
      });
    });
  };

  handleCancel = () => {
    this.setState({
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      disableAPIKey: false,
      schedules: [],
      businessunits: [],
    });

    this.props.form.resetFields();

    this.props.handleCancel();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };
    return (
      <Modal
        visible={this.props.visible}
        title={this.props.title}
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="submit"
            className="bg-success"
            loading={this.state.confirmLoading}
            onClick={this.handleSubmit}
          >
            Save
          </Button>,
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
        ]}
        className="connectModal default-form-margin"
        width={700}
      >
        <Form
          className="pay-schedule-connect"
          layout="vertical"
          onSubmit={this.handleSubmit}
          hideRequiredMark={true}
          autoComplete="off"
        >
          <FormItem {...formItemLayout} label="Connection Name:">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please enter payroll system name",
                },
              ],
            })(
              <Input placeholder="Payroll system name" className="w-[300px]" />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Integration URL:">
            {getFieldDecorator("integrationURL", {
              rules: [
                {
                  required: true,
                  message: "Please enter integration URL",
                },
              ],
            })(<Input placeholder="Integration URL" className="w-[300px]" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="API Key:">
            {getFieldDecorator("apiKey", {
              rules: [
                {
                  required: true,
                  message: "Please enter API Key",
                },
              ],
            })(<Input placeholder="API Key" className="w-[300px]" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="White Label API Key:">
            {getFieldDecorator("whiteLabelUserApiKey", {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <Input placeholder="White label API Key" className="w-[300px]" />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(AddPayrollConnection);
