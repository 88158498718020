import React, { useEffect } from "react";
import { GOOGLE_ANALYTICS_PAGE_TITLE } from "../../../constants";
import {
  AmendmentCompanyUserDash,
  PayRunCompanyUserDash,
} from "../../components/dashboard/Index";
import CustomDashboardSelector from "./CustomDashboardSelector";

const DefaultCompanyUserDashboard = (props: any) => {
  useEffect(() => {
    // Update page header
    props.updateHeader({
      header: {
        title: "Dashboard",
        module: "",
        enableBack: false,
        entity: "",
        action: "",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.Dashboard,
      },
    });
  }, []);

  return (
    <React.Fragment>
      <CustomDashboardSelector selected={props.selectedDashboard} />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <PayRunCompanyUserDash
          className="lg:col-span-2"
          listPayrun={props.listPayrun}
          listAmendments={props.listAmendments}
        />

        <AmendmentCompanyUserDash
          className="lg:col-span-1"
          getAmendmentHistory={props.getAmendmentHistory}
          updateHeader={props.updateHeader}
        />
      </div>
    </React.Fragment>
  );
};

export default DefaultCompanyUserDashboard;
