import { useMemo } from 'react';
import { RowData, Table } from '@tanstack/react-table';

import { IconButton, IconButtonSize, IconButtonTestId } from '@xemplo/icon-button';
import {
  DawnArrowLeft16,
  DawnArrowLeftEnd16,
  DawnArrowRight16,
  DawnArrowRightEnd16,
} from '@xemplo/icons';
import { Item, TextDropdown } from '@xemplo/text-dropdown';

import { formatNumber } from '../table.helper';
import { TableProps } from '../table.types';

import * as S from './pagination.style';

type PaginationProps<TData> = { table: Table<TData> };

const iconButtonIds = {
  firstPage: 'table-pagination-first-page-btn',
  previousPage: 'table-pagination-previous-page-btn',
  nextPage: 'table-pagination-next-page-btn',
  lastPage: 'table-pagination-last-page-btn',
};

export const PaginationTestId = {
  wrapper: 'table-pagination-wrapper',
  counter: 'table-pagination-counter',
  controls: 'table-pagination-controls',
  pages: 'table-pagination-pages',
  pageSizeSelector: 'table-pagination-page-size-selector',
  iconButton: {
    firstPage: IconButtonTestId.Button(iconButtonIds.firstPage),
    previousPage: IconButtonTestId.Button(iconButtonIds.previousPage),
    nextPage: IconButtonTestId.Button(iconButtonIds.nextPage),
    lastPage: IconButtonTestId.Button(iconButtonIds.lastPage),
  },
};

const pageSizeOptions = [
  { key: '10', value: '10' },
  { key: '20', value: '20' },
  { key: '30', value: '30' },
  { key: '40', value: '40' },
  { key: '50', value: '50' },
];

export function Pagination<TData extends RowData>({ table }: PaginationProps<TData>) {
  const { pagination } = table.getState();
  const {
    enablePagination,
    paginationThreshold = 10,
    manualPagination,
    totalRowCount,
    isLoading,
  } = table.options as TableProps<TData>;

  const shouldPaginate = useMemo(() => {
    if (!enablePagination || isLoading) return false;
    if (manualPagination && totalRowCount) {
      return totalRowCount > paginationThreshold;
    }
    return table.getCoreRowModel().rows.length > paginationThreshold;
  }, [
    enablePagination,
    manualPagination,
    paginationThreshold,
    table,
    totalRowCount,
    isLoading,
  ]);

  if (!shouldPaginate) return null;

  return (
    <S.Wrapper data-testid={PaginationTestId.wrapper}>
      <S.ItemCounter table={table} testId={PaginationTestId.counter} />
      <S.Controls data-testid={PaginationTestId.controls}>
        <IconButton
          id={iconButtonIds.firstPage}
          ariaLabel="First page"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
          naked
          size={IconButtonSize.Small}
        >
          <DawnArrowLeftEnd16 />
        </IconButton>
        <IconButton
          id={iconButtonIds.previousPage}
          ariaLabel="Previous page"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          naked
          size={IconButtonSize.Small}
        >
          <DawnArrowLeft16 />
        </IconButton>
        <span data-testid={PaginationTestId.pages}>
          {formatNumber({ value: pagination.pageIndex + 1 })} of{' '}
          {formatNumber({ value: table.getPageCount() })}
        </span>
        <IconButton
          id={iconButtonIds.nextPage}
          ariaLabel="Next page"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          naked
          size={IconButtonSize.Small}
        >
          <DawnArrowRight16 />
        </IconButton>
        <IconButton
          id={iconButtonIds.lastPage}
          ariaLabel="Last page"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
          naked
          size={IconButtonSize.Small}
        >
          <DawnArrowRightEnd16 />
        </IconButton>
      </S.Controls>
      <S.PageSizeSelector>
        <span>Show</span>
        <TextDropdown
          aria-label="Page size selector"
          defaultSelectedKey={pagination.pageSize.toString()}
          defaultItems={pageSizeOptions}
          testId={PaginationTestId.pageSizeSelector}
          onInputChange={(e) => table.setPageSize(Number(e))}
          width="100px"
        >
          {pageSizeOptions.map((pageSize) => (
            <Item key={pageSize.key} textValue={pageSize.value}>
              {pageSize.value}
            </Item>
          ))}
        </TextDropdown>
      </S.PageSizeSelector>
    </S.Wrapper>
  );
}
