import { ColumnDef } from '@tanstack/react-table';

import { AmendmentStatus } from '@xemplo/amendment-status';
import { DisplayDateFormat, formatDate } from '@xemplo/date-time';
import { Amendment, AmendmentStatusIds } from '@xemplo/gp-types';
import { getRequestedByUserName } from '@xemplo/gp-utils';

import { AssignToCell } from './table-cells/assign-to-cell';
import { TotalAmountCell } from './table-cells';
export const columns: ColumnDef<Amendment>[] = [
  {
    id: 'worker',
    accessorKey: 'worker',
    header: 'Worker',
    cell: AssignToCell,
    size: 260,
    enableSorting: true,
  },
  {
    id: 'amendmentTaskTypeName',
    accessorKey: 'amendmentTaskTypeName',
    header: 'Type',
  },
  {
    id: 'subType',
    accessorKey: 'amendmentSubTypeName',
    header: 'Sub type',
    enableSorting: true,
    cell: ({ row }) => row.original.amendmentSubTypeName ?? '-',
  },
  {
    id: 'requested',
    accessorKey: 'requestedDate',
    header: 'Created',
    cell: ({ row }) =>
      formatDate({
        value: row.original.requestedDate,
        format: DisplayDateFormat.Medium,
      }),
    enableSorting: true,
  },
  {
    id: 'requestedBy',
    accessorKey: 'requestedBy',
    header: 'Requested',
    cell: ({ row }) => getRequestedByUserName(row.original.requestedBy),
    enableSorting: true,
  },
  {
    //TODO: Update this once BE returns value https://expedo.atlassian.net/browse/GC-870
    // Re-enable sorting and accessorFn once BE returns the value
    id: 'totalAmount',
    header: 'Total Amount',
    cell: TotalAmountCell,
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => (
      <AmendmentStatus
        status={row.original.amendmentStatusId}
        statusName={row.original.amendmentStatusName}
      />
    ),
    enableSorting: true,
  },
];
