export type User = {
  id?: string;
  firstName: string | null;
  lastName: string | null;
  primaryEmail?: string | null;
  profilePhoto?: string | null;
  options?: Options;
};

export type RoleCodeExtended = {
  code: RoleCode;
  name: string;
};
export type Persona = {
  id: string;
  userId: string;
  roleCodes: RoleCode[] | null;
  roles: RoleCodeExtended[] | null;
  credentialId?: string;
  instanceId: number;
  companyId?: number | null;
  options?: Options;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

export type Instance = {
  id: number;
  name: string;
  subscriptionId: number;
  modules: Module[];
};

export interface Company {
  id: number;
  name: string;
  instanceId: number;
  logoUrl?: string | null;
  options?: Options;
}

export type Options = Record<string, unknown>;

export type Module = {
  instanceId: number;
  moduleType: ModuleType;
  url: string;
};

export enum ModuleType {
  None = 'None',
  HR = 'HR',
  GP = 'GP',
  Hire = 'Hire',
  Timesheets = 'Timesheets',
  Migration = 'Migration',
}

export enum RoleCode {
  Administrator = 'Administrator',
  Manager = 'Manager',
  HRManager = 'HRManager',
  CompanyAdmin = 'CompanyAdmin',
  ClientAdmin = 'ClientAdmin',
  Worker = 'Worker',
  Recruiter = 'Recruiter',
  PayrollManager = 'PayrollManager',
  PSPAdmin = 'PSPAdmin',
  CompanyAccountant = 'CompanyAccountant',
  GlobalAdmin = 'GlobalAdmin',
  HireAdministator = 'HireAdministator',
  ClientUser = 'ClientUser',
  PayrollAdministrator = 'PayrollAdministrator',
  PayrollCompanyAdmin = 'Payroll Company Admin',
  PayrollCompanyAccountant = 'Payroll Company Accountant',
  PayrollCompanyEmployee = 'Payroll Company Employee',
}

export interface Identity {
  firstName: string;
  lastName: string;
  deviceEnrollmentNeeded: number;
  phoneNumber: {
    alias: string;
    type: string;
    countryCode: string;
    areaCode: string;
    phoneNumber: string;
    default: true;
  }[];
  profilePhoto: string;
  mfa: {
    status: string;
    phoneNumber: {
      countryCode: string;
      areaCode: string;
      phoneNumber: string;
    };
  };
  phoneNumbers: {
    default: boolean;
    phoneNumber: string;
  }[];
  createdDateTime: Date;
  activationDateTime: Date;
  options: {
    sms: boolean;
    deviceEnrollment: boolean;
    totp: boolean;
  };
}

export interface UpdateUserIdentity {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumbers?:
    | {
        phoneNumber: string;
        default: boolean;
      }[]
    | null;
}

export type EventLogs = {
  pageNumber: number;
  pageSize: number;
  total: number;
  eventLogs: UserLoginEvent[];
};

export type UserLoginEvent = {
  id: string;
  userId: string;
  eventDateTime: Date;
  eventType: string;
  ipAddress: string;
  browser: string;
  os: string;
  additionalDetails: string; //TODO: Confirm this json string shape
};
