import Reports from "../../Organization/pages/Reports/reports";

export const ReportsRoutesWithSidebar = [
  {
    path: "reports",
    index: "reports",
    componentPath: "../../Organization/pages/reports/reports",
    element: <Reports />,
  },
];
