// @ts-nocheck
import { Button, Modal, Table } from "antd";
import { tableColumnGenerator } from "../../../../libs";
import {
  AmendmentStatusBadge,
  YesNoPartialStatus,
} from "../../../../Common/amendments";
import { useAuthDom } from "../../../../hooks";
import { useMemo } from "react";

const AmendmentSummaryList = ({
  list,
  visible,
  handleAmendmentSummaryClose,
}: any) => {
  const { isCompanyUser } = useAuthDom();

  let colTemplate = [
    { title: "Type", dataIndex: "amendmentTaskTypeName" },
    { title: "Applies To", dataIndex: "assignTo" },
    {
      title: "Requested Date",
      dataType: "date",
      dataIndex: "requestedDate",
    },
    {
      title: "Requested By",
      dataIndex: "requestedBy",
      dataType: "requestedBy",
    },
    {
      title: "Status",
      dataIndex: "amendmentStatusId",
      render: (data: any) => {
        return <AmendmentStatusBadge statusID={data} />;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      dataType: "currency",
      align: "right",
    },
  ];

  const appliedCol = {
    title: "Applied",
    dataIndex: "amendmentStatusInPPSId",
    render: (data: any) => {
      return <YesNoPartialStatus statusInPPSID={data} />;
    },
  };

  if (!isCompanyUser()) {
    colTemplate.splice(5, 0, appliedCol);
  }

  const column = useMemo(() => {
    return tableColumnGenerator(colTemplate);
  }, [list]);

  return (
    <Modal
      width={1000}
      title="Amendments"
      zIndex={9999}
      visible={visible}
      onCancel={handleAmendmentSummaryClose}
      footer={[
        <Button onClick={handleAmendmentSummaryClose} key="a">
          Close
        </Button>,
      ]}
    >
      <Table
        columns={column}
        rowKey={(rowKey) => rowKey.amendmentId}
        dataSource={list}
        pagination={false}
      />
    </Modal>
  );
};

export default AmendmentSummaryList;
