import { Dispatch, SetStateAction, useState } from 'react';

import {
  handleInitialState,
  handleSetStateAction,
  hydrateStorageValue,
} from './use-persistent-state.helper';
import { InitialState, PersistentStateResult } from './use-persistent-state.types';

/**
 * A hook that returns a stateful value, and a function to update it.
 * The value is stored in sessionStorage and will persist between page reloads.
 */
export function usePersistentState<T>(
  key: string,
  initialState: InitialState<T>
): PersistentStateResult<T>;

/**
 * A hook that returns a stateful value, and a function to update it.
 * The value is stored in sessionStorage and will persist between page reloads.
 *
 * Overload for when an initial state is not provided.
 */
export function usePersistentState<T = undefined>(
  key: string,
  initialState?: InitialState<T>
): PersistentStateResult<T | undefined>;

export function usePersistentState<T = undefined>(
  key: string,
  initialState?: InitialState<T>
): PersistentStateResult<T | undefined> {
  const [state, setState] = useState<T | undefined>(() => {
    const persistedValue = hydrateStorageValue<T>(key);

    if (persistedValue != null) return persistedValue;

    if (initialState == null) return undefined;

    const value = handleInitialState<T>(initialState);
    sessionStorage.setItem(key, JSON.stringify(value));
    return value;
  });

  const setPersistentState: Dispatch<SetStateAction<T | undefined>> = (value) => {
    setState((prevState) => {
      const newValue = handleSetStateAction<T | undefined>(value, prevState);
      if (newValue != null) {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      } else {
        sessionStorage.removeItem(key);
      }
      return newValue;
    });
  };

  return [state, setPersistentState];
}
