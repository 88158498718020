import React from "react";
import { Card } from "antd";
import {
  displayDates,
  getAmendmentStatusId,
  onlyDateFormater,
  parseItems,
} from "../../../libs";
import { getAssignedToUsersText } from "../../../libs/validations";
import {
  AmendmentStatusBadge,
} from "../../../Common/amendments";

type PayRunSingleLineGridProps = {
  content: any;
  handleAction: (options: any) => void;
};

const AmendmentSingleLineGrid: React.FC<PayRunSingleLineGridProps> = ({
  content,
  handleAction,
}) => {
  return (
    <Card
      className="bg-default hand rounded-[12px]"
      onClick={(event) => handleAction({ data: content, event })}
    >
      <div className="flex justify-between">
        <div className="flex-auto w-64">
          <div className="text-[15px] font-bold">{`${
            content.amendmentTaskTypeName
          } for ${getAssignedToUsersText({ content, minified: true })}`}</div>
          <div className="color-disabled text-sm">
            {content?.payrunTypeName}
          </div>
          <div className="color-disabled text-sm">
            Date to amend: {displayDates({ value: content?.startDate })}
          </div>
          <div className="text-xs italic mt-4">
            {`Submitted by ${
              parseItems(content.requestedBy) &&
              parseItems(content.requestedBy).UserName
            } on ${onlyDateFormater(content.requestedDate)}`}
          </div>
        </div>

        <div className="text-right flex-auto">
          <AmendmentStatusBadge statusID={getAmendmentStatusId(content)} />
        </div>
      </div>
    </Card>
  );
};

export default AmendmentSingleLineGrid;
