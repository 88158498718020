import React from "react";
import { Input } from "antd";
import { RequiredSign } from "../../../Common/ui";
import { TEXT_AND_NUMBER_REGEX } from "../../../constants/regex";

const FileNameInput = ({
  handleBlur,
  uid,
}: {
  handleBlur: (e: any, uid: string) => void;
  uid: string;
}) => {
  const nameInputValidator = (e: any) => {
    const isInvalidInput = TEXT_AND_NUMBER_REGEX.test(e.key);
    if (isInvalidInput) {
      return e.preventDefault();
    }
  };
  return (
    <div className="flex items-center">
      <RequiredSign className="pr-1" />
      Name
      <Input
        onKeyDown={(e: any) => nameInputValidator(e)}
        maxLength={20}
        className="ml-4 w-[200px]"
        onBlur={(e: any) => handleBlur(e, uid)}
      />
    </div>
  );
};

export default FileNameInput;
