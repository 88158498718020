import styled, { css } from 'styled-components';

import { Colour } from '@xemplo/style-constants';

const DefaultButton = css<{ $size: number[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colour.White[100]};
  margin: 0;
  padding: 0;
  border-radius: 100%;
  font-size: ${({ $size }) => $size[0]}px;
  width: ${({ $size }) => $size[1]}px;
  height: ${({ $size }) => $size[1]}px;
  color: ${Colour.Gray[400]};
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.1s ease-out;
  border: 1px solid ${Colour.Gray[300]};

  > svg:focus {
    outline: none;
  }

  &:hover:not([disabled]) {
    background: ${Colour.Black[5]};
    border-color: ${Colour.Gray[300]};
  }

  &[disabled] {
    cursor: initial;
    opacity: 0.3;
    background: unset;
    border-color: unset;
  }
`;

export const NakedButton = css`
  background: transparent;
  border: none;

  &:hover:not([disabled]) {
    background: ${Colour.Black[5]};
    border-color: ${Colour.Gray[300]};
    color: ${Colour.Gray[600]};
  }
`;

export const LighterButton = css`
  background: transparent;
  color: ${Colour.White[100]};

  &:hover:not([disabled]) {
    background: ${Colour.White[10]};
    color: ${Colour.White[100]};
  }
`;

export const DarkerButton = css`
  background: ${Colour.White[100]};
  color: ${Colour.Gray[500]};

  &:hover:not([disabled]) {
    background: ${Colour.Black[5]};
    border-color: ${Colour.Gray[300]};
    color: ${Colour.Gray[600]};
  }
`;

export const IconButton = styled.button<{
  $size: number[];
  $naked: boolean | undefined;
}>`
  ${DefaultButton};

  &.naked {
    ${NakedButton};
  }

  &.darker {
    ${DarkerButton};
  }

  &.lighter {
    ${LighterButton};
  }
`;
