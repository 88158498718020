//@ts-nocheck
import {
  PERMANENT_AMENDMENT_TYPE,
  PERMANENT_AMENDMENT_TYPE_ID,
  ADHOC_PAYRUN_ID,
  AMENDMENT_CREATED_STATUS,
  AMENDMENT_DECLINED_STATUS,
  AMENDMENT_DRAFT_STATUS,
  COMPLETED_PAYRUN_STATUS,
  INVOICE_CONFIRM_PAYMENT,
  INVOICE_PAYMENT_SKIPPED,
  INVOICE_PENDING_CONFIRMATION,
  REOCCURRING_AMENDMENT_TYPE_ID,
  REOCCURRING_STATUS_CANCELLED_APPLIED_ID,
  REOCCURRING_STATUS_CANCELLED_ID,
  REOCCURRING_STATUS_CREATED_ID,
  REOCCURRING_STATUS_EDITED_ID,
  REOCCURRING_STATUS_IN_PROGRESS_ID,
  RESOLVED_AMENDMENTS_IDS,
  REOCCURRING_STATUS_MANUAL_ID,
  PAYRUN_STATUS_IDS,
  AMENDMENT_UPDATED_STATUS,
} from "../constants/payrun";
import {
  isValidRate,
  conditionalParameter,
  getSelectedRoleTenantId,
  isComponentVisible,
  isEmpty,
  isNullEmptyUndefined,
  isUserSystemAdmin,
  mustBeArray,
  parseItems,
} from "./utilities";

import { isCompanyUser, isUserPSPAdmin } from "./authorizationdom";
import { hasDueDatePassed } from "./timeHandlers";
import moment from "moment";
import { AUSTRALIA_COUNTRY_ID } from "../constants/entities";
import { message } from "antd";
import userRoles from "../constants/userRoles";
import {
  UNATHORIZED_ACCESS_ERRORS,
  FORBIDDEN_ACTION_ERROR,
} from "../constants/errors";
import { amendmentIsAssociatedWithPayrun } from "./amendment";
import {
  BUSINESS_UNIT_ASSIGN_PAYROLL_MANAGER_MODAL_CONTAINER,
  DEFAULT_HOME_ROUTE,
  RESOLVED_RECURRING_AMENDMENT_STATUSES,
} from "../constants";
import { goToRoute } from "../hooks";
import { getAmendmentStatusId, getAmendmentStatusInPPSId } from "./generalAmendment";

// Payrun

const validStatuses = [
  PAYRUN_STATUS_IDS.INVOICE_GENERATED_SYSTEM_TRIGGERED,
  PAYRUN_STATUS_IDS.INVOICE_GENERATED_USER_TRIGGERED,
  PAYRUN_STATUS_IDS.PAYMENT_PENDING_APPROVAL,
  PAYRUN_STATUS_IDS.PAYMENT_CONFIRMED,
  PAYRUN_STATUS_IDS.PROCESSING,
  PAYRUN_STATUS_IDS.PROCESSED,
];
/**
 * Check for whether to render the Export to Xero button
 */
export function showExportToXero({
  payrun = {},
  amendments = [],
  earningLines = [],
}) {
  // If no payrun or payrun is invalid status return false
  if (!payrun || !validStatuses.includes(payrun.payrunStatusID)) {
    return false;
  }
  // If processing fees or earning lines exist, return true
  if (
    mustBeArray(payrun.processingFees) != null ||
    mustBeArray(earningLines) != null
  ) {
    return true;
  }
  // If payrun is adhoc and has permanent amendment, return true
  const isAdhoc = payrun.payrunTypeID === ADHOC_PAYRUN_ID;
  const hasOneTimeAmendment = amendments.some(
    (o) => o.amendmentTypeId === PERMANENT_AMENDMENT_TYPE_ID
  );
  return isAdhoc && hasOneTimeAmendment;
}

export function showPayrunFinalize({ payrun = {}, businessunitDetails = {} }) {
  if (!payrun || ![12, 13, 14, 15, 16].includes(payrun.payrunStatusID))
    return false;
  return !!(
    Number(payrun.payrollModelId) !== 1 ||
    (Number(payrun.payrollModelId) === 1 &&
      businessunitDetails &&
      !businessunitDetails.paymentMandatory)
  );
}

export function showPayrunComplete({ payrun = {}, businessunitDetails = {} }) {
  if (!payrun || ![17].includes(payrun.payrunStatusID)) return false;
  return !!(
    Number(payrun.payrollModelId) !== 1 ||
    (Number(payrun.payrollModelId) === 1 &&
      businessunitDetails &&
      !businessunitDetails.paymentMandatory)
  );
}

export function showPayInvoice({
  payrun = {},
  invoice = {},
  businessunitDetails = {},
}) {
  if (
    !payrun ||
    !invoice ||
    ![1, 2, 4].includes(Number(invoice.invoiceStatusId)) ||
    Number(payrun.payrunStatusID) === 18
  )
    return false;
  if (Number(payrun.payrollModelId) !== 1 && payrun.isInvoiceNeeded)
    return true;
  return !!(
    Number(payrun.payrollModelId) === 1 && businessunitDetails?.paymentMandatory
  );
}

export function showConfirmPayment({
  payrun = {},
  invoice = {},
  businessunitDetails = {},
}) {
  if (
    !payrun ||
    !invoice ||
    ![3].includes(Number(invoice.invoiceStatusId)) ||
    Number(payrun.payrunStatusID) === 18
  )
    return false;
  if (Number(payrun.payrollModelId) !== 1 && payrun.isInvoiceNeeded)
    return true;
  return !!(
    Number(payrun.payrollModelId) === 1 && businessunitDetails?.paymentMandatory
  );
}

export function showInvoiceDetails({ payrun, invoice }) {
  return !!(payrun?.isInvoiceNeeded && invoice);
}

export function getExportToXeroClassName({ invoice }) {
  if (invoice?.invoiceExportDetails && !isEmpty(invoice?.invoiceExportDetails))
    return "";
  return "bg-success";
}

export function isExportToXeroDisabled({ invoice }) {
  return !!(
    invoice?.invoiceExportDetails && !isEmpty(invoice?.invoiceExportDetails)
  );
}

export function getExportToXeroText({ invoice }) {
  if (invoice?.invoiceExportDetails && !isEmpty(invoice?.invoiceExportDetails))
    return "Exported to XERO";
  return "Export to XERO";
}

export function getCurrentPayrunFlowStep({ payrun }) {
  if (!payrun) return 0;
  const payrunStatusID = conditionalParameter({
    data: payrun,
    field: "payrunStatusID",
  });
  const status = Number(payrunStatusID);
  const isInvoiceNeeded = conditionalParameter({
    data: payrun,
    field: "isInvoiceNeeded",
  });
  if ([1, 2, 3].includes(status)) return 0;
  if ([4, 5, 6, 7, 19, 20, 21, 22].includes(status)) return 1;
  if ([8, 9].includes(status)) return 2;
  if ([10, 11, 12, 13].includes(status)) return 3;
  if ([14, 15, 16, 17].includes(status)) {
    return isInvoiceNeeded ? 4 : 3;
  }
  if (status === 18) {
    return isInvoiceNeeded ? 6 : 5;
  }
}

export function displayStaticContent({ payrun, hideStaticContent }) {
  return !!(
    payrun &&
    payrun.payrunStatusID !== COMPLETED_PAYRUN_STATUS &&
    !hideStaticContent &&
    isCompanyUser()
  );
}

export function showProcesssingPayment({ payrun, businessunitDetails }) {
  return !!(
    isCompanyUser() &&
    payrun?.payrunStatusID === 14 &&
    Number(payrun?.payrollModelId) === 1 &&
    businessunitDetails?.paymentMandatory
  );
}

export function showFinalizePayrun({ payrun, businessunitDetails }) {
  return !!(
    payrun?.payrunStatusID === 15 &&
    Number(payrun?.payrollModelId) === 1 &&
    businessunitDetails?.paymentMandatory
  );
}

export function showCompletePayrun({ payrun }) {
  return payrun?.payrunStatusID === 17 && Number(payrun?.payrollModelId) === 1;
}

// Amendments
export function disabledOccurranceEndDate({ startDate, selectedDate }) {
  if (!startDate || !selectedDate) return false;
  if (moment(selectedDate).isSameOrBefore(moment(startDate).add(1, "day")))
    return true;
  return false;
}

export function getAmendmentRequestedByUserName({ amendment }) {
  if (!_.isEmpty(amendment) && parseItems(amendment?.requestedBy)?.UserName) {
    return parseItems(amendment.requestedBy).UserName;
  }
  return "";
}

export function getAmendmentAssignedToText({
  amendment,
  returnLength = false,
}) {
  return getAssignedToUsersText({ content: amendment, returnLength });
}

export function getAssignedToUsersText({
  content,
  minified = false,
  returnLength = false,
}: {
  content: any;
  returnLength?: boolean;
  minified?: boolean;
}) {
  if (content?.assignTo === "All") {
    return returnLength ? { users: "All", usersLength: 1 } : "All";
  }
  const parsedContent = parseItems(content?.assignTo, []);
  const usersLength = parsedContent.length;
  let users;
  if (usersLength > 2 && minified) {
    users = `${parsedContent[0].firstName} ${parsedContent[0].lastName}, ${parsedContent[1].firstName} ${parsedContent[1].lastName}, ${parsedContent[2].firstName} ${parsedContent[2].lastName}...`;
  } else {
    users = parsedContent.reduce((a, b) => {
      return `${a ? `${a}, ` : ""} ${b.firstName} ${b.lastName}`;
    }, "");
  }
  return returnLength ? { users, usersLength: parsedContent.length } : users;
}

// General Amendment Validation

export function showRequestCancellingGeneralAmendment({ content }) {
  if (!content) return false;
  if (
    content.amendmentTypeId === REOCCURRING_AMENDMENT_TYPE_ID &&
    ![
      REOCCURRING_STATUS_CANCELLED_ID,
      REOCCURRING_STATUS_CREATED_ID,
      REOCCURRING_STATUS_CANCELLED_APPLIED_ID,
      REOCCURRING_STATUS_MANUAL_ID, //Applicable with integration is off - newly created recurring amendments will have this status
    ].includes(getAmendmentStatusInPPSId(content))
  )
    return true;
  return false;
}

export function showDeleteGeneralAmendment({ content }) {
  if (!content) return false;
  if (amendmentIsAssociatedWithPayrun({ amendment: content })) {
    return !RESOLVED_AMENDMENTS_IDS.includes(getAmendmentStatusId(content));
  } else {
    return ![
      ...RESOLVED_RECURRING_AMENDMENT_STATUSES,
      REOCCURRING_STATUS_IN_PROGRESS_ID,
    ].includes(getAmendmentStatusInPPSId(content));
  }
}

// General Amendments Grid
export function getGeneralAmendmentRequestCancelClaimName({
  content,
  generalAmendmentView,
  amendmentType,
}) {
  if (
    !amendmentIsAssociatedWithPayrun({ amendment: content }) &&
    [REOCCURRING_STATUS_EDITED_ID, REOCCURRING_STATUS_CREATED_ID].includes(
      getAmendmentStatusInPPSId(content)
    )
  ) {
    return generalAmendmentView
      ? "generalAmendmentCancel"
      : amendmentType === PERMANENT_AMENDMENT_TYPE
        ? "generalAmendmentAdhocAmendmentRequestCancel"
        : "generalAmendmentReoccurringAmendmentRequestCancel";
  }
  return "";
}

export function getGeneralAmendmentApplyInKeypayClaimName({
  content,
  generalAmendmentView,
  amendmentType,
}) {
  if (
    !amendmentIsAssociatedWithPayrun({ amendment: content }) &&
    getAmendmentStatusInPPSId(content) === REOCCURRING_STATUS_CREATED_ID
  ) {
    return generalAmendmentView
      ? "generalAmendmentApplyInKepay"
      : amendmentType === PERMANENT_AMENDMENT_TYPE
        ? "generalAmendmentAdhocAmendmentApplyInKepay"
        : "generalAmendmentReoccurringAmendmentApplyInKepay";
  }
  return "";
}

export function getGeneralAmendmentApplyCancelClaimName({
  content,
  generalAmendmentView,
}) {
  if (
    content &&
    content.amendmentTypeId === REOCCURRING_AMENDMENT_TYPE_ID &&
    getAmendmentStatusInPPSId(content) === REOCCURRING_STATUS_CANCELLED_ID
  ) {
    return generalAmendmentView
      ? "generalAmendmentApplyInKepay"
      : "generalAmendmentReoccurringAmendmentApplyInKepay";
  }
  return "";
}

export function getGeneralAmendmentApplyEditedClaimName({
  content,
  generalAmendmentView,
}) {
  if (
    content &&
    content.amendmentTypeId === REOCCURRING_AMENDMENT_TYPE_ID &&
    getAmendmentStatusInPPSId(content) === REOCCURRING_STATUS_EDITED_ID
  ) {
    return generalAmendmentView
      ? "generalAmendmentApplyInKepay"
      : "generalAmendmentReoccurringAmendmentApplyInKepay";
  }
  return "";
}

export function getGeneralAmendmentResubmitClaimName({
  content,
  generalAmendmentView,
  payrun,
}) {
  if (
    [1, 2, 3].includes(Number(payrun && payrun.payrunStatusID)) &&
    AMENDMENT_DECLINED_STATUS === getAmendmentStatusId(content)
  ) {
    return generalAmendmentView
      ? "generalAmendmentResubmit"
      : "payrunAmendmentResubmit";
  }
  return "";
}

export function getGeneralAmendmentDeleteComponentName({
  content,
  deleteComponent,
}) {
  if (
    content &&
    [
      AMENDMENT_CREATED_STATUS,
      AMENDMENT_DRAFT_STATUS,
      AMENDMENT_UPDATED_STATUS,
    ].includes(getAmendmentStatusId(content)) &&
    !hasDueDatePassed({ date: content && content.ammendmentDueDateTime })
  )
    return deleteComponent;
  return "";
}

export function getGeneralAmendmentEditComponentName({
  content,
  editComponent,
}) {
  if (
    content &&
    [AMENDMENT_CREATED_STATUS, AMENDMENT_DRAFT_STATUS].includes(
      getAmendmentStatusId(content)
    ) &&
    !hasDueDatePassed({ date: content && content.ammendmentDueDateTime })
  )
    return editComponent;
  return "";
}

// Pay Schedule

export function validateCreatePaySchedule({
  values,
  currencyFees,
  formIndex,
  bankFileDateInDay,
  editMode,
  updatePaySchedule,
  handleNext,
}) {
  if (!values) return false;
  if (Number(values.PayrollModel) === 2 && Number(values.BankfileDated) === 2) {
    if (!bankFileDateInDay) {
      message.error("Bank file date is required");
      return false;
    }
  }
  if (editMode) {
    updatePaySchedule(values);
    return false;
  }
  if (Number(formIndex) === 1 || Number(formIndex) === 0) {
    handleNext(values);
    return false;
  }
  if (
    !_.isEmpty(currencyFees) &&
    (values.fixedConversionRate === "" ||
      _.isUndefined(values.fixedConversionRate))
  ) {
    message.error("Please enter fixed currency conversion rate");
    return false;
  }
  if (
    !_.isEmpty(currencyFees) &&
    (values.percentConversionRate === "" ||
      _.isUndefined(values.percentConversionRate))
  ) {
    message.error("Please enter percent currency conversion rate");
    return false;
  }
  return true;
}

export function bankFileDifferentDayDisabled({ bankFileDated, pspUploadBank }) {
  return (
    (bankFileDated && Number(bankFileDated === 1)) ||
    (pspUploadBank && Number(pspUploadBank !== 1))
  );
}

// Country
export function isCountryAustralia({ country }) {
  return Number(country) === AUSTRALIA_COUNTRY_ID;
}

// Processing Fees
export function isProcessingFeesValid({
  feeOption,
  tax,
  feeValue,
  applyStateRate,
  invoiceDescription,
  updatedStateRate,
}) {
  if (!feeOption) {
    message.error("Please select a fee option.");
    return false;
  }
  if (_.isUndefined(tax) || tax === "") {
    message.error("Please select a tax option");
    return false;
  }

  if (!feeValue && Number(feeOption) === 3) {
    message.error("Please enter rate or percentage");
    return false;
  }

  if ([1, 2].includes(Number(feeOption)) && !feeValue && !applyStateRate) {
    message.error("Please enter rate or percentage");
    return false;
  }

  if (!invoiceDescription) {
    message.error("Please enter invoice description");
    return false;
  }
  if (
    applyStateRate &&
    _.isUndefined(_.find(updatedStateRate, (o) => Number(o.rate) > 0))
  ) {
    message.error("Please enter values for at least one state");
    return false;
  }
  return true;
}

export function isEmployeeRangeValid({
  updatedEmployeeRangeFactor,
  feeOption,
  addEmployeeNumber,
  validRange,
}) {
  if (!addEmployeeNumber) return true;
  if ([1, 2].includes(Number(feeOption))) {
    if (updatedEmployeeRangeFactor.find((o) => !isValidRate(o.rate))) {
      message.error("Pleser enter rates for employee range factor");
      return false;
    }
    if (validRange.find((o) => o.isValid === false)) {
      message.error("Please enter employee numbers within valid range");
      return false;
    }
    if (updatedEmployeeRangeFactor.find((o) => o.from >= o.to)) {
      message.error("Please enter employee numbers within valid range");
      return false;
    }
    return true;
  }
  return true;
}

export function processingFeesHasAtLeastOneFieldSelected({
  earningLines,
  payCategory,
  expenseCategory,
  feeOption,
  basePayChecked,
}) {
  const isEarninglineSelected =
    _.isEmpty(earningLines) || _.isUndefined(earningLines);
  const isPayCategorySelected =
    _.isEmpty(payCategory) || _.isUndefined(payCategory);
  const isExpenseCategorySelected =
    _.isEmpty(expenseCategory) || _.isUndefined(expenseCategory);
  if (
    [1].includes(Number(feeOption)) &&
    !basePayChecked &&
    isEarninglineSelected &&
    isPayCategorySelected &&
    isExpenseCategorySelected
  ) {
    message.error(
      "Please select at least one item from Base pay, Earninglines, Pay category and Expenses"
    );
    return false;
  }
  return true;
}

export function isRateRequired({ feeOption, applyStateRate }) {
  if (feeOption === 3) return true;
  if ([1, 2].includes(feeOption) && !applyStateRate) return true;
  return false;
}

// Check to see if the invoice endpoint returned data
export function canClearInvoiceInterval({ response, payrun }) {
  return !!response.data;
}

// Check to see if the getFiles endpoint return an invoice file and summary file
export function canClearFilesInterval({ response, payrun }) {
  // Check the response.data array for files with displayName:"Invoice" and "Summary"
  let invoiceFile = mustBeArray(response.data).some((o) => {
    return o.displayName === "Invoice";
  });
  let summaryFile = mustBeArray(response.data).some((o) => {
    return o.displayName === "Summary";
  });
  if (payrun?.isInvoiceNeeded) {
    return summaryFile && invoiceFile;
  }
  return summaryFile;
}

export function getPaymentTitle({ invoice }) {
  if (!invoice) return "Pending Payment";
  if (INVOICE_CONFIRM_PAYMENT.includes(Number(invoice?.invoiceStatusId))) {
    return "Payment Confirmed";
  }
  if (INVOICE_PAYMENT_SKIPPED.includes(Number(invoice?.invoiceStatusId))) {
    return "Payment Skipped";
  }
  if (INVOICE_PENDING_CONFIRMATION.includes(Number(invoice?.invoiceStatusId))) {
    return "Payment Pending Confirmation";
  }
  return "Pending Payment";
}

export function hasMinAmount({ question = {} }) {
  return !!(
    question?.threshold?.minAmount || question?.threshold?.minAmount === 0
  );
}

export function hasMaxAmount({ question = {} }) {
  return !!(
    question?.threshold?.maxAmount || question?.threshold?.maxAmount === 0
  );
}

export function getMaxAmount({ question = {} }) {
  return question?.threshold?.MaxAmount;
}

export function isABNValidated({ values }) {
  if (
    values?.country === AUSTRALIA_COUNTRY_ID &&
    isNullEmptyUndefined(values?.abn)
  ) {
    message.error("ABN is required for Australia");
    return false;
  }
  return true;
}

export function validateUsersAccessRights({ claim }) {
  if (!claim || !isComponentVisible({ claim })) {
    handleUnAuthorizedAccess();
    return false;
  }
  return true;
}
const handleUnAuthorizedAccess = () => {
  if (!window.location.href?.includes("/home")) {
    message.error(UNATHORIZED_ACCESS_ERRORS);
    goToRoute(DEFAULT_HOME_ROUTE);
  }
};
const userHasPspManagerRole = ({ user }) => {
  if (!user) return false;
  if (
    _.find(
      mustBeArray(user.userPersona),
      (o) => o.roleName === userRoles.PayrollManager
    )
  )
    return true;
  return false;
};

const userHasPspAdminRole = ({ user }) => {
  if (!user) return false;
  if (
    _.find(
      mustBeArray(user.userPersona),
      (o) => o.roleName === userRoles.PSPAdmin
    )
  )
    return true;
  return false;
};

export function showAssignBusinessUnit({ user, userType, member, pspId = "" }) {
  if (userType !== "psp" || !user || !user.activated || !user.enabled)
    return false;
  if (pspId) {
    let userPersonaWithPspRole = _.find(
      mustBeArray(user.userPersona),
      (o) =>
        [userRoles.PayrollManager, userRoles.PSPAdmin].includes(o.roleName) &&
        parseItems(o.tenant) &&
        parseItems(o.tenant).tenantId === pspId
    );
    if (
      userPersonaWithPspRole &&
      (isUserSystemAdmin({ member }) || isUserPSPAdmin({ member }))
    )
      return true;
    return false;
  }

  if (
    isUserSystemAdmin({ member }) &&
    (userHasPspManagerRole({ user }) || userHasPspAdminRole({ user }))
  )
    return true;
  const tenantId = getSelectedRoleTenantId({ member });
  const userRole = _.find(
    mustBeArray(user.userPersona),
    (o) =>
      [userRoles.PayrollManager, userRoles.PSPAdmin].includes(o.roleName) &&
      parseItems(o.tenant) &&
      parseItems(o.tenant).tenantId === tenantId
  );
  if (isUserPSPAdmin({ member }) && userRole) return true;
  return false;
}

export function userCanEdit({ user, member }) {
  if (isUserSystemAdmin({ member })) return true;

  const tenantId = getSelectedRoleTenantId({ member });
  const userPersona = _.find(
    mustBeArray(user && user.userPersona),
    (o) => parseItems(o.tenant) && parseItems(o.tenant).tenantId === tenantId
  );

  // If user is same as logged in user, user can be edited or
  // If user has psp manager role, it can be edited
  if (
    member?.details?.id === userPersona?.userID ||
    userPersona?.roleName === userRoles.PayrollManager
  )
    return true;
  return false;
}

export function handleUnAthorizedRequests() {
  if (!window.location.href?.includes("/home")) {
    message.error(FORBIDDEN_ACTION_ERROR);
    goToRoute(DEFAULT_HOME_ROUTE);
  }
}

export function handleUserUnassignBusinessUnitRedirection({
  user,
  member,
  pspId,
}) {
  let tenantId = getSelectedRoleTenantId({ member });
  const payrollId = pspId || tenantId;
  if (payrollId) {
    return goToRoute(`/psp/${payrollId}/user/${user.id}/assignbusinessunits`);
  } else {
    let tenant = parseItems(mustBeArray(user.userPersona)[0]?.tenant);
    return goToRoute(
      `/psp/${tenant?.tenantId}/user/${user.id}/assignbusinessunits`
    );
  }
}

export function userCanAssignPayrollManager({ claims }) {
  if (!claims) return false;
  const claim = _.find(
    mustBeArray(claims.components),
    (o) => o.name === BUSINESS_UNIT_ASSIGN_PAYROLL_MANAGER_MODAL_CONTAINER
  );
  return isComponentVisible({ claim });
}
