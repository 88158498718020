// @ts-nocheck
import { useAuth } from "@xemplo/auth-provider";
import { useHttpClient } from "@xemplo/http";
import React, { useEffect, useReducer, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AmendmentDetailsModal } from "../../../Common/amendments";
import { FlagIcon } from "../../../Common/customicons";
import PayRunStatus from "../../../Common/payrunStatus";
import messages from "../../../constants/message";
import { AMENDEMNT_UNRESOLVED_STATUS } from "../../../constants/payrun";
import { getPayScheduleDetailRoute } from "../../../libs";
import {
  multipleFieldsQueryGenerator,
  mustBeArray,
} from "../../../libs/utilities";
import { setCompUserDashAmendments } from "../../../slices/generalAmendment";
import { generalAmendmentHandler } from "../../actions/generalAmendment";
import { getPayrun } from "../../actions/payrun";
import { getPayschedule } from "../../actions/payschedule";
import AmendmentSingleLineGrid from "./AmendmentSingleLineGrid";
import CompanyUserSingleLineGridView from "./CompanyUserSingleLineGridView";

// Initial Amendment history modal state
const initialHistoryModalState = {
  viewModal: false,
  selectedAmendment: {},
  amendmentTotalActions: 0,
  isloading: false,
};

// Amendment modal reducer
const historyModalReducer = (state: any, action: any) => {
  switch (action.type) {
    case "historyLoading":
      return { ...state, isloading: action.payload };
    case "saveState":
      return { ...state, ...action.payload };
    case "reset":
      return { ...initialHistoryModalState };
    default:
      throw new Error();
  }
};

type Props = {
  className?: string;
  getAmendmentHistory: (options: any) => Promise<any>;
  updateHeader: (options: any) => void;
};

const classNameDefault = "";

const AmendmentCompanyUser = ({
  className = classNameDefault,
  updateHeader,
}: Props) => {
  const navigate = useNavigate();
  const dispatchAction = useDispatch();

  // TODO: The effect should be part of this httpClient hook. Once that's done, the unmount effect can be dumped
  const { cancelSignal } = useHttpClient();

  // Flag to check if we must redirect to the payrun page
  const [goToPayrun, setGoToPayrun] = useState(false);

  // Flag to check if we must redirect to the pay schedule page
  const [goToPaySchedule, setGoToPaySchedule] = useState(false);

  // Amendment loading state
  const [amIsloading, setAmLoading] = useState(true);

  // Amendment history modal state
  const [historyModalState, dispatchHistoryModal] = useReducer(
    historyModalReducer,
    initialHistoryModalState
  );

  // Amendment list from the store
  const amList = useSelector(
    ({ generalAmendment }: any) => generalAmendment.compUserDashAmendment.list,
    shallowEqual
  );

  // Pay run detail from the store
  const payRunDetail = useSelector(({ payrun }: any) => payrun.detail);

  // Pay run detail from the store
  const payScheduleDetail = useSelector(
    ({ payschedule }: any) => payschedule.detail
  );

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) return;
    // Get Amendments
    dispatchAction(
      generalAmendmentHandler({
        options: {
          q: {
            statusID: multipleFieldsQueryGenerator({
              value: AMENDEMNT_UNRESOLVED_STATUS,
            }),
          },
          per_page: 5,
        },
        type: "compUserDashAmendment",
        action: "list",
      })
    ).then((response: any) => {
      dispatchAction(
        setCompUserDashAmendments({
          data: mustBeArray(response?.data),
          total: response?.total || 0,
        })
      );
      setAmLoading(false);
    });
  }, [isAuthenticated]);

  // Handler row click
  const handleAction = ({ event, data }: any) => {
    event?.stopPropagation();

    dispatchHistoryModal({
      type: "saveState",
      payload: { selectedAmendment: data, viewModal: true },
    });
  };

  // Close modal
  const handleCloseAmendmentModal = () => {
    dispatchHistoryModal({
      type: "saveState",
      payload: { selectedAmendment: {}, viewModal: false },
    });
  };

  // Go to pay schedule view
  /**
   * TODO
   */
  const handlePayscheduleView = () => {
    const { selectedAmendment } = historyModalState;
    setGoToPaySchedule(true);
    dispatchAction(
      getPayschedule({
        id: selectedAmendment?.payscheduleID,
        cancelToken: cancelSignal.token,
        options: {},
        targetEndpoint: undefined,
      })
    );
  };

  // Handle what to do when we get the pay schedule detail
  useEffect(() => {
    if (goToPaySchedule && payScheduleDetail) {
      setGoToPaySchedule(false);
      const { id, businessUnitID, companyId } = payScheduleDetail;
      navigate(
        getPayScheduleDetailRoute({
          id,
          companyId,
          businessUnitId: businessUnitID,
        })
      );
    }
  }, [payScheduleDetail]);

  // Get payrun detail when user clicks on the payrun name
  const handlePayrunView = () => {
    const { selectedAmendment } = historyModalState;

    setGoToPayrun(true);
    dispatchAction(
      getPayrun({
        id: selectedAmendment?.payrunId,
        options: {},
        cancelToken: cancelSignal.token,
      })
    );
  };

  // Handle what to do when we get the payrun detail
  useEffect(() => {
    if (
      goToPayrun &&
      payRunDetail &&
      PayRunStatus.getStatus({
        id: payRunDetail.payrunStatusID,
      }) !== "draft"
    ) {
      setGoToPayrun(false);
      navigate(`/payrun/${historyModalState?.selectedAmendment.payrunId}`);
    }
  }, [payRunDetail]);

  const handleCreate = () => {
    navigate("/generalAmendments");
  };

  useEffect(() => {
    return () => {
      cancelSignal.cancel(
        "AmendmentCompanyUser unmounted: Cancelling any pending API requests"
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div className={className}>
        <CompanyUserSingleLineGridView
          handleAction={handleAction}
          loading={amIsloading}
          list={amList}
          viewMore="/generalAmendments"
          title="Amendments Pending Approval"
          icon={<FlagIcon />}
          DisplayComponent={AmendmentSingleLineGrid}
          handleCreate={handleCreate}
          noDataDescription={messages["pendingAmendments.noData"]}
        />
      </div>
      <AmendmentDetailsModal
        amendmentId={historyModalState?.selectedAmendment.amendmentId}
        handlePayrunView={handlePayrunView}
        handlePayscheduleView={handlePayscheduleView}
        handleClose={handleCloseAmendmentModal}
        visible={historyModalState.viewModal}
      />
    </React.Fragment>
  );
};

export default AmendmentCompanyUser;
