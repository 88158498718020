
import { formatToCurrency } from "@xemplo/common-util";
import {
  Amendment,
  AmendmentKeypayStatusIds,
  ChangeTaskTypes,
} from "@xemplo/gp-types";
import { DawnCircleInformation } from "@xemplo/icons";
import { Tooltip, TooltipPlacement } from "@xemplo/tooltip";
import { Colour } from "@xemplo/style-constants";
import styled from "styled-components";
interface PendingAmountValueProps {
  value?: number | null;
  isIntegrated?: boolean;
  amendmentKeypayStatusId?: Amendment["amendmentKeypayStatusId"];
  amendmentTaskTypeId?: Amendment["amendmentTaskTypeId"];
}
export function PendingAmountValue(props: Readonly<PendingAmountValueProps>) {
  const { value, isIntegrated, amendmentKeypayStatusId, amendmentTaskTypeId } =
    props;

  const isPayInstruction =
    amendmentTaskTypeId === ChangeTaskTypes.PAY_INSTRUCTION;

  if (isPayInstruction) {
    return value ? <>{formatToCurrency({ value: value })}</> : <>-</>;
  }

  if (value) return <>{formatToCurrency({ value: value })}</>;

  //We only need to show pending state for amounts if the amendment is integrated and has not yet been applied to keypay
  if (
    isIntegrated &&
    amendmentKeypayStatusId !== AmendmentKeypayStatusIds.Success &&
    !value
  ) {
    return (
      <Pending>
        Pending
        <Tooltip
          text="Pending amounts will be available once amendment is applied in PPS (Payroll Processing Service) "
          placement={TooltipPlacement.BottomLeft}
        >
          <DawnCircleInformation height={16} width={16} />
        </Tooltip>
      </Pending>
    );
  }
  return <>{formatToCurrency({ value: value })}</>;
}

const Pending = styled.span`
  color: ${Colour.Gray[300]};
  display: flex;
  gap: 4px;
  align-items: center;
`;
