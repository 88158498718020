import { ColumnDef } from '@tanstack/react-table';

import { AmendmentStatus } from '@xemplo/amendment-status';
import { TotalAmountCell } from '@xemplo/amendments-table';
import { tryJsonParse } from '@xemplo/common-util';
import { DEFAULT_DATE_FORMAT, formatDate } from '@xemplo/date-time';
import { Amendment, AmendmentStatusIds, RequestedBy } from '@xemplo/gp-types';
import { getAssignedWorkerDisplayName } from '@xemplo/gp-utils';

import * as S from './amendment-modal.styles';
export const columns: ColumnDef<Amendment>[] = [
  {
    id: 'assignTo',
    accessorKey: 'assignTo',
    header: 'Applies to',
    cell: ({ row }) => {
      if (row.original.assignTo === 'All') return 'All';
      return (
        <S.AppliesTo>{getAssignedWorkerDisplayName(row.original.assignTo)}</S.AppliesTo>
      );
    },
  },
  {
    id: 'amendmentTaskTypeName',
    accessorKey: 'amendmentTaskTypeName',
    header: 'Type',
  },

  {
    id: 'requestedDate',
    accessorKey: 'requestedDate',
    header: 'Requested',
    cell: ({ row }) => {
      return formatDate({
        value: row.original.requestedDate,
        format: DEFAULT_DATE_FORMAT,
      });
    },
  },
  {
    id: 'requestedBy',
    accessorKey: 'requestedBy',
    header: 'Requested By',
    cell: ({ row }) => {
      const requestedBy = tryJsonParse<RequestedBy>(row.original.requestedBy ?? '');
      return requestedBy?.UserName;
    },
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const amendmentStatusId =
        row.original.amendmentStatusId ?? row.original.amendmentStatusID;
      return (
        <AmendmentStatus
          status={amendmentStatusId}
          statusName={row.original.amendmentStatusName}
        />
      );
    },
  },
  {
    //TODO: Update this once BE returns value https://expedo.atlassian.net/browse/GC-870
    // Re-enable sorting and accessorFn once BE returns the value
    id: 'totalAmount',
    header: 'Total Amount',
    cell: TotalAmountCell,
  },
];
