import React, { useEffect, useState } from "react";
import {
  deleteAmendment,
  getAmendment,
  updateTermination,
} from "../../actions/payrun";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Card, Col, Layout, message, Row } from "antd";
import CustomNavbar from "../../components/customNavbar";
import {
  AMENDMENT_MODIFIED_STATUS,
  DEFAULT_GUTTER,
  REOCCURRING_STATUS_EDITED_ID,
} from "../../../constants";
import {
  getConditionalResponse,
  getMatchParams,
  handleRequest,
  isNull,
  isNullEmptyUndefined,
  mustBeArray,
  parseItems,
} from "../../../libs";
import classNames from "classnames";
import QuestionRows from "./QuestionRows";
import { If, Loading } from "../../../Common/ui";
import { generalAmendmentHandler } from "../../actions/generalAmendment";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useHttpClient } from "@xemplo/http";
import { RootState } from "../../../store";
import { updateHeader } from "../../../User/actions/member";

const { Header, Content } = Layout;

const extraQuestion = {
  threshold: {
    maxAmount: 99999.99,
    minAmount: -99999.99,
  },
  type: "Currency",
  name: "",
  value: "",
};

interface AmendmentQuestionEditViewProps {
  match: any;
}

const AmendmentQuestionEditView: React.FC<AmendmentQuestionEditViewProps> = ({
  match,
}) => {
  const { cancelSignal } = useHttpClient();

  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const [amendment, setAmendment] = useState<any>({});
  const [summary, setSummary] = useState<any>({ questions: [] });
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const { header } = useAppSelector((state: RootState) => state.pageHeader);

  useEffect(() => {
    if (!header?.module) {
      dispatch(
        updateHeader({
          header: {
            module: "Back to Payroll Changes",
            returnUrl: "/generalAmendments",
            title: "New Payroll Change",
          },
        })
      );
    }
    const payrunId = getMatchParams({ match, field: "payrunId" });
    const id = getMatchParams({ match, field: "id" });
    setPageLoading(true);
    dispatch(
      getAmendment({
        options: getConditionalResponse({
          condition: payrunId && id,
          resp1: { payrunID: payrunId },
          resp2: {},
        }),
        targetEndpoint: "",
        cancelToken: cancelSignal.token,
        id,
      })
    ).then((res: any) => {
      setAmendment(res.data);
      setSummary(parseItems(res.data.amendmentSummary));
      setPageLoading(false);
    });
  }, []);

  // Save values to the summary
  const onChangeField = function (
    field: string,
    value: any,
    isDefault?: boolean
  ) {
    // Find the field in the summary and update it
    const newSummary = { ...summary };
    // Find the field in the summary and update it
    newSummary.questions = newSummary.questions.map((question: any) => {
      if (question.name === field) {
        if (!isNull(value) && typeof value === "object") {
          // Special inputs
          if (value?.type === "YesNoTimePeriod") {
            question.questions.map((q: any) => {
              if (q.name === value.name) {
                if (isDefault) {
                  q.value = q.default ? "" : q.value;
                  q.default = !q.default;
                } else {
                  q.value = value.value;
                }
              }
            });
          } else {
            // Key pair fields
            question.questions[value.index][value.type] = value.value; // type could name or value
          }
        } else {
          question.value = value;
        }
      }
      return question;
    });
    // update the summary
    setSummary(newSummary);
  };

  // Add key pair question
  const addQuestion = (field: string) => {
    const newSummary = { ...summary };
    newSummary.questions = newSummary.questions.map((question: any) => {
      if (question.name === field) {
        question.questions = [...question.questions, { ...extraQuestion }];
      }
      return question;
    });
    setSummary(newSummary);
  };

  // Remove key pair question
  const removeQuestion = (field: string, index: number) => {
    const newSummary = { ...summary };
    newSummary.questions = newSummary.questions.map((question: any) => {
      if (question.name === field) {
        //remove the question from the array based on the index without mutting the array
        question.questions = question.questions.filter(
          (q: any, i: number) => i !== index
        );
      }
      return question;
    });
    setSummary(newSummary);
  };

  const handleDelete = () => {
    const payrunId = getMatchParams({ match, field: "payrunId" });
    const id = getMatchParams({ match, field: "id" });
    if (payrunId) {
      return dispatch(
        deleteAmendment({ id, options: { payrunID: payrunId } })
      ).then((resp: any) => {
        return resp;
      });
    } else {
      return dispatch(
        generalAmendmentHandler({
          id,
          cancelToken: cancelSignal.token,
          action: "delete",
        })
      ).then((resp: any) => {
        return resp;
      });
    }
  };

  // Question validations
  const requiredFieldsAnswered = ({ questions }: { questions: Array<any> }) => {
    const hasMissingFields = mustBeArray(questions).some(
      (o) => o.required && !o.value
    );
    if (hasMissingFields) return "Please answer all the required fields.";
    // Validate Yes No Fields
    let YesNoFieldsErrors = null;
    let KeyPairFieldsErrors = null;
    mustBeArray(questions).forEach((q) => {
      if (!isEmpty(mustBeArray(q.questions))) {
        mustBeArray(q.questions).forEach((subQ) => {
          const newValue = isNullEmptyUndefined(subQ.value);
          const newName = isNullEmptyUndefined(subQ.name);
          // Check value is entered when Yes selected
          if (q.type === "Sub" && subQ.default && newValue) {
            YesNoFieldsErrors = "Please enter values for YES selections.";
          }
          // Check if both text and amount field are populated
          if (
            q.type === "KeyPairDynamic" &&
            ((newValue && !newName) || (!newValue && newName))
          ) {
            KeyPairFieldsErrors =
              "Please enter values for both text and amount fields.";
          }
        });
      }
    });
    return YesNoFieldsErrors || KeyPairFieldsErrors;
  };

  // Save changes to the questions
  const handleSave = () => {
    setSaveLoading(true);
    // Validate the answers
    const formError = requiredFieldsAnswered({ questions: summary?.questions });
    if (formError) {
      setSaveLoading(false);
      return message.error(formError);
    }
    // For Yes no Field if yes selected - we need a value for hours

    const id = getMatchParams({ match, field: "id" });
    let payload = {
      id,
      amendmentSummary: JSON.stringify({ ...summary }),
    };

    updateTermination(payload).then((resp: any) => {
      setSaveLoading(false);
      if (
        handleRequest({
          response: resp,
          successMessage: "Amendment updated successfully",
          hasValidationErrors: true,
        })
      )
        navigate(`${header.returnUrl}`);
    });
  };

  // Cancel the changes and redirect the previous screen
  const handleCancel = () => {
    navigate(`${header.returnUrl}`);
  };

  useEffect(() => {
    return () =>
      void cancelSignal.cancel(
        "AmendmentQuestionEditView unmounted: Cancelling any pending API requests"
      );
  }, []);

  return (
    <Layout>
      <Header className="bg-white w-full position-absolute-top">
        <CustomNavbar
          handleCancel={handleCancel}
          handleSave={handleSave}
          loading={saveLoading}
          handleDelete={handleDelete}
          enableBack
          pageTitle="Payroll Change"
          header={header}
        />
      </Header>
      <Layout className="p-6 rounded-[5px] create-screen-body w-full overflow-y-auto">
        <Content>
          <Row gutter={DEFAULT_GUTTER} className="mx-0">
            <Col className="pr-0 pl-6">
              <Card
                className={classNames({
                  "h-24 min-h-full relative": pageLoading,
                })}
              >
                <If
                  condition={pageLoading}
                  then={<Loading />}
                  else={
                    <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-6 gap-y-3">
                      <QuestionRows
                        addQuestion={addQuestion}
                        questions={summary.questions}
                        onChangeField={onChangeField}
                        removeQuestion={removeQuestion}
                      />
                    </div>
                  }
                />
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AmendmentQuestionEditView;
