//@ts-nocheck
import React from "react";
import { Tabs, message } from "antd";
import _ from "lodash";
import {
  mustBeArray,
  findAuthorizationClaims,
  errorDisplay,
  delay,
  updateAuthorizationClaims,
  getPersonalizationData,
  filteredByLabel,
  requestUrlBuilder,
  getCurrentCardGrid,
  getCurrentGridPageSize,
  getCurrentTableSortInfo,
  getCurrentGridSortInfo,
  hasAuthorizationToViewComponent,
  setPersonalizationData,
  getUserArea,
  getReoccurringAmendmentKeypayActionId,
  conditionalParameter,
  getOptionalParameter,
  errorHandler,
  getConditionalResponse,
  listHasMoreData,
  isUserSystemAdmin,
  isComponentVisible,
  trimWhiteSpaces,
  getDefaultPage,
  getDefaultPerPage,
  getPayScheduleDetailRoute,
} from "../../../libs";
import { AMENDMENT_FIELD_OPTIONS } from "../../../constants/sortingOptions";
import { PAYRUN_TYPES_OPTIONS } from "../../../constants/options";
import {
  MULTI_TAB_ACTIVE_KEY_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_SCROLLING,
  GRID_VIEW_VALUE,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  STRING_PERSONALIZATION,
  SINGLE_LINE_GRID_VALUE,
} from "../../../constants/defaultClaims";
import axios from "axios";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import ChildTabContainer from "../../../Common/ClaimContainers/ChildTabContainer";
import TabHeader from "../../../Common/tabHeader";
import PayRunStatus from "../../../Common/payrunStatus";
import APIHandler from "../../../constants/apiUrl";
import GeneralAmendmentSingleLineGridDisplay from "../../components/payrun/GeneralAmendments/GeneralAmendmentSingleLineGridDisplay";
import {
  PERMANENT_AMENDMENT_TYPE,
  PERMANENT_AMENDMENT_TYPE_ID,
  AMENDMENT_TYPES_OPTIONS,
  DEFAULT_GUID,
  PAYRUN_AMENDMENT_TYPE,
  PAYRUN_AMENDMENT_TYPE_ID,
  REOCCURRING_AMENDMENT_TYPE,
  REOCCURRING_AMENDMENT_TYPE_ID,
} from "../../../constants/payrun";
import AmendmentDetailList from "../../components/payrun/amendmentDetailList";
import AmendmentDisplayCard from "../../components/payrun/amendmentDisplayCard";
import userRoles from "../../../constants/userRoles";
import SwitchButtonWithText from "../../../Common/ClaimComponents/Filter/SwitchButtonWithText";
import { AmendmentDetailsModal } from "../../../Common/amendments";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import {
  GENERAL_AMENDMENT_ADHOC_AMENDMENT_TAB_CONTAINER,
  GENERAL_AMENDMENT_FILTER_CONTAINER,
  GENERAL_AMENDMENT_FILTER_TEXTBOX,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_CHILD_TAB_CONTAINER,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DELETE,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DETAIL_VIEW,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_EDIT,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER,
  GENERAL_AMENDMENT_PAYRUN_AMENDMENT_TAB_CONTAINER,
  GENERAL_AMENDMENT_RESET_FILTER,
  GENERAL_AMENDMENT_SEARCH_BUTTON,
  GOOGLE_ANALYTICS_PAGE_TITLE,
  MAX_AMENDMENT_PER_PAGE,
} from "../../../constants";
import { If } from "../../../Common/ui";
import { withRouter } from "../../../hooks";
import { CreateAmedmentButton } from "./CreateAmendmentButton";

// ADHOC & ONCE-OFF are previous terms for PERMANENT amendments. Permenent Amendments is the current correct name.

const TabPane = Tabs.TabPane;

type State = any;
type Props = any;
class GeneralAmendmentIndex extends React.Component<Props, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      activeKey: "1",
      basic: {},
      payRun: [],
      payrunTypeId: 3,
      options: PAYRUN_TYPES_OPTIONS,
      amendmentTypes: [
        { type: PAYRUN_AMENDMENT_TYPE },
        { type: PERMANENT_AMENDMENT_TYPE },
        { type: REOCCURRING_AMENDMENT_TYPE },
      ],
      generalAmendmentType: [{ type: "generalAmendment" }],
      filters: {},
      // Payrun Amendmentss
      payrunAmendmentPagination: 1,
      payrunAmendmentPerPage: 10,
      payrunAmendmentTotal: 0,
      payrunAmendmentList: [],
      payrunAmendmentListLoading: true,
      payrunAmendmentAddable: false,
      payrunAmendmentGridable: false,
      payrunAmendmentGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      payrunAmendmentGridView: false,
      payrunAmendmentIsPinned: "false",
      payrunAmendmentSortingType: "notrequired",
      payrunAmendmentHasMoreData: false,
      payrunAmendmentSortInfo: {},
      payrunAmendmentSelectedGridSort: undefined,
      payrunAmendmentFieldOptions: AMENDMENT_FIELD_OPTIONS,
      payrunAmendmentGridSortOption: AMENDMENT_FIELD_OPTIONS,

      // One-time Amendments
      adhocAmendmentPagination: 1,
      adhocAmendmentPerPage: 10,
      adhocAmendmentTotal: 0,
      adhocAmendmentList: [],
      adhocAmendmentListLoading: true,
      adhocAmendmentAddable: false,
      adhocAmendmentGridable: false,
      adhocAmendmentGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      adhocAmendmentGridView: false,
      adhocAmendmentIsPinned: "false",
      adhocAmendmentSortingType: "notrequired",
      adhocAmendmentHasMoreData: false,
      adhocAmendmentSortInfo: {},
      adhocAmendmentSelectedGridSort: undefined,
      adhocAmendmentFieldOptions: AMENDMENT_FIELD_OPTIONS,
      adhocAmendmentGridSortOption: AMENDMENT_FIELD_OPTIONS,

      // Reoccurring Amendments
      reoccurringAmendmentPagination: 1,
      reoccurringAmendmentPerPage: 10,
      reoccurringAmendmentTotal: 0,
      reoccurringAmendmentList: [],
      reoccurringAmendmentListLoading: true,
      reoccurringAmendmentAddable: false,
      reoccurringAmendmentGridable: false,
      reoccurringAmendmentGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      reoccurringAmendmentGridView: false,
      reoccurringAmendmentIsPinned: "false",
      reoccurringAmendmentSortingType: "notrequired",
      reoccurringAmendmentHasMoreData: false,
      reoccurringAmendmentSortInfo: {},
      reoccurringAmendmentGridSortOption: AMENDMENT_FIELD_OPTIONS,
      reoccurringAmendmentSelectedGridSort: undefined,
      reoccurringAmendmentFieldOptions: AMENDMENT_FIELD_OPTIONS,
      reoccurringAmendmentShowArchived: false,

      // General Amendments for System User
      // Payrun Amendmentss
      generalAmendmentPagination: 1,
      generalAmendmentPerPage: 10,
      generalAmendmentTotal: 0,
      generalAmendmentList: [],
      generalAmendmentListLoading: true,
      generalAmendmentAddable: false,
      generalAmendmentGridable: false,
      generalAmendmentGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      generalAmendmentGridView: false,
      generalAmendmentIsPinned: "false",
      generalAmendmentSortingType: "notrequired",
      generalAmendmentHasMoreData: false,
      generalAmendmentSortInfo: {},
      generalAmendmentSelectedGridSort: undefined,
      generalAmendmentFieldOptions: AMENDMENT_FIELD_OPTIONS,
      generalAmendmentGridSortOption: AMENDMENT_FIELD_OPTIONS,

      searchLoading: false,
      updatedComponentPersonalization: [],
      selectedAmendment: {},
      amendmentHistoryList: [],
      amendmentHistoryLoading: true,
      amendmentTotalActions: 0,
      // hasActivePayrun: false,
      pageLoading: true,
    };
  }
  componentDidMount() {
    const { dashboardView, pageClaims } = this.props;
    if (dashboardView) {
      this.handleDashboardView();
    } else {
      this.props.updateHeader({
        header: {
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.AmendmentList,
          title: "Amendments",
          module: "",
          enableBack: false,
          entity: "generalAmendment",
          action: "",
        },
      });
      // Filter container
      const generalAmendmentNameTextboxFilter = findAuthorizationClaims({
        claims: conditionalParameter({
          data: this.props.pageClaims,
          field: "components",
        }),
        name: GENERAL_AMENDMENT_FILTER_TEXTBOX,
      });
      const nameTextBoxPersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData: generalAmendmentNameTextboxFilter?.personalization,
      });

      const generalAmendmentTypeDropdownFilter = findAuthorizationClaims({
        claims: conditionalParameter({
          data: this.props.pageClaims,
          field: "components",
        }),
        name: "generalAmendmentTypeDropdownFilter",
      });
      const amendmentTypePersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData:
          generalAmendmentTypeDropdownFilter?.personalization,
      });

      this.setState({
        filters: {
          name:
            nameTextBoxPersonalizations?.value &&
            hasAuthorizationToViewComponent(
              generalAmendmentNameTextboxFilter?.authorizationStatusId
            )
              ? nameTextBoxPersonalizations.value
              : "",
          type:
            amendmentTypePersonalizations?.value &&
            hasAuthorizationToViewComponent(
              generalAmendmentTypeDropdownFilter?.authorizationStatusId
            )
              ? amendmentTypePersonalizations.value
              : undefined,
        },
      });
      if ([userRoles.SystemAdminArea].includes(getUserArea())) {
        this.loadGeneralAmendment();
      } else {
        this.loadMultiTabContents();
      }
    }
  }

  handleDashboardView = () => {
    const { dashboardClaims, type } = this.props;
    const personalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: dashboardClaims,
    });
    if (!personalizations || !personalizations.gridView) {
      this.setState({ [`${type}GridView`]: false });
    } else {
      this.setState({
        [`${type}SingleLineGridView`]:
          personalizations.gridView === SINGLE_LINE_GRID_VALUE,
      });
    }
    this.listGeneralAmendment({ options: {}, type, notUpdate: true });
  };

  loadMultiTabContents = () => {
    const { pageClaims } = this.props;
    // Multi tab
    const generalAmendmentMultiTabContainer = _.find(
      mustBeArray(
        conditionalParameter({
          data: this.props.pageClaims,
          field: "components",
        })
      ),
      (o) => o.name === "generalAmendmentMultiTabContainer"
    );
    const generalAmendmentMultiTabPersonalizations = getPersonalizationData({
      type: "multiTabContainer",
      personalizationData: generalAmendmentMultiTabContainer?.personalizations,
    });
    if (generalAmendmentMultiTabPersonalizations?.activeKey) {
      this.setState({
        activeKey: generalAmendmentMultiTabPersonalizations.activeKey,
      });
      this.props.updateHeader({
        header: {
          title: "Amendments",
          module: "",
          enableBack: false,
          entity: "generalAmendment",
          action: "",
        },
      });
    }
    this.loadPayrunAmendment({ generalAmendmentMultiTabContainer });
    this.loadReoccurringAmendment({ generalAmendmentMultiTabContainer });

    delay(300).then(() => {
      this.loadAdhocAmendment({
        generalAmendmentMultiTabContainer,
        pageLoad: true,
      });
    });
  };

  loadGeneralAmendment = () => {
    const { pageClaims } = this.props;
    const generalAmendmentListContainer = findAuthorizationClaims({
      claims: mustBeArray(pageClaims?.components),
      name: "generalAmendmentListContainer",
    });
    const generalAmendmentGeneralTable = findAuthorizationClaims({
      claims: generalAmendmentListContainer?.components,
      name: "generalAmendmentGeneralTable",
    });
    const generalAmendmentGeneralGrid = findAuthorizationClaims({
      claims: generalAmendmentListContainer?.components,
      name: "generalAmendmentGeneralGrid",
    });
    const generalAmendmentPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: generalAmendmentListContainer?.personalizations,
    });
    this.setState({
      generalAmendmentPaginationType:
        generalAmendmentPersonalizations?.paginationType,
      generalAmendmentSortingType:
        generalAmendmentPersonalizations?.sortingType,
      generalAmendmentIsPinned: generalAmendmentPersonalizations?.isPinned,
      generalAmendmentGridable: generalAmendmentListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: generalAmendmentGeneralTable })) {
      const generalAmendmentTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: generalAmendmentGeneralTable.personalizations,
      });
      const generalAmendmentPagination = getDefaultPage({
        page: generalAmendmentTablePersonalizations?.pageNumber,
      });
      const pageSize = getDefaultPerPage({
        perPage: generalAmendmentTablePersonalizations?.pageSize,
      });
      const SortOrder =
        generalAmendmentTablePersonalizations?.sortOrder ?? null;
      const SortColumn =
        generalAmendmentTablePersonalizations?.sortColumn ?? null;
      this.setState(
        {
          generalAmendmentPerPage:
            generalAmendmentPersonalizations?.generalAmendmentPaginationType ===
            PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize }),
          generalAmendmentPagination,
          generalAmendmentSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!generalAmendmentPersonalizations.gridView) {
            if (
              generalAmendmentPersonalizations.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGeneralAmendment({
                pageLoad: true,
                type: "generalAmendment",
                notUpdate: true,
              });
            } else {
              this.listGeneralAmendment({
                options: {},
                type: "generalAmendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }

    // If Grid
    if (isComponentVisible({ claim: generalAmendmentGeneralGrid })) {
      const generalAmendmentGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: generalAmendmentGeneralGrid.personalizations,
      });
      const pageNumber = getDefaultPage({
        page: generalAmendmentGridPersonalizations?.pageNumber,
      });
      const row = generalAmendmentGridPersonalizations?.row
        ? Number(generalAmendmentGridPersonalizations.row)
        : 5;
      const col = generalAmendmentGridPersonalizations?.col
        ? Number(generalAmendmentGridPersonalizations.col)
        : 4;
      const SortOrder = generalAmendmentGridPersonalizations?.sortOrder ?? null;
      const SortColumn =
        generalAmendmentGridPersonalizations?.sortColumn ?? null;
      this.setState(
        {
          generalAmendmentGrid: {
            page: getDefaultPage({ page: pageNumber }),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          generalAmendmentSelectedGridSort: _.find(
            this.state.generalAmendmentGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            generalAmendmentPersonalizations?.gridView &&
            generalAmendmentPersonalizations?.gridView !==
              SINGLE_LINE_GRID_VALUE
          ) {
            this.setState({ generalAmendmentGridView: true });
            if (
              generalAmendmentPersonalizations.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridGeneralAmendment({
                pageLoad: true,
                type: "generalAmendment",
                notUpdate: true,
              });
            } else {
              this.generalAmendmentFetchMoreData({
                isPaginated: true,
                type: "generalAmendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  loadPayrunAmendment = ({ generalAmendmentMultiTabContainer }) => {
    const payrunAmendmentListContainer = findAuthorizationClaims({
      claims: mustBeArray(generalAmendmentMultiTabContainer?.components),
      name: GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER,
    });
    const payrunAmendmentGeneralTable = findAuthorizationClaims({
      claims: payrunAmendmentListContainer?.components,
      name: GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE,
    });
    const payrunAmendmentGeneralGrid = findAuthorizationClaims({
      claims: payrunAmendmentListContainer?.components,
      name: GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID,
    });
    const payrunAmendmentPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: payrunAmendmentListContainer?.personalizations,
    });
    this.setState({
      payrunAmendmentPaginationType:
        payrunAmendmentPersonalizations?.paginationType,
      payrunAmendmentSortingType: payrunAmendmentPersonalizations?.sortingType,
      payrunAmendmentIsPinned: payrunAmendmentPersonalizations?.isPinned,
      payrunAmendmentGridable: payrunAmendmentListContainer?.gridable,
    });
    if (payrunAmendmentPersonalizations?.gridView === SINGLE_LINE_GRID_VALUE) {
      this.setState({ payrunAmendmentSingleLineGridView: true });
      delay(300).then(() => {
        this.listSingleLineGridGeneralAmendment({
          options: {},
          type: "payrunAmendment",
          notUpdate: true,
        });
      });
    }

    // If Table
    if (isComponentVisible({ claim: payrunAmendmentGeneralTable })) {
      const payrunAmendmentTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: payrunAmendmentGeneralTable.personalizations,
      });
      const payrunAmendmentPagination = getDefaultPage({
        page: payrunAmendmentTablePersonalizations?.pageNumber,
      });
      const pageSize = getDefaultPerPage({
        perPage: payrunAmendmentTablePersonalizations?.pageSize,
      });
      const SortOrder = payrunAmendmentTablePersonalizations?.sortOrder ?? null;
      const SortColumn =
        payrunAmendmentTablePersonalizations?.sortColumn ?? null;
      this.setState(
        {
          payrunAmendmentPerPage:
            payrunAmendmentPersonalizations &&
            payrunAmendmentPersonalizations.payrunAmendmentPaginationType ===
              PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize }),
          payrunAmendmentPagination,
          payrunAmendmentSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!payrunAmendmentPersonalizations?.gridView) {
            if (
              payrunAmendmentPersonalizations.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGeneralAmendment({
                pageLoad: true,
                type: "payrunAmendment",
                notUpdate: true,
              });
            } else {
              this.listGeneralAmendment({
                options: {},
                type: "payrunAmendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }

    // If Grid
    if (isComponentVisible({ claim: payrunAmendmentGeneralGrid })) {
      const payrunAmendmentGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: payrunAmendmentGeneralGrid.personalizations,
      });
      const pageNumber = getDefaultPage({
        page: payrunAmendmentGridPersonalizations?.pageNumber,
      });
      const row = payrunAmendmentGridPersonalizations?.row
        ? Number(payrunAmendmentGridPersonalizations.row)
        : 5;
      const col = payrunAmendmentGridPersonalizations?.col
        ? Number(payrunAmendmentGridPersonalizations.col)
        : 4;
      const SortOrder = payrunAmendmentGridPersonalizations?.sortOrder ?? null;
      const SortColumn =
        payrunAmendmentGridPersonalizations?.sortColumn ?? null;
      this.setState(
        {
          payrunAmendmentGrid: {
            page: getDefaultPage({ page: pageNumber }),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          payrunAmendmentSelectedGridSort: _.find(
            this.state.payrunAmendmentGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            payrunAmendmentPersonalizations?.gridView &&
            payrunAmendmentPersonalizations.gridView !== SINGLE_LINE_GRID_VALUE
          ) {
            this.setState({ payrunAmendmentGridView: true });
            if (
              payrunAmendmentPersonalizations.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridGeneralAmendment({
                pageLoad: true,
                type: "payrunAmendment",
                notUpdate: true,
              });
            } else {
              this.generalAmendmentFetchMoreData({
                isPaginated: true,
                type: "payrunAmendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  loadAdhocAmendment = ({
    generalAmendmentMultiTabContainer,
    pageLoad = false,
  }) => {
    const adhocAmendmentListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        generalAmendmentMultiTabContainer &&
          generalAmendmentMultiTabContainer.components
      ),
      name: "generalAmendmentAdhocAmendmentListContainer",
    });
    const adhocAmendmentGeneralTable = findAuthorizationClaims({
      claims: adhocAmendmentListContainer?.components,
      name: "generalAmendmentAdhocAmendmentGeneralTable",
    });
    const adhocAmendmentGeneralGrid = findAuthorizationClaims({
      claims: adhocAmendmentListContainer?.components,
      name: "generalAmendmentAdhocAmendmentGeneralGrid",
    });
    const adhocAmendmentPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData:
        adhocAmendmentListContainer &&
        adhocAmendmentListContainer.personalizations,
    });

    this.setState({
      adhocAmendmentPaginationType:
        adhocAmendmentPersonalizations?.paginationType,
      adhocAmendmentSortingType: adhocAmendmentPersonalizations?.sortingType,
      adhocAmendmentIsPinned: adhocAmendmentPersonalizations?.isPinned,
      adhocAmendmentGridable: adhocAmendmentListContainer?.gridable,
    });

    if (adhocAmendmentPersonalizations.gridView === SINGLE_LINE_GRID_VALUE) {
      this.setState({ adhocAmendmentSingleLineGridView: true });
      delay(300).then(() => {
        this.listSingleLineGridGeneralAmendment({
          options: {},
          type: "adhocAmendment",
          notUpdate: true,
          pageLoad,
        });
      });
    }

    // If Table
    if (isComponentVisible({ claim: adhocAmendmentGeneralTable })) {
      const adhocAmendmentTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: adhocAmendmentGeneralTable.personalizations,
      });
      const adhocAmendmentPagination = getDefaultPage({
        page: adhocAmendmentTablePersonalizations?.pageNumber,
      });
      const pageSize = getDefaultPerPage({
        perPage: adhocAmendmentTablePersonalizations?.pageSize,
      });
      const SortOrder = adhocAmendmentTablePersonalizations?.sortOrder ?? null;
      const SortColumn =
        adhocAmendmentTablePersonalizations?.sortColumn ?? null;
      this.setState(
        {
          adhocAmendmentPerPage:
            adhocAmendmentPersonalizations?.adhocAmendmentPaginationType ===
            PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize }),
          adhocAmendmentPagination,
          adhocAmendmentSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!adhocAmendmentPersonalizations.gridView) {
            if (
              adhocAmendmentPersonalizations?.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGeneralAmendment({
                pageLoad: true,
                type: "adhocAmendment",
                notUpdate: true,
              });
            } else {
              this.listGeneralAmendment({
                options: {},
                type: "adhocAmendment",
                notUpdate: true,
                pageLoad,
              });
            }
          }
        }
      );
    }

    // If Grid
    if (isComponentVisible({ claim: adhocAmendmentGeneralGrid })) {
      const adhocAmendmentGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: adhocAmendmentGeneralGrid.personalizations,
      });
      const pageNumber = getDefaultPage({
        page: adhocAmendmentGridPersonalizations?.pageNumber,
      });
      const row = adhocAmendmentGridPersonalizations?.row
        ? Number(adhocAmendmentGridPersonalizations.row)
        : 5;
      const col = adhocAmendmentGridPersonalizations?.col
        ? Number(adhocAmendmentGridPersonalizations.col)
        : 4;
      const SortOrder = adhocAmendmentGridPersonalizations?.sortOrder ?? null;
      const SortColumn = adhocAmendmentGridPersonalizations?.sortColumn ?? null;
      this.setState(
        {
          adhocAmendmentGrid: {
            page: getDefaultPage({ page: pageNumber }),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          adhocAmendmentSelectedGridSort: _.find(
            this.state.adhocAmendmentGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            adhocAmendmentPersonalizations?.gridView &&
            adhocAmendmentPersonalizations?.gridView !== SINGLE_LINE_GRID_VALUE
          ) {
            this.setState({ adhocAmendmentGridView: true });
            if (
              adhocAmendmentPersonalizations.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridGeneralAmendment({
                type: "adhocAmendment",
                notUpdate: true,
                pageLoad,
              });
            } else {
              this.generalAmendmentFetchMoreData({
                isPaginated: true,
                type: "adhocAmendment",
                notUpdate: true,
                pageLoad,
              });
            }
          }
        }
      );
    }
  };

  loadReoccurringAmendment = ({ generalAmendmentMultiTabContainer }) => {
    const reoccurringAmendmentListContainer = findAuthorizationClaims({
      claims: mustBeArray(generalAmendmentMultiTabContainer?.components),
      name: "generalAmendmentReoccurringAmendmentListContainer",
    });
    const reoccurringAmendmentGeneralTable = findAuthorizationClaims({
      claims: reoccurringAmendmentListContainer?.components,
      name: "generalAmendmentReoccurringAmendmentGeneralTable",
    });
    const reoccurringAmendmentGeneralGrid = findAuthorizationClaims({
      claims: reoccurringAmendmentListContainer?.components,
      name: "generalAmendmentReoccurringAmendmentGeneralGrid",
    });
    const reoccurringAmendmentPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: reoccurringAmendmentListContainer?.personalizations,
    });

    this.setState({
      reoccurringAmendmentPaginationType:
        reoccurringAmendmentPersonalizations?.paginationType,
      reoccurringAmendmentSortingType:
        reoccurringAmendmentPersonalizations?.sortingType,
      reoccurringAmendmentIsPinned:
        reoccurringAmendmentPersonalizations?.isPinned,
      reoccurringAmendmentGridable: reoccurringAmendmentListContainer?.gridable,
    });

    if (
      reoccurringAmendmentPersonalizations?.gridView === SINGLE_LINE_GRID_VALUE
    ) {
      this.setState({ reoccurringAmendmentSingleLineGridView: true });
      delay(300).then(() => {
        this.listSingleLineGridGeneralAmendment({
          options: {},
          type: "reoccurringAmendment",
          notUpdate: true,
        });
      });
    }

    // If Table
    if (isComponentVisible({ claim: reoccurringAmendmentGeneralTable })) {
      const reoccurringAmendmentTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: reoccurringAmendmentGeneralTable.personalizations,
      });
      const reoccurringAmendmentPagination = getDefaultPage({
        page: reoccurringAmendmentTablePersonalizations?.pageNumber,
      });
      const pageSize = getDefaultPerPage({
        perPage: reoccurringAmendmentTablePersonalizations?.pageSize,
      });
      const SortOrder =
        reoccurringAmendmentTablePersonalizations?.sortOrder ?? null;
      const SortColumn =
        reoccurringAmendmentTablePersonalizations?.sortColumn ?? null;
      this.setState(
        {
          reoccurringAmendmentPerPage:
            reoccurringAmendmentPersonalizations &&
            reoccurringAmendmentPersonalizations.reoccurringAmendmentPaginationType ===
              PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize }),
          reoccurringAmendmentPagination,
          reoccurringAmendmentSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!reoccurringAmendmentPersonalizations.gridView) {
            if (
              reoccurringAmendmentPersonalizations?.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGeneralAmendment({
                pageLoad: true,
                type: "reoccurringAmendment",
                notUpdate: true,
              });
            } else {
              this.listGeneralAmendment({
                options: {},
                type: "reoccurringAmendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }

    // If Grid
    if (isComponentVisible({ claim: reoccurringAmendmentGeneralGrid })) {
      const reoccurringAmendmentGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: reoccurringAmendmentGeneralGrid.personalizations,
      });
      const pageNumber = getDefaultPage({
        page: reoccurringAmendmentGridPersonalizations?.pageNumber,
      });
      const row = reoccurringAmendmentGridPersonalizations?.row
        ? Number(reoccurringAmendmentGridPersonalizations.row)
        : 5;
      const col = reoccurringAmendmentGridPersonalizations?.col
        ? Number(reoccurringAmendmentGridPersonalizations.col)
        : 4;
      const SortOrder =
        reoccurringAmendmentGridPersonalizations?.sortOrder ?? null;
      const SortColumn =
        reoccurringAmendmentGridPersonalizations?.sortColumn ?? null;
      this.setState(
        {
          reoccurringAmendmentGrid: {
            page: getDefaultPage({ page: pageNumber }),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          reoccurringAmendmentSelectedGridSort: _.find(
            this.state.reoccurringAmendmentGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            reoccurringAmendmentPersonalizations?.gridView &&
            reoccurringAmendmentPersonalizations?.gridView !==
              SINGLE_LINE_GRID_VALUE
          ) {
            this.setState({ reoccurringAmendmentGridView: true });
            if (
              reoccurringAmendmentPersonalizations.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridGeneralAmendment({
                pageLoad: true,
                type: "reoccurringAmendment",
                notUpdate: true,
              });
            } else {
              this.generalAmendmentFetchMoreData({
                isPaginated: true,
                type: "reoccurringAmendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  getQueryParams = ({ type }) => {
    let queryParams = {};
    switch (type) {
      case PAYRUN_AMENDMENT_TYPE:
        queryParams = {
          amendmentType: PAYRUN_AMENDMENT_TYPE_ID,
          showComplete: false,
        };
        break;
      case PERMANENT_AMENDMENT_TYPE:
        queryParams = {
          amendmentType: PERMANENT_AMENDMENT_TYPE_ID,
          showComplete: false,
        };
        break;
      case REOCCURRING_AMENDMENT_TYPE:
        queryParams = {
          amendmentType: REOCCURRING_AMENDMENT_TYPE_ID,
          showArchived: this.state.reoccurringAmendmentShowArchived,
          showComplete: false,
        };
        break;
      case "generalAmendment":
        queryParams = { showComplete: false };
        break;
      default:
        break;
    }
    return queryParams;
  };

  listSingleLineGridGeneralAmendment = ({ options, type, pageLoad }) => {
    let queryParams = this.getQueryParams({ type });
    let newOptions = {
      page: options?.page ? options.page : 1,
      per_page: MAX_AMENDMENT_PER_PAGE,
    };
    if (this.getFilters()) {
      newOptions.q = this.getFilters();
    }
    newOptions.q = newOptions.q
      ? { ...newOptions.q, ...queryParams }
      : queryParams;
    this.getGeneralAmendmentData({
      options: newOptions,
      cancelToken: this.signal.token,
      targetEndpoint: this.props.targetEndpoint,
      updatePersonalization: false,
      type,
      isPaginated: true,
      pageLoad,
    });
  };

  getOptions = ({ options }) => {
    if (options) {
      return {
        ...options,
        filter: options.filter
          ? { ...options.filter, showComplete: false }
          : { showComplete: false },
      };
    }
    return { q: { showComplete: false } };
  };

  getGeneralAmendmentData = ({
    options,
    cancelToken,
    pageLoad,
    targetEndpoint,
    updatePersonalization,
    type,
    updateClaimName,
    isPaginated,
  }) => {
    this.setState({ [`${type}ListLoading`]: true });
    return this.props
      .generalAmendmentHandler({
        options: this.getOptions({ options }),
        cancelToken,
        targetEndpoint,
        type,
        action: "list",
      })
      .then((resp) => {
        this.setState({ [`${type}ListLoading`]: false });

        if (
          Number(this.state[`${type}Pagination`]) !== 1 &&
          _.isEmpty(mustBeArray(resp.data)) &&
          !this.props.targetEndpoint
        ) {
          this.setState({ [`${type}Pagination`]: 1 }, () => {
            this.getGeneralAmendmentData({
              options: { ...options, page: 1 },
              cancelToken,
              targetEndpoint,
              updatePersonalization: true,
              updateClaimName,
              isPaginated,
            });
          });
        } else {
          this.setState(
            {
              [`${type}List`]: getConditionalResponse({
                condition: pageLoad || isPaginated,

                resp1: mustBeArray(resp.data),

                resp2: [
                  ...mustBeArray(this.state[`${type}List`]),
                  ...mustBeArray(resp.data),
                ],
              }),
              [`${type}Total`]: resp.total,
            },
            () => {
              if (!isPaginated) {
                this.setState({
                  [`${type}HasMoreData`]: getConditionalResponse({
                    condition:
                      mustBeArray(this.state[`${type}List`]).length <
                      resp.total,
                    resp1: true,
                    resp2: false,
                  }),
                });
              }
            }
          );
        }
        this.handleUpdatePersonalization({
          updateClaimName,
          updatePersonalization,
        });

        return resp;
      });
  };

  getGeneralAmendmentGridData = ({
    options,
    type,
    cancelToken,
    targetEndpoint,
    updatePersonalization,
    updateClaimName,
    isPaginated,
    pageLoad,
  }) => {
    return this.props
      .generalAmendmentHandler({
        options: this.getOptions({ options }),
        cancelToken,
        targetEndpoint,
        action: "list",
        type,
      })
      .then((response) => {
        if (response.status) {
          if (
            Number(options?.page) !== 1 &&
            _.isEmpty(mustBeArray(response.data)) &&
            !this.props.targetEndpoint
          ) {
            this.setState(
              { [`${type}Grid`]: { ...this.state[`${type}Grid`], page: 1 } },
              () => {
                this.getGeneralAmendmentGridData({
                  options: { ...options, page: 1 },
                  cancelToken,
                  targetEndpoint,
                  updatePersonalization: true,
                  updateClaimName,
                  isPaginated,
                });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState[`${type}List`] = data;
                } else {
                  prevState[`${type}List`] =
                    prevState[`${type}List`].concat(data);
                  prevState[`${type}HasMoreData`] = listHasMoreData({
                    data,
                    currentList: prevState[`${type}List`],
                    currentTotal: prevState[`${type}Total`],
                  });
                  prevState[`${type}Grid`] = listHasMoreData({
                    data,
                    currentList: prevState[`${type}List`],
                    currentTotal: prevState[`${type}Total`],
                  })
                    ? {
                        ...prevState[`${type}Grid`],
                        page: prevState[`${type}Grid`].page + 1,
                      }
                    : { ...prevState[`${type}Grid`] };
                }

                prevState[`${type}Total`] = response.total;
                prevState[`${type}ListLoading`] = false;
                return prevState;
              },
              () => {
                this.handleUpdatePersonalization({
                  updateClaimName,
                  updatePersonalization,
                });
              }
            );
          }
        }
      });
  };

  handleUpdatePersonalization = ({
    updatePersonalization,
    updateClaimName,
  }) => {
    if (updatePersonalization) {
      this.updatePersonalization(updateClaimName);
    }
  };

  listGeneralAmendment = ({
    options,
    type,
    notUpdate,
    isPaginated = true,
    pageLoad,
  }) => {
    if (this.props.dashboardView) {
      return this.getGeneralAmendmentData({
        options: {},

        cancelToken: this.signal.token,

        targetEndpoint: this.props.targetEndpoint,
        updatePersonalization: !notUpdate,
        type,
        isPaginated,
      });
    }
    this.setState(
      (prevState) => {
        if (options?.page) {
          prevState[`${type}Pagination`] = options.page;
          prevState[`${type}SortInfo`] = {
            field: options.field,
            order: options.order,
          };
        }
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let updateClaimName = "";

        let queryParams = this.getQueryParams({ type });
        let newOptions = {
          page: !_.isNaN(Number(this.state[`${type}Pagination`]))
            ? Number(this.state[`${type}Pagination`])
            : 1,

          per_page: !_.isNaN(Number(this.state[`${type}PerPage`]))
            ? Number(this.state[`${type}PerPage`])
            : 10,
          ...getCurrentTableSortInfo({
            sortInfo: this.state[`${type}SortInfo`],
            sortingType: this.state[`${type}SortingType`],
          }),
        };

        if (this.getFilters()) {
          newOptions.q = this.getFilters();
        }
        newOptions.q = newOptions.q
          ? { ...newOptions.q, ...queryParams }
          : queryParams;

        this.getGeneralAmendmentData({
          options: newOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
          action: "list",
          isPaginated,
          updatePersonalization: !notUpdate,
          updateClaimName,
          type,
          pageLoad,
        });
      }
    );
  };

  generalAmendmentFetchMoreData = ({
    isPaginated = false,
    type,
    notUpdate,
    pageLoad,
  }) => {
    this.setState({ [`${type}ListLoading`]: true });

    let newOptions = this.getRequestOptions({ type, gridView: true });

    this.getGeneralAmendmentGridData({
      options: newOptions,

      cancelToken: this.signal.token,

      targetEndpoint: this.props.targetEndpoint,
      updatePersonalization: !notUpdate,

      updateClaimName: this.getClaimName({ type, gridView: true }),
      type,
      isPaginated,
      pageLoad,
    });
  };

  listScrollingGridGeneralAmendment = ({ pageLoad, type, notUpdate }) => {
    if (!pageLoad && !this.state[`${type}HasMoreData`]) return false;

    this.setState(
      {
        [`${type}ListLoading`]: true,
        [`${type}Grid`]: {
          ...this.state[`${type}Grid`],
          page: !pageLoad
            ? Number(
                this.state[`${type}Grid`] && this.state[`${type}Grid`].page
              ) + 1
            : Number(
                this.state[`${type}Grid`] && this.state[`${type}Grid`].page
              ),
        },
      },
      () => {
        let updateClaimName = this.getClaimName({ type, gridView: true });

        let newOptions = this.getRequestOptions({
          pageLoad,
          type,
          gridView: true,
        });

        this.getGeneralAmendmentGridData({
          options: newOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
          updatePersonalization: !notUpdate,
          updateClaimName,
          type,
          isPaginated: false,
        });
      }
    );
  };

  listScrollingGeneralAmendment = ({
    options = {},
    pageLoad = false,
    type,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state[`${type}HasMoreData`]) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState[`${type}sortInfo`] = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState[`${type}Pagination`] =
            prevState[`${type}Total`] ===
            mustBeArray(prevState[`${type}List`]).length
              ? prevState[`${type}Pagination`]
              : Number(prevState[`${type}Pagination`]) + 1;
        }
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let updateClaimName = this.getClaimName({ type, gridView: false });

        let tempOptions = this.getRequestOptions({
          type,
          gridView: false,
          pageLoad,
        });

        this.getGeneralAmendmentData({
          options: tempOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
          isPaginated: false,
          updatePersonalization: !notUpdate,
          updateClaimName,
          type,
        });
      }
    );
  };

  onTabChange = (key) => {
    const { pageClaims } = this.props;

    this.props.updateHeader({
      header: {
        title: "Amendments",
        module: "",
        enableBack: false,
        entity: "generalAmendment",
        action: "",
      },
    });

    this.setState({ activeKey: key }, () => {
      this.updatePersonalization("generalAmendmentMultiTabContainer");
    });
  };

  listGeneralAmendmentGrid = ({ page, type, pageLoad }) => {
    this.setState(
      (prevState) => {
        prevState[`${type}Grid`].page = page;
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let updateClaimName = this.getClaimName({
          type,
          gridView: this.state[`${type}GridView`],
        });

        let tempOptions = this.getRequestOptions({ type, gridView: true });

        this.getGeneralAmendmentGridData({
          options: tempOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
          updatePersonalization: true,
          updateClaimName,
          type,
          isPaginated: true,
          pageLoad,
        });
      }
    );
  };

  getRequestOptions = ({ type, gridView, pageLoad }) => {
    let queryParams = this.getQueryParams({ type });

    let tempFilters = this.getFilters();
    let tempOptions = {};

    if (this.state[`${type}SingleLineGridView`]) {
      return { per_page: 500, q: { ...queryParams } };
    }
    if (gridView) {
      let per_page = getCurrentGridPageSize({
        grid: this.state[`${type}Grid`],
        paginationType: this.state[`${type}PaginationType`],
      });
      if (pageLoad) {
        tempOptions = {
          page: pageLoad ? 1 : page,
          per_page: pageLoad ? Number(page) * per_page : per_page,
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state[`${type}Grid`],
            sortingType: this.state[`${type}SortingType`],
          }),
        };
      } else {
        tempOptions = {
          page: this.state[`${type}Grid`]?.page,
          per_page,
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state[`${type}Grid`],
            sortingType: this.state[`${type}SortingType`],
          }),
        };
      }
    } else {
      tempOptions = {
        ...getCurrentTableSortInfo({
          sortInfo: this.state[`${type}SortInfo`],
          sortingType: this.state[`${type}SortingType`],
        }),
        q: { ...tempFilters },
      };
      if (pageLoad) {
        tempOptions = {
          ...tempOptions,
          page: 1,
          per_page:
            Number(this.state[`${type}Pagination`]) *
            Number(this.state[`${type}PerPage`]),
        };
      } else {
        tempOptions = {
          ...tempOptions,
          page: this.state[`${type}Pagination`],
          per_page: this.state[`${type}PerPage`],
        };
      }
    }
    tempOptions.q = tempOptions.q
      ? { ...tempOptions.q, ...queryParams }
      : queryParams;
    return tempOptions;
  };

  getClaimName = ({ type, gridView }) => {
    if (this.state[`${type}SingleLineGridView`]) return "";
    switch (type) {
      case "payrunAmendment":
        if (gridView) return GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE;
        return GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID;
      case "adhocAmendment":
        if (gridView) return "generalAmendmentAdhocAmendmentGeneralTable";
        return "generalAmendmentAdhocAmendmentGeneralGrid";
      case "reoccurringAmendment":
        if (gridView) return "generalAmendmentReoccurringAmendmentGeneralTable";
        return "generalAmendmentReoccurringAmendmentGeneralGrid";
      case "generalAmendment":
        if (gridView) return "generalAmendmentGeneralTable";
        return "generalAmendmentGeneralGrid";
      default:
        return "";
    }
  };

  getContainerClaimName = ({ type }) => {
    if (this.state[`${type}SingleLineGridView`]) return "";
    switch (type) {
      case "payrunAmendment":
        return GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER;
      case "adhocAmendment":
        return "generalAmendmentAdhocAmendmentListContainer";
      case "reoccurringAmendment":
        return "generalAmendmentReoccurringAmendmentListContainer";
      case "generalAmendment":
        return "generalAmendmentListContainer";
      default:
        return "";
    }
  };

  handleFilter = () => {
    this.updatePersonalization(GENERAL_AMENDMENT_FILTER_TEXTBOX, true);
    delay(300).then(() => {
      this.updatePersonalization("generalAmendmentTypeDropdownFilter", true);
    });

    this.setState(
      {
        payrunAmendmentPagination: 1,
        payrunAmendmentListLoading: true,

        payrunAmendmentGrid: {
          ...this.state.payrunAmendmentGrid,
          page: 1,
          sortInfo: {},
        },
        adhocAmendmentPagination: 1,
        adhocAmendmentListLoading: true,

        adhocAmendmentGrid: {
          ...this.state.adhocAmendmentGrid,
          page: 1,
          sortInfo: {},
        },
        reoccurringAmendmentPagination: 1,
        reoccurringAmendmentListLoading: true,

        reoccurringAmendmentGrid: {
          ...this.state.reoccurringAmendmentGrid,
          page: 1,
          sortInfo: {},
        },
      },
      () => {
        let timer = 0;

        _.forEach(
          this.state[
            [userRoles.SystemAdminArea].includes(getUserArea())
              ? "generalAmendmentType"
              : "amendmentTypes"
          ],
          (types) => {
            timer = timer + 300;
            return delay(timer).then(() => {
              this.fetchGeneralAmendmentTypesList({ ...types });
            });
          }
        );
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        payrunAmendmentSortInfo: {},
        payrunAmendmentSelectedGridSort: undefined,
        adhocAmendmentSortInfo: {},
        adhocAmendmentSelectedGridSort: undefined,
        reoccurringAmendmentSortInfo: {},
        reoccurringAmendmentSelectedGridSort: undefined,
        filters: { ...this.state.filters, name: "", type: 0 }, //Filters were not being cleared
      },
      () => {
        this.handleFilter();
      }
    );
  };

  fetchGeneralAmendmentTypesList = ({ type }) => {
    let tempFilters = this.getFilters();

    let options = {
      page: 1,
      q: {
        name: tempFilters.name,
        amendmentType: tempFilters.type,
        ...this.getQueryParams({ type }),
      },
    };

    let updateClaimName = this.getClaimName({
      type,
      gridView: this.state[`${type}GridView`],
    });
    let actionName;

    if (this.state[`${type}SingleLineGridView`]) {
      actionName = this.getGeneralAmendmentData;
      options = { ...options, per_page: 100 };
    } else if (this.state[`${type}GridView`]) {
      options = {
        ...options,
        per_page: getCurrentGridPageSize({
          grid: this.state[`${type}Grid`],
          paginationType: this.state[`${type}PaginationType`],
        }),

        ...getCurrentGridSortInfo({
          grid: this.state[`${type}Grid`],
          sortingType: this.state[`${type}SortingType`],
        }),
      };

      actionName = this.getGeneralAmendmentGridData;
    } else {
      options = {
        ...options,
        per_page: this.state[`${type}PerPage`],

        ...getCurrentTableSortInfo({
          sortInfo: this.state[`${type}SortInfo`],
          sortingType: this.state[`${type}SortingType`],
        }),
      };

      actionName = this.getGeneralAmendmentData;
    }
    actionName({
      options,

      cancelToken: this.signal.token,

      targetEndpoint: this.props.targetEndpoint,
      updatePersonalization: true,
      updateClaimName,
      type,
      isPaginated: true,
    });
  };

  getFilters = () => {
    let tempFilters = { ...this.state.filters };

    if (!tempFilters.type || Number(tempFilters.type) === 0) {
      delete tempFilters.type;
    }

    return { ...tempFilters, name: trimWhiteSpaces(tempFilters.name) };
  };

  handleGeneralAmendmentGridChange = ({ gridView, type }) => {
    if (this.state[`${type}GridView`] === gridView) {
      return false;
    }

    const componentName = this.getContainerClaimName({ type });

    this.setState(
      {
        [`${type}GridView`]: gridView,
        [`${type}List`]: [],
        [`${type}ListLoading`]: true,
      },
      () => {
        this.updatePersonalization(componentName, true);
        if (!gridView) {
          this.listGeneralAmendment({
            options: {},
            pageLoad: true,
            type,
            notUpdate: false,

            isPaginated:
              this.state[`${type}PaginationType`] === PAGINATION_SCROLLING,
          });
        } else {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGridGeneralAmendment({
              pageLoad: true,
              isPaginated: false,
              options: {},
              type,
            });
          } else {
            this.generalAmendmentFetchMoreData({ isPaginated: true, type });
          }
        }
      }
    );
  };

  generalAmendmentUpdatePageSize = ({ value, type }) => {
    if (this.state[`${type}GridView`]) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState[`${type}Grid`].row = Number(row);
          prevState[`${type}Grid`].col = Number(col);
          prevState[`${type}Grid`].page = 1;
          return prevState;
        },
        () => {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGridGeneralAmendment({
              pageLoad: true,
              isPaginated: false,
              options: {},
              type,
            });
          } else {
            this.generalAmendmentFetchMoreData({ isPaginated: true, type });
          }
        }
      );
    } else {
      this.setState(
        { [`${type}PerPage`]: Number(value), [`${type}Pagination`]: 1 },
        () => {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGeneralAmendment({
              pageLoad: true,
              options: {},
              type,
            });
          } else {
            this.listGeneralAmendment({ options: {}, type });
          }
        }
      );
    }
  };

  updateGeneralAmendmentGridField = ({ field, value, type }) => {
    this.setState(
      (prevState) => {
        if (prevState[`${type}Grid`].sortInfo[field] === value) {
          prevState[`${type}Grid`].sortInfo[field] = "";
        } else {
          prevState[`${type}Grid`].sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.generalAmendmentFetchMoreData({ isPaginated: true, type });
      }
    );
  };

  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;

    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member?.details?.authorizationDOM
      );
      let updatedDOM = [];
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const generalAmendmentFilterContainer = findAuthorizationClaims({
        claims: conditionalParameter({ data: pageClaims, field: "components" }),
        name: GENERAL_AMENDMENT_FILTER_CONTAINER,
      });
      const generalAmendmentMultiTabContainer = findAuthorizationClaims({
        claims: conditionalParameter({ data: pageClaims, field: "components" }),
        name: "generalAmendmentMultiTabContainer",
      });
      const generalAmendmentListContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "generalAmendmentListContainer",
      });
      const payrunAmendmentListContainer = findAuthorizationClaims({
        claims: generalAmendmentMultiTabContainer?.components,
        name: GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER,
      });
      const adhocAmendmentListContainer = findAuthorizationClaims({
        claims: generalAmendmentMultiTabContainer?.components,
        name: "generalAmendmentAdhocAmendmentListContainer",
      });
      const reoccurringAmendmentListContainer = findAuthorizationClaims({
        claims: generalAmendmentMultiTabContainer?.components,
        name: "generalAmendmentReoccurringAmendmentListContainer",
      });

      const payrunAmendmentGeneralTable = _.find(
        mustBeArray(
          conditionalParameter({
            data: this.props.pageClaims,
            field: "components",
          })
        ),
        (o) => o.name === GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE
      );

      const payrunAmendmentGeneralGrid = _.find(
        mustBeArray(
          conditionalParameter({
            data: this.props.pageClaims,
            field: "components",
          })
        ),
        (o) => o.name === GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID
      );

      const adhocAmendmentGeneralTable = _.find(
        mustBeArray(
          conditionalParameter({
            data: this.props.pageClaims,
            field: "components",
          })
        ),
        (o) => o.name === "generalAmendmentAdhocAmendmentGeneralTable"
      );

      const adhocAmendmentGeneralGrid = _.find(
        mustBeArray(
          conditionalParameter({
            data: this.props.pageClaims,
            field: "components",
          })
        ),
        (o) => o.name === "generalAmendmentAdhocAmendmentGeneralGrid"
      );

      const reoccurringAmendmentGeneralTable = _.find(
        mustBeArray(
          conditionalParameter({
            data: this.props.pageClaims,
            field: "components",
          })
        ),
        (o) => o.name === "generalAmendmentReoccurringAmendmentGeneralTable"
      );

      const reoccurringAmendmentGeneralGrid = _.find(
        mustBeArray(
          conditionalParameter({
            data: this.props.pageClaims,
            field: "components",
          })
        ),
        (o) => o.name === "generalAmendmentReoccurringAmendmentGeneralGrid"
      );
      switch (field) {
        case GENERAL_AMENDMENT_FILTER_TEXTBOX:
          if (generalAmendmentFilterContainer?.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.props.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: this.props.pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentTypeDropdownFilter":
          if (generalAmendmentFilterContainer?.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.props.filters?.type;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: this.props.pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentMultiTabContainer":
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: conditionalParameter({
              data: pageClaims,
              field: "components",
            }),
            name: field,
          });
          break;

        // General amendment
        case "generalAmendmentGeneralTable":
          if (generalAmendmentListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.generalAmendmentPagination}`,

              sortOrder: this.state.generalAmendmentSortInfo?.order,

              sortColumn: this.state.generalAmendmentSortInfo?.field,

              pageSize: `${this.state.generalAmendmentPerPage}`,
              personalizationData:
                generalAmendmentGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentGeneralGrid":
          if (generalAmendmentListContainer?.personalizable === "true") {
            const { generalAmendmentGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${generalAmendmentGrid?.page}`,
              row: `${generalAmendmentGrid?.row}`,
              col: `${generalAmendmentGrid?.col}`,
              sortOrder: generalAmendmentGrid?.sortInfo?.order,
              sortColumn: generalAmendmentGrid?.sortInfo?.field,
              personalizationData:
                generalAmendmentGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentListContainer":
          if (
            generalAmendmentListContainer &&
            generalAmendmentListContainer.personalizable === "true"
          ) {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.generalAmendmentGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.generalAmendmentPaginationType,

              sortingType: this.state.generalAmendmentSortingType,

              isPinned: this.state.generalAmendmentIsPinned,
              personalizationData:
                generalAmendmentListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;

        // Payrun Amendment
        case GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE:
          if (payrunAmendmentListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.payrunAmendmentPagination}`,
              sortOrder: this.state.payrunAmendmentSortInfo?.order,
              sortColumn: this.state.payrunAmendmentSortInfo?.field,
              pageSize: `${this.state.payrunAmendmentPerPage}`,
              personalizationData:
                payrunAmendmentGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID:
          if (payrunAmendmentListContainer?.personalizable === "true") {
            const { payrunAmendmentGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${payrunAmendmentGrid?.page}`,
              row: `${payrunAmendmentGrid?.row}`,
              col: `${payrunAmendmentGrid?.col}`,
              sortOrder: payrunAmendmentGrid?.sortInfo?.order,
              sortColumn: payrunAmendmentGrid?.sortInfo?.field,
              personalizationData: payrunAmendmentGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER:
          if (payrunAmendmentListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.payrunAmendmentGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.payrunAmendmentPaginationType,
              sortingType: this.state.payrunAmendmentSortingType,
              isPinned: this.state.payrunAmendmentIsPinned,
              personalizationData:
                payrunAmendmentListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        //One-time amendment
        case "generalAmendmentAdhocAmendmentGeneralTable":
          if (adhocAmendmentListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.adhocAmendmentPagination}`,

              sortOrder: this.state.adhocAmendmentSortInfo?.order,

              sortColumn: this.state.adhocAmendmentSortInfo?.field,

              pageSize: `${this.state.adhocAmendmentPerPage}`,
              personalizationData: adhocAmendmentGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentAdhocAmendmentGeneralGrid":
          if (adhocAmendmentListContainer?.personalizable === "true") {
            const { adhocAmendmentGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${adhocAmendmentGrid?.page}`,
              row: `${adhocAmendmentGrid?.row}`,
              col: `${adhocAmendmentGrid?.col}`,
              sortOrder: adhocAmendmentGrid?.sortInfo.order,
              sortColumn: adhocAmendmentGrid?.sortInfo.field,
              personalizationData: adhocAmendmentGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentAdhocAmendmentListContainer":
          if (
            adhocAmendmentListContainer &&
            adhocAmendmentListContainer.personalizable === "true"
          ) {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.adhocAmendmentGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.adhocAmendmentPaginationType,

              sortingType: this.state.adhocAmendmentSortingType,

              isPinned: this.state.adhocAmendmentIsPinned,
              personalizationData: adhocAmendmentListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;

        // Reoccurring
        case "generalAmendmentReoccurringAmendmentGeneralTable":
          if (reoccurringAmendmentListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.reoccurringAmendmentPagination}`,

              sortOrder: this.state.reoccurringAmendmentSortInfo?.order,

              sortColumn: this.state.reoccurringAmendmentSortInfo?.field,

              pageSize: `${this.state.reoccurringAmendmentPerPage}`,
              personalizationData:
                reoccurringAmendmentGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentReoccurringAmendmentGeneralGrid":
          if (reoccurringAmendmentListContainer?.personalizable === "true") {
            const { reoccurringAmendmentGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${reoccurringAmendmentGrid?.page}`,
              row: `${reoccurringAmendmentGrid?.row}`,
              col: `${reoccurringAmendmentGrid?.col}`,
              sortOrder: reoccurringAmendmentGrid?.sortInfo?.order,
              sortColumn: reoccurringAmendmentGrid?.sortInfo?.field,
              personalizationData:
                reoccurringAmendmentGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "generalAmendmentReoccurringAmendmentListContainer":
          if (reoccurringAmendmentListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.reoccurringAmendmentGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.reoccurringAmendmentPaginationType,

              sortingType: this.state.reoccurringAmendmentSortingType,

              isPinned: this.state.reoccurringAmendmentIsPinned,
              personalizationData:
                reoccurringAmendmentListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update: _.isEmpty(payload) ? false : update,
        });
      });
    } catch (err) {
      return err;
    }
  };
  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === newClaim?.id
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  handleEdit = ({ data, type }) => {
    this.handleAmendmentActions({ data, type, action: "edit" });
  };

  handleChange = ({ field, value }) => {
    return this.setState({
      filters: {
        ...this.state.filters,
        [field]: value,
      },
    });
  };

  handleCloseAmendmentModal = () => {
    this.setState({ viewAmendmentModal: false }, () => {
      this.setState({ selectedAmendment: {} });
    });
  };
  handleAmendmentActions = ({ event = null, type, data, action, pageLoad }) => {
    if (event && action !== "view") {
      event.stopPropagation();
    }
    switch (action) {
      case "edit":
        this.props.updateHeader({
          header: {
            module: "Back to General Amendment",
            enableBack: true,
            entity: "generalAmendment",
            returnUrl: `/generalAmendments`,
          },
        });
        this.props.router.navigate(
          `${
            data?.amendmentTypeId === PAYRUN_AMENDMENT_TYPE_ID &&
            data?.payrunId !== DEFAULT_GUID
              ? `/payrun/${data?.payrunId}/amendment/${data?.amendmentId}`
              : `/payrun/amendment/${data?.amendmentId}`
          }`
        );
        break;
      case "delete":
        if (
          data?.amendmentTypeId === PAYRUN_AMENDMENT_TYPE_ID &&
          data?.payrunId !== DEFAULT_GUID
        ) {
          this.props
            .deleteAmendment({
              id: data?.amendmentId,
              action: "delete",
              payrunId: data?.payrunId,
              options: {},
            })
            .then((resp) => {
              if (resp.status) {
                message.success("Amendment deleted successfully");

                this.listSingleLineGridGeneralAmendment({ type, options: {} });
              } else {
                errorDisplay(resp?.data?.validationErrors, true);
              }
            });
        } else {
          this.props
            .generalAmendmentHandler({
              id: data?.amendmentId,
              cancelToken: this.signal.token,
              action: "delete",
            })
            .then((resp) => {
              if (resp.status) {
                message.success("Amendment deleted successfully");

                this.listSingleLineGridGeneralAmendment({ type, options: {} });
              } else {
                errorDisplay(resp?.data?.validationErrors, true);
              }
            });
        }
        break;
      case "view":
        this.setState({ selectedAmendment: data }, () => {
          this.setState({ viewAmendmentModal: true });
        });
        break;
      case "verifiedInKeypay":
        this.setState(
          {
            selectedAmendment: data,
          },
          () => {
            this.handlePayscheduleAction();
          }
        );
        break;
      case "requestCancel":
      case "applyCancel":
      case "applyEditedInKeypay":
        this.props
          .updateAmendment({
            id: data?.amendmentId,
            payrunId:
              data?.payrunId && data.payrunId !== DEFAULT_GUID
                ? data.payrunId
                : null,
            values: {
              amendmentStatusInPPSId: getReoccurringAmendmentKeypayActionId({
                action,
              }),
              amendmentSummary: data?.amendmentSummary,
              assignTo: data?.assignTo,
            },
          })
          .then((resp) => {
            if (resp.status) {
              message.success("Amendment updated successfully.");

              if (this.state.reoccurringAmendmentGridView) {
                this.listGeneralAmendmentGrid({
                  page: this.state.reoccurringAmendmentGrid?.page,
                  type,
                  pageLoad,
                });
              } else {
                this.listGeneralAmendment({
                  options: {},
                  type,
                  notUpdate: true,
                  isPaginated: true,
                  pageLoad: false,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        break;
      default:
        break;
    }
  };

  getComponentName = ({ type }) => {
    switch (type) {
      case "payrunAmendment":
        return "PayrunAmendment";
      case "adhocAmendment":
        return "AdhocAmendment";
      case "reoccurringAmendment":
        return "ReoccurringAmendment";
      default:
        return "";
    }
  };

  updateShowArchived = () => {
    this.setState(
      {
        reoccurringAmendmentShowArchived:
          !this.state.reoccurringAmendmentShowArchived,
      },
      () => {
        delay(100).then(() => {
          this.fetchGeneralAmendmentTypesList({
            type: REOCCURRING_AMENDMENT_TYPE,
          });
        });
      }
    );
  };

  handlePayrunView = () => {
    const { selectedAmendment } = this.state;
    if (
      [userRoles.SystemAdminArea, userRoles.PSPArea].includes(getUserArea())
    ) {
      this.props.router.navigate(`/payrun/${selectedAmendment?.payrunId}`);
    } else {
      this.props
        .getPayrun({
          id: selectedAmendment?.payrunId,
          options: {},
          cancelToken: this.signal.token,
        })
        .then((resp) => {
          if (resp.status && resp.data) {
            return PayRunStatus.getStatus({
              id: resp.data.payrunStatusID,
            }) === "draft"
              ? ""
              : this.props.router.navigate(
                  `/payrun/${selectedAmendment?.payrunId}`
                );
          }
        });
    }
  };

  handlePayscheduleView = () => {
    this.props
      .getPayschedule({
        id: this.state.selectedAmendment?.payscheduleID,
      })
      .then((resp) => {
        if (resp?.status && resp.data) {
          const { id, companyId, businessUnitID } = resp.data;
          this.props.router.navigate(
            getPayScheduleDetailRoute({
              id,
              companyId,
              businessUnitId: businessUnitID,
            })
          );
        }
      });
  };

  getPayrunId = (selectedAmendment) => {
    if (
      selectedAmendment?.payrunId &&
      selectedAmendment?.payrunId !== DEFAULT_GUID
    )
      return selectedAmendment.payrunId;
    return null;
  };

  getCurrentPage = (type) => {
    if (type) {
      return (this.state[`${type}Grid`] && this.state[`${type}Grid`].page) || 1;
    }

    return this.state.reoccurringAmendmentGrid?.page || 1;
  };
  handlePayscheduleAction = () => {
    let type = "";
    if ([userRoles.SystemAdminArea].includes(getUserArea())) {
      type = "generalAmendment";
    }

    this.setState({ payscheduleSelectLoading: true });

    const { selectedAmendment } = this.state;

    this.props
      .updateAmendment({
        id: conditionalParameter({
          data: selectedAmendment,
          field: "amendmentId",
        }),

        payrunId: this.getPayrunId(selectedAmendment),
        values: {
          amendmentStatusInPPSId: getReoccurringAmendmentKeypayActionId({
            action: "verifiedInKeypay",
          }),
          amendmentSummary: conditionalParameter({
            data: selectedAmendment,
            field: "amendmentSummary",
          }),
          assignTo: conditionalParameter({
            data: selectedAmendment,
            field: "assignTo",
          }),
        },
      })
      .then((resp) => {
        this.setState({ payscheduleSelectLoading: false });

        if (resp.status) {
          message.success("Amendment updated successfully.");

          if (this.state.reoccurringAmendmentGridView) {
            this.listGeneralAmendmentGrid({
              page: this.getCurrentPage(type),
              type: getOptionalParameter({
                value1: type,
                value2: REOCCURRING_AMENDMENT_TYPE,
              }),
              pageLoad,
            });
          } else {
            this.listGeneralAmendment({
              options: {},
              type: getOptionalParameter({
                value1: type,
                value2: REOCCURRING_AMENDMENT_TYPE,
              }),
              notUpdate: true,
              isPaginated: true,
              pageLoad: false,
            });
          }
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  getFilterLabel = () => {
    let currentFilters = this.getFilters();
    let filters = [];
    if (currentFilters.type) {
      let selectedAmendmentType = _.find(
        AMENDMENT_TYPES_OPTIONS,
        (o) => o.id === currentFilters.type
      );
      filters.push(selectedAmendmentType?.name);
    }
    if (currentFilters.name) {
      filters.push(currentFilters.name);
    }
    let filterLabel = filteredByLabel({
      filters,
    });

    return filterLabel;
  };

  render() {
    const { pageClaims, dashboardView, type, member } = this.props;

    const {
      activeKey,
      searchLoading,
      payrunAmendmentListLoading,
      payrunAmendmentList,
      payrunAmendmentPagination,
      payrunAmendmentSortInfo,
      payrunAmendmentPerPage,
      payrunAmendmentTotal,
      payrunAmendmentGrid,
      payrunAmendmentGridable,
      payrunAmendmentGridView,
      payrunAmendmentFieldOptions,
      payrunAmendmentSortingType,
      payrunAmendmentPaginationType,
      payrunAmendmentIsPinned,
      payrunAmendmentHasMoreData,
      adhocAmendmentListLoading,
      adhocAmendmentList,
      adhocAmendmentPagination,
      adhocAmendmentSortInfo,
      adhocAmendmentPerPage,
      adhocAmendmentTotal,
      adhocAmendmentGrid,
      adhocAmendmentGridable,
      adhocAmendmentGridView,
      adhocAmendmentFieldOptions,
      adhocAmendmentSortingType,
      adhocAmendmentPaginationType,
      adhocAmendmentIsPinned,
      adhocAmendmentHasMoreData,
      reoccurringAmendmentListLoading,
      reoccurringAmendmentList,
      reoccurringAmendmentPagination,
      reoccurringAmendmentSortInfo,
      reoccurringAmendmentPerPage,
      reoccurringAmendmentTotal,
      reoccurringAmendmentGrid,
      reoccurringAmendmentGridable,
      reoccurringAmendmentGridView,
      reoccurringAmendmentFieldOptions,
      reoccurringAmendmentSortingType,
      reoccurringAmendmentPaginationType,
      reoccurringAmendmentIsPinned,
      reoccurringAmendmentHasMoreData,
      generalAmendmentListLoading,
      generalAmendmentList,
      generalAmendmentPagination,
      generalAmendmentSortInfo,
      generalAmendmentPerPage,
      generalAmendmentTotal,
      generalAmendmentGrid,
      generalAmendmentGridable,
      generalAmendmentGridView,
      generalAmendmentFieldOptions,
      generalAmendmentSortingType,
      generalAmendmentPaginationType,
      generalAmendmentIsPinned,
      generalAmendmentHasMoreData,
    } = this.state;
    const generalAmendmentMultiTabContainer = _.find(
      mustBeArray(
        conditionalParameter({ data: pageClaims, field: "components" })
      ),
      (o) => o.name === "generalAmendmentMultiTabContainer"
    );

    // General Amendment

    const generalAmendmentCardGrid = getCurrentCardGrid({
      grid: this.state.generalAmendmentGrid,
    });

    // Payrun Amendment
    const payrunAmendmentTabContainer = _.find(
      mustBeArray(generalAmendmentMultiTabContainer?.components),
      (o) => o.name === GENERAL_AMENDMENT_PAYRUN_AMENDMENT_TAB_CONTAINER
    );
    const payrunAmendmentChildTabContainer = _.find(
      mustBeArray(payrunAmendmentTabContainer?.components),
      (o) => o.name === GENERAL_AMENDMENT_PAYRUN_AMENDMENT_CHILD_TAB_CONTAINER
    );

    const payrunAmendmentCardGrid = getCurrentCardGrid({
      grid: this.state.payrunAmendmentGrid,
    });

    // One-time Amendment
    const adhocAmendmentTabContainer = _.find(
      mustBeArray(generalAmendmentMultiTabContainer?.components),
      (o) => o.name === GENERAL_AMENDMENT_ADHOC_AMENDMENT_TAB_CONTAINER
    );
    const adhocAmendmentChildTabContainer = _.find(
      mustBeArray(adhocAmendmentTabContainer?.components),
      (o) => o.name === "generalAmendmentAdhocAmendmentChildTabContainer"
    );

    const adhocAmendmentCardGrid = getCurrentCardGrid({
      grid: this.state.adhocAmendmentGrid,
    });

    // Reoccurring Amendment
    const reoccurringAmendmentTabContainer = _.find(
      mustBeArray(generalAmendmentMultiTabContainer?.components),
      (o) => o.name === "generalAmendmentReoccurringAmendmentTabContainer"
    );
    const reoccurringAmendmentChildTabContainer = _.find(
      mustBeArray(reoccurringAmendmentTabContainer?.components),
      (o) => o.name === "generalAmendmentReoccurringAmendmentChildTabContainer"
    );
    const reoccurringAmendmentListContainer = _.find(
      mustBeArray(reoccurringAmendmentChildTabContainer?.components),
      (o) => o.name === "generalAmendmentReoccurringAmendmentListContainer"
    );

    const reoccurringAmendmentCardGrid = getCurrentCardGrid({
      grid: this.state.reoccurringAmendmentGrid,
    });

    const filterLabel = this.getFilterLabel();
    const baseUrl = APIHandler.constructEndpoint({
      endpoint: "GENERAL_AMENDMENTS_URL",
    });
    const payrunAmendmentTargetEndpoint = requestUrlBuilder(baseUrl, {
      ...this.getRequestOptions({
        type: PAYRUN_AMENDMENT_TYPE,
        gridView: this.state[`${PAYRUN_AMENDMENT_TYPE}GridView`],
        pageLoad: false,
      }),
    });
    const reoccurringAmendmentTargetEndpoint = requestUrlBuilder(baseUrl, {
      ...this.getRequestOptions({
        type: REOCCURRING_AMENDMENT_TYPE,
        gridView: this.state[`${REOCCURRING_AMENDMENT_TYPE}GridView`],
        pageLoad: false,
      }),
    });
    const adhocAmendmentTargetEndpoint = requestUrlBuilder(baseUrl, {
      ...this.getRequestOptions({
        type: PERMANENT_AMENDMENT_TYPE,
        gridView: this.state[`${PERMANENT_AMENDMENT_TYPE}GridView`],
        pageLoad: false,
      }),
    });
    const generalAmendmentTargetEndpoint = requestUrlBuilder(baseUrl, {
      ...this.getRequestOptions({
        type: "generalAmendment",
        gridView: this.state.generalAmendmentGridView,
        pageLoad: false,
      }),
    });
    const generalAmendmentMapper = {
      [PAYRUN_AMENDMENT_TYPE]: {
        claims: payrunAmendmentChildTabContainer,
        isPinned: payrunAmendmentIsPinned,
        gridView: payrunAmendmentGridView,
        gridable: payrunAmendmentGridable,
        grid: payrunAmendmentGrid,
        per_page: payrunAmendmentPerPage,
        generalTablePaginatedComponent: AmendmentDetailList,
        fieldOptions: payrunAmendmentFieldOptions,
        sortingType: payrunAmendmentSortingType,
        paginationType: payrunAmendmentPaginationType,
        list: payrunAmendmentList,
        listLoading: payrunAmendmentListLoading,
        hasMoreData: payrunAmendmentHasMoreData,
        total: payrunAmendmentTotal,
        current: payrunAmendmentPagination,
        sortInfo: payrunAmendmentSortInfo,
        cardGrid: payrunAmendmentCardGrid,
        showAddAndGrid: true,
        title: "Pay Run Amendments",

        showTitle: this.props.showTitle,
        targetEndpoint: payrunAmendmentTargetEndpoint,
        componentTitle: "Pay Run Amendments",

        singleLineGridView: this.state.payrunAmendmentSingleLineGridView,
        displayCardComponent: AmendmentDisplayCard,
        generalAmendmentView: false,
      },
      [PERMANENT_AMENDMENT_TYPE]: {
        claims: adhocAmendmentChildTabContainer,
        isPinned: adhocAmendmentIsPinned,
        gridView: adhocAmendmentGridView,
        gridable: adhocAmendmentGridable,
        grid: adhocAmendmentGrid,
        per_page: adhocAmendmentPerPage,
        generalTablePaginatedComponent: AmendmentDetailList,
        fieldOptions: adhocAmendmentFieldOptions,
        sortingType: adhocAmendmentSortingType,
        paginationType: adhocAmendmentPaginationType,
        list: adhocAmendmentList,
        listLoading: adhocAmendmentListLoading,
        hasMoreData: adhocAmendmentHasMoreData,
        total: adhocAmendmentTotal,
        current: adhocAmendmentPagination,
        sortInfo: adhocAmendmentSortInfo,
        cardGrid: adhocAmendmentCardGrid,
        showAddAndGrid: true,
        title: "Permanent Amendments",

        showTitle: this.props.showTitle,
        targetEndpoint: adhocAmendmentTargetEndpoint,
        componentTitle: "Permanent Amendments",

        singleLineGridView: this.state.adhocAmendmentSingleLineGridView,
        displayCardComponent: AmendmentDisplayCard,
        generalAmendmentView: false,
      },
      [REOCCURRING_AMENDMENT_TYPE]: {
        claims: reoccurringAmendmentChildTabContainer,
        isPinned: reoccurringAmendmentIsPinned,
        gridView: reoccurringAmendmentGridView,
        gridable: reoccurringAmendmentGridable,
        grid: reoccurringAmendmentGrid,
        per_page: reoccurringAmendmentPerPage,
        generalTablePaginatedComponent: AmendmentDetailList,
        fieldOptions: reoccurringAmendmentFieldOptions,
        sortingType: reoccurringAmendmentSortingType,
        paginationType: reoccurringAmendmentPaginationType,
        list: reoccurringAmendmentList,
        listLoading: reoccurringAmendmentListLoading,
        hasMoreData: reoccurringAmendmentHasMoreData,
        total: reoccurringAmendmentTotal,
        current: reoccurringAmendmentPagination,
        sortInfo: reoccurringAmendmentSortInfo,
        cardGrid: reoccurringAmendmentCardGrid,
        showAddAndGrid: true,
        title: "Recurring Amendments",

        showTitle: this.props.showTitle,
        targetEndpoint: reoccurringAmendmentTargetEndpoint,
        componentTitle: "Recurring Amendments",

        singleLineGridView: this.state.reoccurringAmendmentSingleLineGridView,
        displayCardComponent: AmendmentDisplayCard,
        generalAmendmentView: false,
      },
      generalAmendment: {
        claims: pageClaims,
        isPinned: generalAmendmentIsPinned,
        gridView: generalAmendmentGridView,
        gridable: generalAmendmentGridable,
        grid: generalAmendmentGrid,
        per_page: generalAmendmentPerPage,
        generalTablePaginatedComponent: AmendmentDetailList,
        fieldOptions: generalAmendmentFieldOptions,
        sortingType: generalAmendmentSortingType,
        paginationType: generalAmendmentPaginationType,
        list: generalAmendmentList,
        listLoading: generalAmendmentListLoading,
        hasMoreData: generalAmendmentHasMoreData,
        total: generalAmendmentTotal,
        current: generalAmendmentPagination,
        sortInfo: generalAmendmentSortInfo,
        cardGrid: generalAmendmentCardGrid,
        showAddAndGrid: true,
        title: "Amendments",

        showTitle: this.props.showTitle,
        targetEndpoint: generalAmendmentTargetEndpoint,
        componentTitle: "Amendments",

        singleLineGridView: this.state.generalAmendmentSingleLineGridView,
        displayCardComponent: AmendmentDisplayCard,
        generalAmendmentView: true,
      },
    };

    return (
      <If
        condition={dashboardView}
        then={
          <ListContainer
            name={`generalAmendment${this.getComponentName({
              type,
            })}ListContainer`}
            claims={generalAmendmentMapper[type]?.claims}
            isPinned={generalAmendmentMapper[type]?.isPinned}
            updatePinnedComponent={this.updatePinnedComponent}
            gridable={generalAmendmentMapper[type]?.gridable}
            gridView={generalAmendmentMapper[type]?.gridView}
            grid={generalAmendmentMapper[type]?.grid}
            per_page={generalAmendmentMapper[type]?.per_page}
            GeneralTablePaginatedComponent={
              generalAmendmentMapper[type]?.generalTablePaginatedComponent
            }
            GeneralTableScrollableComponent={
              generalAmendmentMapper[type]?.generalTablePaginatedComponent
            }
            generalTableComponentName={`generalAmendment${this.getComponentName(
              { type }
            )}GeneralTable`}
            gridComponentName={`generalAmendment${this.getComponentName({
              type,
            })}GeneralGrid`}
            handleAddAction={this.handlePayrunCreate}
            handleGridChange={(options) =>
              this.handleGeneralAmendmentGridChange({ ...options, type })
            }
            fieldOptions={generalAmendmentMapper[type]?.fieldOptions}
            updateGridField={(options) =>
              this.updateGeneralAmendmentGridField({ ...options, type })
            }
            sortingType={generalAmendmentMapper[type]?.sortingType}
            paginationType={generalAmendmentMapper[type]?.paginationType}
            list={generalAmendmentMapper[type]?.list}
            listLoading={generalAmendmentMapper[type]?.listLoading}
            listGrid={(page) => this.listGeneralAmendmentGrid({ page, type })}
            listScrollingGrid={(options) =>
              this.listScrollingGridGeneralAmendment({ ...options, type })
            }
            hasMoreData={generalAmendmentMapper[type]?.hasMoreData}
            handleGridAction={(options) =>
              this.handleAmendmentActions({ ...options, type })
            }
            listScrollingGeneralTable={(options) =>
              this.listScrollingGeneralAmendment({ ...options })
            }
            total={generalAmendmentMapper[type]?.total}
            current={generalAmendmentMapper[type]?.current}
            sortInfo={generalAmendmentMapper[type]?.sortInfo}
            updatePageSize={(value) =>
              this.generalAmendmentUpdatePageSize({ value, type })
            }
            listGeneralTable={({ options }) =>
              this.listGeneralAmendment({ options, type })
            }
            gridPageSize={getCurrentGridPageSize({
              grid: generalAmendmentMapper[type]?.grid,
              paginationType: generalAmendmentMapper[type]?.paginationType,
            })}
            cardGrid={generalAmendmentMapper[type]?.cardGrid}
            viewComponent={`generalAmendment${this.getComponentName({
              type,
            })}DetailView`}
            pinnedComponentName={`generalAmendment${this.getComponentName({
              type,
            })}IsPinned`}
            showAddAndGrid={true}
            DisplayCardComponent={
              generalAmendmentMapper[type]?.displayCardComponent
            }
            handleActions={(options) =>
              this.handleAmendmentActions({ ...options, type })
            }
            handleEdit={(data) => this.handleEdit({ data, type })}
            rowKeyClassName="bg-table-success vertical-align-top"
            title={this.props.title || "Pay Run Amendments"}
            showTitle={this.props.showTitle}
            targetEndpoint={
              this.props.targetEndpoint ||
              generalAmendmentMapper[type]?.targetEndpoint
            }
            hasNoDuplicateEntity
            SingleLineGridDisplayComponent={
              GeneralAmendmentSingleLineGridDisplay
            }
            singleLineGridView={
              generalAmendmentMapper[type]?.singleLineGridView
            }
            amendmentType={type}
            handleAmendmentActions={(options) =>
              this.handleAmendmentActions({ ...options, type })
            }
            member={member}
            dashboardView={dashboardView}
            componentTitle={this.props.componentTitle}
            filterApplied={this.props.filterApplied}
            getAmendmentHistory={this.props.getAmendmentHistory}
            generalAmendmentView={
              generalAmendmentMapper[type]?.generalAmendmentView
            }
            type={type}
            rowIdParam="amendmentId"
          />
        }
        else={
          <div className="tab-top">
            <CreateAmedmentButton />
            <FilterContainer
              name={GENERAL_AMENDMENT_FILTER_CONTAINER}
              claims={pageClaims}
            >
              <SingleFieldTextBoxFilter
                placeholder="Keywords"
                name={GENERAL_AMENDMENT_FILTER_TEXTBOX}
                claims={this.props.claims}
                value={this.state.filters?.name}
                onChange={(value) =>
                  this.handleChange({ field: "name", value })
                }
                onSearch={this.handleFilter}
                className="mb-2 w-250"
              />

              <SingleFieldDropdownFilter
                options={AMENDMENT_TYPES_OPTIONS}
                name="generalAmendmentTypeDropdownFilter"
                value={this.state.filters?.amendmentTypeId || undefined}
                defaultValue={undefined}
                onSelect={(value) =>
                  this.handleChange({
                    field: "amendmentType",
                    value: Number(value),
                  })
                }
                className="mb-2 mr-3"
                claims={this.props.claims}
                valueIsNumber={true}
                nameParam="name"
                canBeEmpty
                placeholder="Amendment Type"
              />

              <SearchIconButton
                loading={searchLoading}
                onClick={this.handleFilter}
                name={GENERAL_AMENDMENT_SEARCH_BUTTON}
                claims={this.props.claims}
              />
              <LinkButton
                buttonText="Reset Filter"
                className="color-primary hand"
                onClick={this.resetFilter}
                name={GENERAL_AMENDMENT_RESET_FILTER}
                claims={this.props.claims}
              />
            </FilterContainer>
            {/* TODO: SA has now same view as Payroll Users. Need to remove the codes handling SA view */}
            {/* Render separate view for SA and Company users */}
            <If
              condition={isUserSystemAdmin({ member })}
              then={
                <ListContainer
                  name="generalAmendmentListContainer"
                  claims={pageClaims}
                  isPinned={generalAmendmentIsPinned}
                  updatePinnedComponent={this.updatePinnedComponent}
                  gridable={generalAmendmentGridable}
                  gridView={generalAmendmentGridView}
                  grid={generalAmendmentGrid}
                  per_page={generalAmendmentPerPage}
                  GeneralTablePaginatedComponent={AmendmentDetailList}
                  GeneralTableScrollableComponent={AmendmentDetailList}
                  generalTableComponentName={"generalAmendmentGeneralTable"}
                  gridComponentName={"generalAmendmentGeneralGrid"}
                  handleAddAction={this.handlePayrunCreate}
                  handleGridChange={(options) =>
                    this.handleGeneralAmendmentGridChange({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  fieldOptions={generalAmendmentFieldOptions}
                  updateGridField={(options) =>
                    this.updateGeneralAmendmentGridField({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  sortingType={generalAmendmentSortingType}
                  paginationType={generalAmendmentPaginationType}
                  list={generalAmendmentList}
                  listLoading={generalAmendmentListLoading}
                  listGrid={(page) =>
                    this.listGeneralAmendmentGrid({
                      page,
                      type: "generalAmendment",
                    })
                  }
                  listScrollingGrid={(options) =>
                    this.listScrollingGridGeneralAmendment({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  hasMoreData={generalAmendmentHasMoreData}
                  handleGridAction={(options) =>
                    this.handleAmendmentActions({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  listScrollingGeneralTable={(options) =>
                    this.listScrollingGeneralAmendment({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  total={generalAmendmentTotal}
                  current={generalAmendmentPagination}
                  sortInfo={generalAmendmentSortInfo}
                  updatePageSize={(value) =>
                    this.generalAmendmentUpdatePageSize({
                      value,
                      type: "generalAmendment",
                    })
                  }
                  listGeneralTable={({ options }) =>
                    this.listGeneralAmendment({
                      options,
                      type: "generalAmendment",
                    })
                  }
                  gridPageSize={getCurrentGridPageSize({
                    grid: generalAmendmentGrid,
                    paginationType: generalAmendmentPaginationType,
                  })}
                  cardGrid={generalAmendmentCardGrid}
                  component="generalAmendmentDetailView"
                  gridActionComponentName={"generalAmendmentDetailView"}
                  pinnedComponentName="generalAmendmentIsPinned"
                  showAddAndGrid={true}
                  DisplayCardComponent={AmendmentDisplayCard}
                  handleActions={(options) =>
                    this.handleAmendmentActions({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  handleEdit={(data) =>
                    this.handleEdit({ data, type: "generalAmendment" })
                  }
                  rowKeyClassName="bg-table-success vertical-align-top"
                  filterLabel={filterLabel}
                  title={"Amendments"}
                  showTitle={this.props.showTitle}
                  targetEndpoint={generalAmendmentTargetEndpoint}
                  hasNoDuplicateEntity={true}
                  componentTitle="Amendments"
                  SingleLineGridDisplayComponent={
                    GeneralAmendmentSingleLineGridDisplay
                  }
                  singleLineGridView={
                    this.state.generalAmendmentSingleLineGridView
                  }
                  amendmentType={PAYRUN_AMENDMENT_TYPE}
                  handleAmendmentActions={(options) =>
                    this.handleAmendmentActions({
                      ...options,
                      type: "generalAmendment",
                    })
                  }
                  member={member}
                  dashboardView={dashboardView}
                  filterApplied={filterLabel}
                  getAmendmentHistory={this.props.getAmendmentHistory}
                  editComponent="generalAmendmentEdit"
                  deleteComponent="generalAmendmentDelete"
                  rowIdParam="amendmentId"
                  generalAmendmentView={true}
                  deleteAmendment={this.props.deleteAmendment}
                />
              }
              else={
                <MultiTabContainer
                  name="generalAmendmentMultiTabContainer"
                  claims={pageClaims}
                  activeKey={activeKey}
                  handleTabChange={this.onTabChange}
                >
                  {isComponentVisible({
                    claim: payrunAmendmentTabContainer,
                  }) && (
                    <TabPane
                      tab={
                        <TabHeader
                          title="Pay Run Amendments"
                          total={payrunAmendmentTotal}
                        />
                      }
                      key={"1"}
                    >
                      <ChildTabContainer
                        name={
                          GENERAL_AMENDMENT_PAYRUN_AMENDMENT_CHILD_TAB_CONTAINER
                        }
                        claims={payrunAmendmentTabContainer}
                      >
                        <ListContainer
                          name={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER
                          }
                          claims={payrunAmendmentChildTabContainer}
                          isPinned={payrunAmendmentIsPinned}
                          updatePinnedComponent={this.updatePinnedComponent}
                          gridable={payrunAmendmentGridable}
                          gridView={payrunAmendmentGridView}
                          grid={payrunAmendmentGrid}
                          per_page={payrunAmendmentPerPage}
                          GeneralTablePaginatedComponent={AmendmentDetailList}
                          GeneralTableScrollableComponent={AmendmentDetailList}
                          generalTableComponentName={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE
                          }
                          gridComponentName={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID
                          }
                          handleAddAction={this.handlePayrunCreate}
                          handleGridChange={(options) =>
                            this.handleGeneralAmendmentGridChange({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          fieldOptions={payrunAmendmentFieldOptions}
                          updateGridField={(options) =>
                            this.updateGeneralAmendmentGridField({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          sortingType={payrunAmendmentSortingType}
                          paginationType={payrunAmendmentPaginationType}
                          list={payrunAmendmentList}
                          listLoading={payrunAmendmentListLoading}
                          listGrid={(page) =>
                            this.listGeneralAmendmentGrid({
                              page,
                              type: "payrunAmendment",
                            })
                          }
                          listScrollingGrid={(options) =>
                            this.listScrollingGridGeneralAmendment({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          hasMoreData={payrunAmendmentHasMoreData}
                          handleGridAction={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          listScrollingGeneralTable={(options) =>
                            this.listScrollingGeneralAmendment({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          total={payrunAmendmentTotal}
                          current={payrunAmendmentPagination}
                          sortInfo={payrunAmendmentSortInfo}
                          updatePageSize={(value) =>
                            this.generalAmendmentUpdatePageSize({
                              value,
                              type: "payrunAmendment",
                            })
                          }
                          listGeneralTable={({ options }) =>
                            this.listGeneralAmendment({
                              options,
                              type: "payrunAmendment",
                            })
                          }
                          gridPageSize={getCurrentGridPageSize({
                            grid: payrunAmendmentGrid,
                            paginationType: payrunAmendmentPaginationType,
                          })}
                          cardGrid={payrunAmendmentCardGrid}
                          component={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DETAIL_VIEW
                          }
                          gridActionComponentName={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DETAIL_VIEW
                          }
                          pinnedComponentName="generalAmendmentPayrunAmendmentIsPinned"
                          showAddAndGrid={true}
                          DisplayCardComponent={AmendmentDisplayCard}
                          handleActions={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          handleEdit={(data) =>
                            this.handleEdit({ data, type: "payrunAmendment" })
                          }
                          rowKeyClassName="bg-table-success vertical-align-top"
                          filterLabel={filterLabel}
                          title={this.props.title || "Pay Run Amendments"}
                          showTitle={this.props.showTitle}
                          targetEndpoint={payrunAmendmentTargetEndpoint}
                          hasNoDuplicateEntity={true}
                          componentTitle="Pay Run Amendments"
                          SingleLineGridDisplayComponent={
                            GeneralAmendmentSingleLineGridDisplay
                          }
                          singleLineGridView={
                            this.state.payrunAmendmentSingleLineGridView
                          }
                          amendmentType={PAYRUN_AMENDMENT_TYPE}
                          handleAmendmentActions={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "payrunAmendment",
                            })
                          }
                          member={member}
                          dashboardView={dashboardView}
                          filterApplied={filterLabel}
                          getAmendmentHistory={this.props.getAmendmentHistory}
                          editComponent={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_EDIT
                          }
                          deleteComponent={
                            GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DELETE
                          }
                          rowIdParam="amendmentId"
                          deleteAmendment={this.props.deleteAmendment}
                        />
                      </ChildTabContainer>
                    </TabPane>
                  )}
                  {isComponentVisible({
                    claim: adhocAmendmentTabContainer,
                  }) && (
                    <TabPane
                      tab={
                        <TabHeader
                          title="Permanent Amendments"
                          total={adhocAmendmentTotal}
                        />
                      }
                      key={"2"}
                    >
                      <ChildTabContainer
                        name="generalAmendmentAdhocAmendmentChildTabContainer"
                        claims={adhocAmendmentTabContainer}
                      >
                        <ListContainer
                          name="generalAmendmentAdhocAmendmentListContainer"
                          claims={adhocAmendmentChildTabContainer}
                          isPinned={adhocAmendmentIsPinned}
                          updatePinnedComponent={this.updatePinnedComponent}
                          gridable={adhocAmendmentGridable}
                          gridView={adhocAmendmentGridView}
                          grid={adhocAmendmentGrid}
                          per_page={adhocAmendmentPerPage}
                          GeneralTablePaginatedComponent={AmendmentDetailList}
                          GeneralTableScrollableComponent={AmendmentDetailList}
                          generalTableComponentName={
                            "generalAmendmentAdhocAmendmentGeneralTable"
                          }
                          gridComponentName={
                            "generalAmendmentAdhocAmendmentGeneralGrid"
                          }
                          handleAddAction={this.handlePayrunCreate}
                          handleGridChange={(options) =>
                            this.handleGeneralAmendmentGridChange({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          fieldOptions={adhocAmendmentFieldOptions}
                          updateGridField={(options) =>
                            this.updateGeneralAmendmentGridField({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          sortingType={adhocAmendmentSortingType}
                          paginationType={adhocAmendmentPaginationType}
                          list={adhocAmendmentList}
                          listLoading={adhocAmendmentListLoading}
                          listGrid={(page) =>
                            this.listGeneralAmendmentGrid({
                              page,
                              type: "adhocAmendment",
                            })
                          }
                          listScrollingGrid={(options) =>
                            this.listScrollingGridGeneralAmendment({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          hasMoreData={adhocAmendmentHasMoreData}
                          handleGridAction={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          listScrollingGeneralTable={(options) =>
                            this.listScrollingGeneralAmendment({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          total={adhocAmendmentTotal}
                          current={adhocAmendmentPagination}
                          sortInfo={adhocAmendmentSortInfo}
                          updatePageSize={(value) =>
                            this.generalAmendmentUpdatePageSize({
                              value,
                              type: "adhocAmendment",
                            })
                          }
                          listGeneralTable={({ options }) =>
                            this.listGeneralAmendment({
                              options,
                              type: "adhocAmendment",
                            })
                          }
                          gridPageSize={getCurrentGridPageSize({
                            grid: adhocAmendmentGrid,
                            paginationType: adhocAmendmentPaginationType,
                          })}
                          cardGrid={adhocAmendmentCardGrid}
                          component="generalAmendmentAdhocAmendmentDetailView"
                          gridActionComponentName={
                            "generalAmendmentAdhocAmendmentDetailView"
                          }
                          pinnedComponentName="generalAmendmentAdhocAmendmentIsPinned"
                          showAddAndGrid={true}
                          DisplayCardComponent={AmendmentDisplayCard}
                          handleActions={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          handleEdit={(data) =>
                            this.handleEdit({ data, type: "adhocAmendment" })
                          }
                          rowKeyClassName="bg-table-success vertical-align-top"
                          filterLabel={filterLabel}
                          title={this.props.title || "Permanent Amendments"}
                          showTitle={this.props.showTitle}
                          targetEndpoint={adhocAmendmentTargetEndpoint}
                          hasNoDuplicateEntity={true}
                          componentTitle="Permanent Amendments"
                          filterApplied={filterLabel}
                          SingleLineGridDisplayComponent={
                            GeneralAmendmentSingleLineGridDisplay
                          }
                          singleLineGridView={
                            this.state.adhocAmendmentSingleLineGridView
                          }
                          amendmentType={PERMANENT_AMENDMENT_TYPE}
                          handleAmendmentActions={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "adhocAmendment",
                            })
                          }
                          getAmendmentHistory={this.props.getAmendmentHistory}
                          editComponent="generalAmendmentAdhocAmendmentEdit"
                          deleteComponent="generalAmendmentAdhocAmendmentDelete"
                          type="adhocAmendment"
                          rowIdParam="amendmentId"
                          deleteAmendment={this.props.deleteAmendment}
                        />
                      </ChildTabContainer>
                    </TabPane>
                  )}
                  {isComponentVisible({
                    claim: reoccurringAmendmentTabContainer,
                  }) && (
                    <TabPane
                      tab={
                        <TabHeader
                          title="Recurring Amendments"
                          total={reoccurringAmendmentTotal}
                        />
                      }
                      key={"3"}
                    >
                      <ChildTabContainer
                        name="generalAmendmentReoccurringAmendmentChildTabContainer"
                        claims={reoccurringAmendmentTabContainer}
                      >
                        <ListContainer
                          name="generalAmendmentReoccurringAmendmentListContainer"
                          claims={reoccurringAmendmentChildTabContainer}
                          isPinned={reoccurringAmendmentIsPinned}
                          updatePinnedComponent={this.updatePinnedComponent}
                          gridable={reoccurringAmendmentGridable}
                          gridView={reoccurringAmendmentGridView}
                          grid={reoccurringAmendmentGrid}
                          per_page={reoccurringAmendmentPerPage}
                          GeneralTablePaginatedComponent={AmendmentDetailList}
                          GeneralTableScrollableComponent={AmendmentDetailList}
                          generalTableComponentName={
                            "generalAmendmentReoccurringAmendmentGeneralTable"
                          }
                          gridComponentName={
                            "generalAmendmentReoccurringAmendmentGeneralGrid"
                          }
                          handleAddAction={this.handlePayrunCreate}
                          handleGridChange={(options) =>
                            this.handleGeneralAmendmentGridChange({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          fieldOptions={reoccurringAmendmentFieldOptions}
                          updateGridField={(options) =>
                            this.updateGeneralAmendmentGridField({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          sortingType={reoccurringAmendmentSortingType}
                          paginationType={reoccurringAmendmentPaginationType}
                          list={reoccurringAmendmentList}
                          listLoading={reoccurringAmendmentListLoading}
                          listGrid={(page) =>
                            this.listGeneralAmendmentGrid({
                              page,
                              type: "reoccurringAmendment",
                            })
                          }
                          listScrollingGrid={(options) =>
                            this.listScrollingGridGeneralAmendment({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          hasMoreData={reoccurringAmendmentHasMoreData}
                          handleGridAction={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          listScrollingGeneralTable={(options) =>
                            this.listScrollingGeneralAmendment({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          total={reoccurringAmendmentTotal}
                          current={reoccurringAmendmentPagination}
                          sortInfo={reoccurringAmendmentSortInfo}
                          updatePageSize={(value) =>
                            this.generalAmendmentUpdatePageSize({
                              value,
                              type: "reoccurringAmendment",
                            })
                          }
                          listGeneralTable={({ options }) =>
                            this.listGeneralAmendment({
                              options,
                              type: "reoccurringAmendment",
                            })
                          }
                          gridPageSize={getCurrentGridPageSize({
                            grid: reoccurringAmendmentGrid,
                            paginationType: reoccurringAmendmentPaginationType,
                          })}
                          cardGrid={reoccurringAmendmentCardGrid}
                          component="generalAmendmentReoccurringAmendmentDetailView"
                          gridActionComponentName={
                            "generalAmendmentReoccurringAmendmentDetailView"
                          }
                          editComponent="generalAmendmentReoccurringAmendmentEdit"
                          deleteComponent="generalAmendmentReoccurringAmendmentDelete"
                          pinnedComponentName="generalAmendmentReoccurringAmendmentIsPinned"
                          showAddAndGrid={true}
                          DisplayCardComponent={AmendmentDisplayCard}
                          handleActions={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          handleEdit={(data) =>
                            this.handleEdit({
                              data,
                              type: "reoccurringAmendment",
                            })
                          }
                          rowKeyClassName="bg-table-success vertical-align-top"
                          filterLabel={filterLabel}
                          title={this.props.title || "Recurring Amendments"}
                          showTitle={this.props.showTitle}
                          targetEndpoint={reoccurringAmendmentTargetEndpoint}
                          hasNoDuplicateEntity={true}
                          componentTitle="Recurring Amendments"
                          filterApplied={filterLabel}
                          SingleLineGridDisplayComponent={
                            GeneralAmendmentSingleLineGridDisplay
                          }
                          singleLineGridView={
                            this.state.reoccurringAmendmentSingleLineGridView
                          }
                          amendmentType={REOCCURRING_AMENDMENT_TYPE}
                          handleAmendmentActions={(options) =>
                            this.handleAmendmentActions({
                              ...options,
                              type: "reoccurringAmendment",
                            })
                          }
                          CustomActions={
                            <SwitchButtonWithText
                              unCheckedChildren="Show Archived"
                              size="default"
                              checkedChildren="Hide Archived"
                              checked={
                                this.state.reoccurringAmendmentShowArchived
                              }
                              onChange={this.updateShowArchived}
                              className="mb-4"
                              claims={reoccurringAmendmentListContainer}
                              componentName="generalAmendmentReoccurringAmendmentShowHideArchieved"
                            />
                          }
                          getAmendmentHistory={this.props.getAmendmentHistory}
                          type="reoccurringAmendment"
                          rowIdParam="amendmentId"
                          deleteAmendment={this.props.deleteAmendment}
                        />
                      </ChildTabContainer>
                    </TabPane>
                  )}
                </MultiTabContainer>
              }
            />
            <AmendmentDetailsModal
              amendmentId={this.state.selectedAmendment?.amendmentId}
              handlePayrunView={this.handlePayrunView}
              handlePayscheduleView={this.handlePayscheduleView}
              handleClose={this.handleCloseAmendmentModal}
              visible={this.state.viewAmendmentModal}
              payRunID={this.state.selectedAmendment?.payrunId}
            />
          </div>
        }
      />
    );
  }
}

export default withRouter(GeneralAmendmentIndex);
