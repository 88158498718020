import { useEffect, useState } from 'react';
import { OnChangeFn, SortingState } from '@tanstack/react-table';

import { useAmendmentDetailsModal } from '@xemplo/amendment-details-modal';
import { useGetAmendmentsQueryV2 } from '@xemplo/amendment-query';
import { AmendmentsTableQueryFilter } from '@xemplo/amendments-table-filter';
import { builSortParams } from '@xemplo/gp-api';
import { AmendmentTypes } from '@xemplo/gp-types';
import { useServerSideState } from '@xemplo/table';

import { columns } from './amendments-table.helper';
import * as S from './amendments-table.styles';

export function AmendmentsTable(
  props: Readonly<{
    amendmentType?: AmendmentTypes | null;
    filter?: AmendmentsTableQueryFilter;
  }>
) {
  const { amendmentType, filter } = props;
  const [sorting, setSorting] = useState<SortingState>([]);

  const { updateModal } = useAmendmentDetailsModal();

  const handleSortingChange: OnChangeFn<SortingState> = (sorting) => {
    setSorting(sorting);
  };

  const { pagination, onPaginationChange } = useServerSideState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { pageIndex, pageSize } = pagination;

  const { data, isLoading } = useGetAmendmentsQueryV2({
    requestParams: {
      per_page: pageSize,
      page: pageIndex + 1, // BE isnt zero-indexed here
      ...builSortParams(sorting),
      filter: {
        ...(amendmentType ? { amendmentType } : {}),
        ...(filter ?? { filter }),
      },
    },
  });

  // reset the pagination so after filters have changed
  // so we dont end up with a invalid page index
  useEffect(() => {
    pagination.pageIndex = 0;
  }, [amendmentType, filter]);

  return (
    <S.AmendmentListTable
      data={data?.result?.rows ?? []}
      columns={columns}
      enableRowSelection={false}
      enablePagination
      manualPagination
      isListTable
      onBodyRowClick={(e, row) => updateModal(row.original)}
      pageCount={data?.result?.total_Pages ?? 0}
      state={{ pagination, sorting }}
      onPaginationChange={onPaginationChange}
      onSortingChange={handleSortingChange}
      totalRowCount={data?.result?.total ?? 0}
      isLoading={isLoading}
    />
  );
}
