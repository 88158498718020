import ReactGA from "react-ga4";

// Hooks to track user events
const useAnalyticsEventTracker = (category = "Test category") => {
  //Page title or module type used for the category
  const eventTracker = (action = "test action", label = "test label") => {
    //label is optional
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
