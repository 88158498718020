import {
  filteredByLabel,
  isComponentVisible,
  mustBeArray,
  requestUrlBuilder,
  trimWhiteSpaces,
} from "./utilities";
import {
  amendmentIsAssociatedWithPayrun,
  isAmendmentAutomated,
  amendmentIsRejected,
} from "./amendment";
import {
  AMENDMENT_APPROVED_STATUS,
  AMENDMENT_DECLINED_STATUS,
  AMENDMENT_STATUS_ID,
  REOCCURRING_STATUS_APPLIED_ID,
  REOCCURRING_STATUS_MANUAL_ID,
  REOCCURRING_STATUS_UNAPPLIED_ID,
} from "../constants";
import APIHandler from "../constants/apiUrl";
import { getGridTableRequestOptions } from "./personalization";
import { convertDateToUtc } from "./timeHandlers";

export const getGeneralAmendmentActionText = ({
  appliedInKeypayClaim,
  applyCancelClaim,
  applyEditedInKeypayClaim,
}: any) => {
  if (isComponentVisible({ claim: appliedInKeypayClaim }))
    return "verifiedInKeypay";
  if (isComponentVisible({ claim: applyCancelClaim })) return "applyCancel";
  if (isComponentVisible({ claim: applyEditedInKeypayClaim }))
    return "applyEditedInKeypay";
  return "";
};

export const getGeneralAmendmentStatusText = ({
  appliedInKeypayClaim,
  applyCancelClaim,
  applyEditedInKeypayClaim,
  amendment,
}: any) => {
  if (isComponentVisible({ claim: appliedInKeypayClaim }))
    return isAmendmentAutomated({ amendment })
      ? "Approve"
      : "Applied In Keypay";
  if (isComponentVisible({ claim: applyCancelClaim }))
    return "Approve Cancellation";
  if (isComponentVisible({ claim: applyEditedInKeypayClaim }))
    return "Applied Edited In Keypay";
  return "";
};

export const displayGeneralAmendmentActionOptions = ({
  appliedInKeypayClaim,
  applyCancelClaim,
  applyEditedInKeypayClaim,
}: any) => {
  return (
    isComponentVisible({ claim: appliedInKeypayClaim }) ||
    isComponentVisible({ claim: applyCancelClaim }) ||
    isComponentVisible({ claim: applyEditedInKeypayClaim })
  );
};

// Applied filters for fetching data
export const getAppliedFilters = (filters: {
  name?: any;
  type?: any;
  changeType?: any;
  loading?: boolean;
  businessUnit?: string;
  startDate?: string | null;
  endDate?: string | null;
  requestedBy?: string;
}) => {
  let tempFilters: any = {};
  if (filters.type && Number(filters.type) !== 0) {
    tempFilters.amendmentType = filters.type;
  }
  if (filters.changeType && Number(filters.changeType) !== 0) {
    tempFilters.amendmentTaskTypeId = filters.changeType;
  }
  if (filters.name) {
    tempFilters.name = trimWhiteSpaces(filters.name);
  }
  if (filters.startDate && filters.endDate) {
    // Convert the selected date to utc
    tempFilters.startDate = convertDateToUtc({
      date: filters.startDate,
      currentFormat: "DD/MM/YYYY",
    });
    tempFilters.endDate = convertDateToUtc({
      date: `${filters.endDate} 23:59:59`,
      currentFormat: "DD/MM/YYYY hh:mm:ss",
    });
  }
  if (filters.requestedBy) {
    tempFilters.requestedById = filters.requestedBy;
  }
  if (filters.businessUnit) {
    tempFilters.businessUnitId = filters.businessUnit;
  }
  return tempFilters;
};

// Return active filters for pinned component title
export const getFilterLabel = (appliedFilters: {
  name?: string;
  amendmentType?: string;
  amendmentTaskTypeId?: string;
  changeTypeList: Array<any>;
  amendmentTypeList: any;
  businessUnit?: string;
  startDate?: string | null;
  endDate?: string | null;
  requestedById?: string;
  businessUnitList?: Array<any>;
  requestedByList: Array<{ name: string; id: string; email: string }>;
}) => {
  let activeFilters: Array<any> = [];
  if (appliedFilters.name) {
    activeFilters.push(appliedFilters.name);
  }
  if (appliedFilters.amendmentType) {
    const selectedAmendmentType = mustBeArray(
      appliedFilters.amendmentTypeList
    ).find((o) => o.id === appliedFilters.amendmentType);
    activeFilters.push(selectedAmendmentType?.name);
  }

  if (appliedFilters.amendmentTaskTypeId) {
    const changeType = mustBeArray(appliedFilters.changeTypeList).find(
      (o: any) => o.changeTaskValueId === appliedFilters.amendmentTaskTypeId
    );
    activeFilters.push(changeType?.name);
  }
  if (appliedFilters.requestedById) {
    const selectedRequestedBy = mustBeArray(
      appliedFilters.requestedByList
    ).find((o) => o.id === appliedFilters.requestedById);
    activeFilters.push(selectedRequestedBy?.name);
  }
  return filteredByLabel({
    filters: activeFilters,
    startDate: appliedFilters?.startDate,
    endDate: appliedFilters?.endDate,
    format: "DD/MM/YYYY",
  });
};

// Get target endpoint
export const getTargetEndpoint = ({
  gridView,
  table,
  grid,
  appliedFilters,
  isPending,
}: any) => {
  const baseUrl = APIHandler.constructEndpoint({
    endpoint: "GENERAL_AMENDMENTS_URL",
  });
  return requestUrlBuilder(baseUrl, {
    ...getGridTableRequestOptions({
      gridView: gridView,
      table: table,
      grid: grid,
      filters: appliedFilters,
      singleLineGridView: false,
      queryParams: { isPending: isPending },
    }),
  });
};

// Validation to display applied to payroll option
export const displayAppliedToPayroll = ({ record }: any): boolean => {
  // Not required for payrun amendments
  if (amendmentIsAssociatedWithPayrun({ amendment: record })) return false;
  // Both Amendments
  return !(
    !AMENDMENT_APPROVED_STATUS.includes(getAmendmentStatusId(record)) ||
    record?.isIntegrated ||
    ![REOCCURRING_STATUS_MANUAL_ID, REOCCURRING_STATUS_UNAPPLIED_ID].includes(
      getAmendmentStatusInPPSId(record)
    )
  );
};

// Validation to display approve option in general amendment
export const displayApproveAmendment = ({ record }: any): boolean => {
  // Payrun status
  // Check the status of the payrun
  if (AMENDMENT_APPROVED_STATUS.includes(getAmendmentStatusId(record)))
    return false;
  if (
    amendmentIsAssociatedWithPayrun({ amendment: record }) &&
    !payrunAmendmentCanBeActioned(record?.payRunStatusId)
  )
    return false;
  return true;
};

// Validation to display reject option in general amendment
export const displayRejectAmendment = ({ record }: any): boolean => {
  // Payrun status
  // Check the status of the payrun
  if (
    (AMENDMENT_APPROVED_STATUS.includes(getAmendmentStatusId(record)) &&
      record?.isIntegrated) ||
    getAmendmentStatusInPPSId(record) === AMENDMENT_DECLINED_STATUS
  )
    return false;
  if (
    amendmentIsAssociatedWithPayrun({ amendment: record }) &&
    !payrunAmendmentCanBeActioned(record?.payRunStatusId)
  )
    return false;
  return true;
};

/**  Validation to display delete option in general amendment table */
export const displayDeleteAmendment = ({ record }: any): boolean => {
  const canBeDeteled = [
    AMENDMENT_STATUS_ID.CREATED,
    AMENDMENT_STATUS_ID.DRAFT,
    AMENDMENT_STATUS_ID.UPDATED,
  ];
  return canBeDeteled.includes(getAmendmentStatusId(record));
};

/** Validation to display unapply in keypay option
 * Amendments can be unapplied only if they are already applied and integration settings are off.
 * Payrun amendments cannot be unapplied
 * If an amendment is rejected it cannot be unapplied
 * */
export const displayUnapplyAmendmentInKeypay = ({ record }: any): boolean => {
  if (
    amendmentIsAssociatedWithPayrun({ amendment: record }) ||
    amendmentIsRejected({ amendment: record })
  )
    return false;
  return (
    !record?.isIntegrated &&
    REOCCURRING_STATUS_APPLIED_ID === getAmendmentStatusInPPSId(record)
  );
};

// When the payrun amendments can be actioned - approved/rejected
const payrunAmendmentCanBeActioned = (id: number) => {
  return [4, 5, 6, 7].includes(id);
};

// Get update amendment payloads
export const getUpdateAmendmentPayloads = (data: any) => {
  return {
    amendmentSummary: data?.amendmentSummary ?? "",
    assignTo: data?.assignTo,
    amendmentStatusId: getAmendmentStatusId(data),
    amendmentStatusInPPSId: getAmendmentStatusInPPSId(data),
    payscheduleId: data?.payscheduleID ?? "00000000-0000-0000-0000-000000000000",
  };
};

export const getAmendmentStatusId = (record?: any) => {
  return record?.amendmentStatusId ?? record?.amendmentStatusID;
};

export const getAmendmentStatusInPPSId = (record?: any) => {
  return record?.amendmentStatusInPPSId ?? record?.amendmentStatusInPPSID;
};

export const getAmendmentTaskTypeId = (record?: any) => {
  return record?.amendmentTaskTypeId ?? record?.amendmentTaskTypeID;
};