import { RowData, Table } from '@tanstack/react-table';
import classnames from 'classnames';

import { Button, ButtonSize, ButtonType, ButtonVariant } from '@xemplo/button';
import { IconButtonSize, IconButtonVariant } from '@xemplo/icon-button';
import { DawnCross16 } from '@xemplo/icons';

import { DefaultItemCounter } from '../item-counter';
import { formatNumber } from '../table.helper';
import { TableProps } from '../table.types';

import * as S from './action-bar.style';

export const ActionBarTestId = {
  container: 'action-bar-container',
  counter: 'action-bar-counter',
  selectedCounter: 'action-bar-selected-counter',
  customActions: 'action-bar-custom-actions',
  divider: 'action-bar-divider',
  selector: 'action-bar-selector',
  closeButton: 'action-bar-close-button',
};

type ActionBarProps<TData> = {
  table: Table<TData>;
};

export const ActionBar = <TData extends RowData>(props: ActionBarProps<TData>) => {
  const { table } = props;
  const { enableRowSelection } = table.options as TableProps<TData>;
  const hasSelected = !!table.getSelectedRowModel().rows.length;
  //  Note: table.getIsSomeRowsSelected() is always false in the second page of the table
  if (!enableRowSelection) return null;

  return (
    <S.Container
      className={classnames('action-bar-container', {
        'has-selected': hasSelected,
      })}
      data-testid={ActionBarTestId.container}
    >
      <DefaultActionBar {...props} />
    </S.Container>
  );
};

function DefaultActionBar<TData>({ table }: ActionBarProps<TData>) {
  const { selectedActions } = table.options as TableProps<TData>;
  const hasSelectedRows = !!table.getSelectedRowModel().rows.length;
  //  Note: table.getIsSomeRowsSelected() is always false in the second page of the table
  if (hasSelectedRows) {
    return (
      <>
        <SelectedRowCounter table={table} />
        <S.Divider className="light" />
        <SelectAllRows table={table} />
        {selectedActions && (
          <S.SelectedActions>{selectedActions({ table })}</S.SelectedActions>
        )}
        <S.CloseButton
          ariaLabel="close action bar button"
          id="close-action-bar"
          naked
          size={IconButtonSize.Small}
          variant={IconButtonVariant.Lighter}
          onClick={() => table.resetRowSelection()}
        >
          <DawnCross16 />
        </S.CloseButton>
      </>
    );
  }

  return (
    <>
      <DefaultItemCounter table={table} testId={ActionBarTestId.counter} />
      <S.Divider />
      <SelectAllRows table={table} />
    </>
  );
}

function SelectedRowCounter<TData>({ table }: ActionBarProps<TData>) {
  const { rows } = table.getSelectedRowModel();
  const selectedCount = formatNumber({ value: rows.length });
  const { isListTable } = table.options as TableProps<TData>;
  const recordType = isListTable
    ? pluralize(rows.length, 'item', 'items')
    : pluralize(rows.length, 'row', 'rows');
  return (
    <span data-testid={ActionBarTestId.selectedCounter}>
      {selectedCount} {recordType} selected
    </span>
  );
}

function SelectAllRows<TData>({ table }: ActionBarProps<TData>) {
  const { rows } = table.getCoreRowModel();
  const toggleSelect = table.getToggleAllRowsSelectedHandler();
  const isAllSelected = table.getIsAllRowsSelected();
  const isAnySelected = !!table.getSelectedRowModel().rows.length;
  return (
    <Button
      ariaLabel="Select all rows"
      testId={ActionBarTestId.selector}
      type={ButtonType.Tertiary}
      size={ButtonSize.Small}
      variant={isAnySelected ? ButtonVariant.Light : ButtonVariant.Dark}
      onClick={toggleSelect}
      data-testid={ActionBarTestId.selector}
    >
      {isAllSelected
        ? 'Deselect all'
        : `Select all (${formatNumber({ value: rows.length })})`}
    </Button>
  );
}

function pluralize(value: number, singular: string, plural: string) {
  return value > 1 ? plural : singular;
}
