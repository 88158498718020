import React from "react";
import { Card, Tooltip, Typography, Avatar } from "antd";
import { ContractIcon } from "../../../Common/customicons";
import {
  renderReoccurringEndDate,
  getConditionalResponse,
  renderDefaultDate,
  getAmendmentRequestedByUserName,
  getAssignedToUsersText,
  getGeneralAmendmentApplyCancelClaimName,
  getGeneralAmendmentApplyEditedClaimName,
  getGeneralAmendmentApplyInKeypayClaimName,
  getGeneralAmendmentDeleteComponentName,
  getGeneralAmendmentRequestCancelClaimName,
  getGeneralAmendmentResubmitClaimName,
  showDeleteGeneralAmendment,
  getAmendmentStatusId,
  getAmendmentStatusInPPSId,
  getAmendmentTaskTypeId,
} from "../../../libs";
import GridActionComponent from "../common/gridAction";
import {
  PERMANENT_AMENDMENT_TYPE_ID,
  REOCCURRING_AMENDMENT_TYPE_ID,
  TERMINATION_CHANGE_TYPE_ID,
} from "../../../constants";
import { If } from "../../../Common/ui";
import {
  AmendmentStatusBadge,
  KeypayStatusBadge,
} from "../../../Common/amendments";
import { PendingAmountValue } from "./Amendments/pending-amount-value";

const { Title } = Typography;

const AmendmentDisplayCard = ({
  handleAction,
  content,
  claims,
  component,
  deleteComponent,
  generalAmendmentView,
  payrun,
  amendmentType,
  handleAmendmentActions,
}: any) => (
  <Card
    className="card hand card-prop h-full mb-4 pb-0"
    bordered={false}
    onClick={() => handleAction({ data: content, action: "view" })}
  >
    <If
      condition={content}
      then={
        <React.Fragment>
          <div className="relative py-3 px-0">
            <div className="mb-2.5 relative flex">
              <Avatar size={36}>
                <ContractIcon className="text-lg" />
              </Avatar>
              <div className="ml-2 relative">
                <Tooltip placement="top" title={content.amendmentTaskTypeName}>
                  <Title
                    ellipsis
                    className="card-title"
                    level={3}
                  >{`${content.amendmentTaskTypeName}`}</Title>
                </Tooltip>
                <Title ellipsis className="card-subtitle" level={4}>
                  <span>
                    Total Amount:{" "}
                    {getAmendmentTaskTypeId(content) ===
                      TERMINATION_CHANGE_TYPE_ID ? (
                      "N/A"
                    ) : (
                      //TODO: Replace calculated value with BE returned totalAmount https://expedo.atlassian.net/browse/GC-870  */
                      <PendingAmountValue
                        value={(content.amount ?? 0) * (content.units ?? 0)}
                        isIntegrated={content.isIntegrated}
                        amendmentKeypayStatusId={
                          content.amendmentKeypayStatusId
                        }
                        amendmentTaskTypeId={getAmendmentTaskTypeId(content)}
                      />
                    )}
                  </span>
                </Title>
              </div>
            </div>
            <div className="flex justify-between mb-1 text-xs">
              Business Unit: {content?.businessUnitName}
            </div>
            {/*content?.amendmentSubTypeName && (
              <div className="flex justify-between mb-1 text-xs">
                Sub Type: {content?.amendmentSubTypeName}
              </div>
            )*/}
            <div className="flex justify-between mb-1 text-xs">
              Requested By:{" "}
              {getAmendmentRequestedByUserName({
                amendment: content,
              })}
            </div>
            <div className="flex justify-between mb-1 text-xs">
              Requested Date: {renderDefaultDate(content.requestedDate)}
            </div>
            <div className="flex justify-between mb-1 text-xs">
              {getConditionalResponse({
                condition: [REOCCURRING_AMENDMENT_TYPE_ID].includes(
                  content.amendmentType
                ),

                resp1: (
                  <div>
                    {getConditionalResponse({
                      condition: content?.recurringExpiryDate,
                      resp1: "End Date: ",
                      resp2: "Maximum Amount: ",
                    })}
                    {renderReoccurringEndDate(content)}
                  </div>
                ),

                resp2: <React.Fragment />,
              })}
            </div>
            <If
              condition={content.assignTo}
              then={
                <div className="flex justify-between mb-1 text-xs mt-3">
                  Applies To: {getAssignedToUsersText({ content })}
                </div>
              }
            />

            <div>
              <If
                condition={[
                  REOCCURRING_AMENDMENT_TYPE_ID,
                  PERMANENT_AMENDMENT_TYPE_ID,
                ].includes(content.amendmentTypeId)}
                then={
                  <KeypayStatusBadge
                    statusInPPSID={getAmendmentStatusInPPSId(content)}
                  />
                }
                else={
                  <AmendmentStatusBadge statusID={getAmendmentStatusId(content)} />
                }
              />
            </div>
          </div>
          <GridActionComponent
            record={content}
            classname="card-item mt-4 mr-4 position-absolute-top position-absolute-top-right"
            buttonClassName="card-prop animated fadeIn"
            claims={claims}
            handleAction={(options: any) =>
              [
                "adhocAmendment",
                "reoccurringAmendment",
                "generalAmendment",
              ].includes(amendmentType)
                ? handleAmendmentActions({ ...options, data: content })
                : handleAction({ ...options, data: content, record: content })
            }
            viewComponentNameGrid={component}
            editComponentNameGrid=""
            // {
            //   getGeneralAmendmentEditComponentName({
            //   content,
            //   editComponent: editComponent,
            // })}
            deleteComponentNameGrid={
              showDeleteGeneralAmendment({ content })
                ? getGeneralAmendmentDeleteComponentName({
                    content,
                    deleteComponent: deleteComponent,
                  })
                : ""
            }
            resubmitComponentNameGrid={getGeneralAmendmentResubmitClaimName({
              content,
              payrun,
              generalAmendmentView,
            })}
            appliedInKeypay={getGeneralAmendmentApplyInKeypayClaimName({
              content,
              generalAmendmentView,
              amendmentType,
            })}
            requestCancel={getGeneralAmendmentRequestCancelClaimName({
              content,
              generalAmendmentView,
              amendmentType,
            })}
            applyCancel={getGeneralAmendmentApplyCancelClaimName({
              content,
              generalAmendmentView,
            })}
            applyEditedInKeypay={getGeneralAmendmentApplyEditedClaimName({
              content,
              generalAmendmentView,
            })}
          />
        </React.Fragment>
      }
    />
  </Card>
);

export default AmendmentDisplayCard;
