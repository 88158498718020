import { Button, ButtonType, ButtonVariant } from '@xemplo/button';
import { PAYRUN_COMPONENTS } from '@xemplo/gp-constants';
import { Amendment, AmendmentStatusIds, AmendmentTypes } from '@xemplo/gp-types';
import { DawnTrash16 } from '@xemplo/icons';
import { Prompt } from '@xemplo/prompts';
import { RoleRenderer } from '@xemplo/role-renderer';
import { AuthorisationModule } from '@xemplo/role-types';
import { useRoleAuthorisation } from '@xemplo/roles-query';

import { useRemoveAmendmentPrompt } from './use-remove-amendment';

export interface RemoveAmendmentButtonProps {
  onSuccess?: () => void;
  amendment: Amendment;
}

export const RemoveAmendmentButtonTestId = {
  RemoveAmendmentButton: 'remove-amendment-button',
  RemoveAmendmentPrompt: 'remove-amendment-prompt',
};
export const RemoveAmendmentButton = (props: RemoveAmendmentButtonProps) => {
  const { onSuccess, amendment } = props;
  const { componentDisabled } = useRoleAuthorisation(AuthorisationModule.payrun);

  const { promptProps, promptVisible, setPromptVisible } = useRemoveAmendmentPrompt({
    amendmentId: amendment.amendmentId,
    onSuccess: () => onSuccess?.(),
  });

  return (
    <RoleRenderer
      module={AuthorisationModule.payrun}
      component={PAYRUN_COMPONENTS.AMENDMENT_DELETE}
      additionalRules={[() => amendmentCanBeRemoved(amendment)]}
    >
      <div>
        <Button
          testId={RemoveAmendmentButtonTestId.RemoveAmendmentButton}
          ariaLabel="delete-amendment"
          type={ButtonType.Tertiary}
          icon={<DawnTrash16 />}
          variant={ButtonVariant.Error}
          onClick={() => setPromptVisible(true)}
          disabled={componentDisabled(PAYRUN_COMPONENTS.AMENDMENT_DELETE)}
        >
          Remove
        </Button>
        <Prompt
          data-testid={RemoveAmendmentButtonTestId.RemoveAmendmentPrompt}
          {...promptProps}
          open={promptVisible}
          onCloseCallback={() => setPromptVisible(false)}
        />
      </div>
    </RoleRenderer>
  );
};

export const amendmentCanBeRemoved = (amendment: Amendment) => {
  const amendmentStatusId = amendment.amendmentStatusId || amendment.amendmentStatusID;
  if (amendment.amendmentTypeId === AmendmentTypes.PAYRUN)
    return (
      amendmentStatusId === AmendmentStatusIds.CREATED ||
      amendmentStatusId === AmendmentStatusIds.DRAFT ||
      amendmentStatusId === AmendmentStatusIds.UPDATED
    );
  return (
    amendmentStatusId === AmendmentStatusIds.CREATED ||
    amendmentStatusId === AmendmentStatusIds.REJECTED ||
    amendmentStatusId === AmendmentStatusIds.UPDATED
  );
};
