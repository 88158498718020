//@ts-nocheck
import React, { useState } from "react";
import { Button, Card, message } from "antd";
import _ from "lodash";
import { currencyFormater, mustBeArray } from "../../../libs/utilities";
import { defaultCurrencyList } from "../../../constants/currency";
import { PercentageOutlined, SyncOutlined } from "@ant-design/icons";
import EditLinkButton from "../../../Common/ClaimComponents/Button/EditLinkButton";
import {
  syncEmployeesFromKeypay,
  syncDeductionCategoriesFromKeypay,
  syncPayCategoriesFromKeypay,
} from "../../actions/businessunits";
import { connect } from "react-redux";
import { getMatchParams } from "../../../libs/utilities";

const SYNC_TYPES: Arrray<string> = [
  "All Employee Data",
  "Pay Categories",
  "Deduction Categories",
];
const ServiceDetails = (props: any) => {
  const {
    organization,
    handleEdit,
    claims,
    claimComponent,
    hasNoComponents,
    match,
  } = props;
  const [isSyncLoading, setIsSyncLoading] = useState(false);

  let convertFrom = _.find(
    mustBeArray(defaultCurrencyList),
    (o) => o.id === Number(organization?.convertFrom)
  );
  let convertTo = _.find(
    mustBeArray(defaultCurrencyList),
    (o) => o.id === Number(organization?.convertTo)
  );

  const businessunitid = getMatchParams({
    match,
    field: "businessunitid",
  });

  const handleSync = async () => {
    setIsSyncLoading(true);

    const response = await Promise.all([
      props.syncEmployeesFromKeypay(businessunitid),
      props.syncPayCategoriesFromKeypay(businessunitid),
      props.syncDeductionCategoriesFromKeypay(businessunitid),
    ]);
    setIsSyncLoading(false);
    let statusCount = response.filter((o) => o.status).length;

    switch (statusCount) {
      case 0:
        message.error(
          "All Employee Data, Pay Categories and Deduction Categories syncing have failed."
        );
        break;
      case 1:
        // Fined the index of successful request
        let successIndex = response.findIndex((o) => o.status);
        message.success(
          `${SYNC_TYPES[successIndex]} has been successfully synced.`
        );
        // Remove this index elemented from the sync types
        let failedTypes = [...SYNC_TYPES].splice(successIndex, 1);
        message.error(
          `${failedTypes?.[0]} and ${failedTypes?.[1]} syncing have failed.`
        );
        break;
      case 2:
        // Fined the index of failed request
        let failedIndex = response.findIndex((o) => !o.status);
        message.error(`${SYNC_TYPES[failedIndex]} syncing has failed.`);
        // Remove this index elemented from the sync types
        let successTypes = [...SYNC_TYPES].splice(failedIndex, 1);
        message.success(
          `${failedTypes?.[0]} and ${successTypes?.[1]} have been successfully synced.`
        );
        break;
      case 3:
        message.success(
          "All Employee Data, Pay Categories and Deduction Categories have been successfully synced."
        );
        break;
      default:
        break;
    }
  };
  return (
    organization && (
      <Card
        className="card customized-card-header card-body-overflow-hidden"
        title={
          <div className="flex items-center">
            <PercentageOutlined className="mr-2 text-2xl color-disabled opacity-60" />
            <b>Service Details</b>
          </div>
        }
        extra={
          <EditLinkButton
            onClick={handleEdit}
            name={claimComponent}
            claims={claims}
            hasNoComponents={hasNoComponents}
            className="bg-default color-dark"
          />
        }
      >
        <div className="bg-default p-4 rounded-sm">
          {
            <div className="profile profile-pos-servicecountry">
              <p>
                <strong>Service Country</strong>
              </p>
              {organization.country}
            </div>
          }
          {
            <div className="profile profile-pos-bsp">
              <p>
                <strong>Billing Service Provider</strong>
              </p>
              {organization.billingServiceProviderName}
            </div>
          }

          {
            <div className="profile profile-pos-bsp">
              <p>
                <strong>External Business Unit ID</strong>
              </p>
              {organization.externalBusinessUnitId}
            </div>
          }
          {
            <div className="profile profile-pos-bsp">
              <p>
                <strong>Currency Conversion</strong>
              </p>
              {`${convertFrom && convertFrom.code} to ${
                convertTo && convertTo.code
              }`}
            </div>
          }
          {
            <div className="profile profile-pos-bsp">
              <p>
                <strong>Fixed Conversion Rate</strong>
              </p>
              {currencyFormater(
                organization.fixedConversionRate,
                organization.addressCountryName
              )}
            </div>
          }
          {
            <div className="profile profile-pos-bsp">
              <p>
                <strong>Percentage Conversion Rate</strong>
              </p>
              {`${organization.percentConversionRate}%`}
            </div>
          }
          {
            <div className="profile profile-pos-bsp flex justify-between items-end">
              <span>
                <p>
                  <strong>Payroll Connection</strong>
                </p>
                {`${organization?.payrollSystemConnection}`}
              </span>
              <Button
                onClick={handleSync}
                className="rounded-lg ml-4 bg-white px-4 button-link "
                // size="small"
                loading={isSyncLoading}
              >
                <SyncOutlined />
                Sync
              </Button>
            </div>
          }
        </div>
      </Card>
    )
  );
};
const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  syncEmployeesFromKeypay,
  syncDeductionCategoriesFromKeypay,
  syncPayCategoriesFromKeypay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
