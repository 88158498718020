import {
  Amendment,
  AmendmentStatusIds,
  AmendmentTypes,
  ChangeTaskTypes,
} from '@xemplo/gp-types';

import { EditButtonTypes } from './edit-button.types';

// Common status arrays
const commonValidStatuses = [AmendmentStatusIds.CREATED, AmendmentStatusIds.UPDATED];
const validAmendmentStatuses = [
  ...commonValidStatuses,
  AmendmentStatusIds.SUBMITTED,
  AmendmentStatusIds.RESUBMITTED,
  AmendmentStatusIds.DRAFT,
];
//Rejected amendments can only be edited if they are payrun amendments
const validPayrunStatuses = [...validAmendmentStatuses, AmendmentStatusIds.REJECTED];

const EditButtonConfig = {
  [ChangeTaskTypes.TERMINATION]: {
    button: EditButtonTypes.termination,
    isValid: (amendment: Amendment) => {
      const amendmentStatusId =
        amendment.amendmentStatusId ?? amendment.amendmentStatusID;
      return amendmentStatusId ? commonValidStatuses.includes(amendmentStatusId) : false;
    },
  },
  [ChangeTaskTypes.PAY_INSTRUCTION]: {
    button: EditButtonTypes.payInstruction,
    isValid: (amendment: Amendment) => {
      const amendmentStatusId =
        amendment.amendmentStatusId ?? amendment.amendmentStatusID!;
      return amendmentStatusId ? validPayrunStatuses.includes(amendmentStatusId) : false;
    },
  },
  [ChangeTaskTypes.ALLOWANCE]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) => {
      const amendmentStatusId =
        amendment.amendmentStatusId ?? amendment.amendmentStatusID;
      if (amendmentStatusId === undefined) {
        return false;
      }
      return amendment.amendmentTypeId === AmendmentTypes.PAYRUN
        ? validPayrunStatuses.includes(amendmentStatusId)
        : validAmendmentStatuses.includes(amendmentStatusId);
    },
  },
  [ChangeTaskTypes.DEDUCTION]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) => {
      const amendmentStatusId =
        amendment.amendmentStatusId ?? amendment.amendmentStatusID;

      if (amendmentStatusId === undefined) {
        return false;
      }
      return amendment.amendmentTypeId === AmendmentTypes.PAYRUN
        ? validPayrunStatuses.includes(amendmentStatusId)
        : validAmendmentStatuses.includes(amendmentStatusId);
    },
  },
  [ChangeTaskTypes.SALARY]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) => {
      const amendmentStatusId =
        amendment.amendmentStatusId ?? amendment.amendmentStatusID;

      if (amendmentStatusId === undefined) {
        return false;
      }
      return amendment.amendmentTypeId === AmendmentTypes.PAYRUN
        ? validPayrunStatuses.includes(amendmentStatusId)
        : validAmendmentStatuses.includes(amendmentStatusId);
    },
  },
};

export const getEditButtonType = (amendment: Amendment) => {
  const amendmentTaskTypeId = (amendment.amendmentTaskTypeId ??
    amendment.amendmentTaskTypeID)!;
  const config = EditButtonConfig[amendmentTaskTypeId];
  if (config?.isValid(amendment)) {
    return config.button;
  }

  return null;
};
