import { useCallback, useState } from 'react';
import { PaginationState, RowSelectionState, Updater } from '@tanstack/react-table';

import { DEFAULT_PAGINATION } from './server-side-state.constants';
import { ServerSideRowSelectionState } from './server-side-state.types';

/**
 * This hook is used to manage the state of a table that is using server side paging.
 * It includes the row selection state and the pagination state.
 * It provides a functionality similar to what gmail does.
 */
export const useServerSideState = (defaultPagination?: PaginationState) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [pagination, setPagination] = useState<PaginationState>(
    defaultPagination ?? DEFAULT_PAGINATION
  );

  const resetPagination = () => {
    setPagination(defaultPagination ?? DEFAULT_PAGINATION);
  };

  const [overallRowSelection, setOverallRowSelection] =
    useState<ServerSideRowSelectionState>({});

  /**
   * Updates the row selection state and stores it in the overallRowSelection state.
   * This is to avoid losing the selection when navigating to a different page.
   */
  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const state = typeof updater === 'function' ? updater(rowSelection) : updater;
      setRowSelection(state);
      setOverallRowSelection((prev) => ({
        ...prev,
        [pagination.pageIndex]: {
          pagination,
          rowSelection: state,
        },
      }));
    },
    [pagination, rowSelection]
  );

  /**
   * Updates the pagination state and restores the row selection state if it exists.
   * If it doesn't exist, it creates an empty row selection state.
   * This is to avoid having selected rows when navigating to a different page.
   * It also restore the selection when a previously visited page is visited again and contains
   * row selection state.
   */
  const onPaginationChange = useCallback(
    (updater: Updater<PaginationState>) => {
      const state = typeof updater === 'function' ? updater(pagination) : updater;
      setPagination(state);
      const existingSelection = overallRowSelection[state.pageIndex];
      if (existingSelection) {
        const { rowSelection } = existingSelection;
        setRowSelection(rowSelection);
      } else {
        const pageItems = Array.from({ length: state.pageSize });
        setRowSelection(Object.fromEntries(pageItems.map((_, index) => [index, false])));
      }
    },
    [overallRowSelection, pagination]
  );

  return {
    onRowSelectionChange,
    onPaginationChange,
    rowSelection,
    pagination,
    resetPagination,
  };
};
