import React from "react";
import { displayDates, isUserPSPManager } from "../../../libs";
import {
  getAssignedCompaniesAndBusinessUnits,
  getSortOrder,
  getSortOrderRequestValue,
} from "../../../libs/utilities";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import { tableColumnGenerator } from "../../../libs/table";
import SwitchButton from "../../../Common/ClaimComponents/Filter/SwitchButton";
import { sortInfoType } from "../../../constants/paramsTypes";
import { useAppSelector } from "../../../hooks";
import { RootState } from "../../../store";
import { PayrollManagerUser } from "../../../types/businessUnit";

interface AssignPayrollManagerProps {
  handlePayrollUserTableChange: (e: any) => void;
  handleAssign: (e: any) => void;
  handleChangePrimary: (e: any) => void;
  loading: boolean;
  data: PayrollManagerUser[];
  sortInfo: sortInfoType | undefined;
  currentPage?: number;
}
const AssignPayrollManagerList: React.FC<AssignPayrollManagerProps> = ({
  handlePayrollUserTableChange,
  loading,
  data,
  handleAssign,
  handleChangePrimary,
  sortInfo,
  currentPage,
}) => {
  const member = useAppSelector((state: RootState) => state.member);

  const columns: Array<unknown> = isUserPSPManager({ member })
    ? [
        {
          title: "Name",
          dataIndex: "payrollName",
          dataType: "nameAndImage",
          sorter: false,
          imageParam: "profilePhoto",
        },
        {
          title: "Primary",
          dataIndex: "primary",
          sorter: false,
          align: "center",
          width: "20%",
          render: (record: any, userDetail: PayrollManagerUser) => {
            return (
              <SwitchButton
                name=""
                disabled={true}
                checked={userDetail.isPrimary}
                displaySwitch={true}
              />
            );
          },
        },
      ]
    : [
        {
          title: "Name",
          dataIndex: "payrollName",
          dataType: "nameAndImage",
          sorter: true,
          sortOrder: getSortOrder({ sortInfo, field: "name" }),
          width: "15%",
          imageParam: "profilePhoto",
        },
        {
          title: "Companies / Business Units",
          dataIndex: "companiesBusinessUnits",
          width: "8%",
          render: (record: any, userDetail: PayrollManagerUser) => {
            return (
              <div className="mt-3 bold">{`${getAssignedCompaniesAndBusinessUnits(
                { data: userDetail }
              )}`}</div>
            );
          },
        },
        {
          title: "Pay Schedules",
          dataIndex: "payScheduleCount",
          width: "7%",
        },
        {
          title: "Email",
          dataIndex: "emailAddress",
          sorter: true,
          sortOrder: getSortOrder({ sortInfo, field: "emailaddress" }),
          width: "18%",
        },
        {
          title: "Last Logout",
          dataIndex: "lastLogout",
          sorter: false,
          sortOrder: getSortOrder({ sortInfo, field: "lastLogout" }),
          width: "8%",
          render: (data: undefined | string) => {
            return displayDates({ value: data, format: undefined });
          },
        },
        {
          title: "Assign / Unassign",
          dataIndex: "assignUnassign",
          width: "6%",
          render: (record: any, userDetail: PayrollManagerUser) => {
            return (
              <SwitchButton
                name=""
                checked={userDetail.isUserAssignedAsPayrollManager}
                handleChangeSwitch={(e) =>
                  handleAssign({ e, record: userDetail })
                }
                displaySwitch={true}
              />
            );
          },
        },
        {
          title: "Primary",
          dataIndex: "primary",
          sorter: false,
          width: "8%",
          render: (record: any, userDetail: PayrollManagerUser) => {
            return (
              <SwitchButton
                name=""
                checked={userDetail.isPrimary}
                disabled={!userDetail.isUserAssignedAsPayrollManager}
                handleChangeSwitch={(e) =>
                  handleChangePrimary({ e, record: userDetail })
                }
                displaySwitch={true}
              />
            );
          },
        },
      ];

  const getSortInfoRequestField = (field: string) => {
    switch (field) {
      case "emailAddress":
        return "emailaddress";
      case "payrollName":
        return "name";
      default:
        return field;
    }
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    const options = {
      order: getSortOrderRequestValue({ sorter }),
      field: getSortInfoRequestField(sorter?.field),
      currentPage: pagination.current,
      action: extra.action,
    };
    handlePayrollUserTableChange(options);
  };

  return (
    <SimpleTableContainer
      columns={tableColumnGenerator(columns)}
      size={"middle"}
      dataSource={data}
      pagination={{ pageSize: 10, current: currentPage }}
      loading={loading}
      onChange={handleTableChange}
      rowIdParam={isUserPSPManager({ member }) ? "userId" : "id"}
      dashboardView={false}
    />
  );
};

export default AssignPayrollManagerList;
