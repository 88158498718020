import { useCallback, useRef, useState } from 'react';
import { flexRender, Row, RowData } from '@tanstack/react-table';
import classNames from 'classnames';
import styled from 'styled-components';

import { hasCustomColumnSize, kebabCase } from '../table.helper';
import { CellWrapper, ExtraColumn } from '../table.style';
import { TableProps } from '../table.types';

import { BodyProps } from './body.types';
import { useVirtualRows } from './use-virtual-rows';

const StyledCell = styled.td<{ $width?: number }>`
  ${({ $width }) => ($width ? `width: ${$width}px;` : null)};
  ${ExtraColumn};
`;

const StyledPaddingCell = styled.td<{ $height?: number }>`
  height: ${({ $height }) => $height}px;
`;

export const TableBodyTestId = {
  tbody: 'table-body',
  row: (id: string) => `table-row-${id}`,
};

export function TableBody<TData extends RowData>(props: BodyProps<TData>) {
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const [activeRowId, setActiveRowId] = useState<string | null>(null);
  const [hoverRowId, setHoverRowId] = useState<string | null>(null);

  const { enableExpanding, enableRowSelection, onBodyRowClick } = props.table
    .options as TableProps<TData>;
  const { rows, paddingTop, paddingBottom, virtualRows } = useVirtualRows({
    ...props,
    bodyRef,
  });

  const handleRowClick = useCallback(
    (e: React.MouseEvent, row: Row<TData>) => {
      if ((e.target as HTMLElement).closest('table') === null) return;
      setActiveRowId(activeRowId === row.id ? null : row.id);
      onBodyRowClick?.(e, row);
    },
    [activeRowId, onBodyRowClick]
  );

  const handleRowEnter = useCallback(
    (e: React.MouseEvent, row: Row<TData>) => {
      setHoverRowId(hoverRowId === row.id ? null : row.id);
    },
    [hoverRowId]
  );

  const handleRowLeave = useCallback(() => setHoverRowId(null), []);

  return (
    <tbody ref={bodyRef} data-testid={TableBodyTestId.tbody}>
      <Padding padding={paddingTop} />
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const rowClassName = classNames({
          hover: row.id === hoverRowId,
          active: row.id === activeRowId,
          selected: row.getIsSelected(),
          hasOnClick: !!onBodyRowClick,
        });

        return (
          <tr
            key={row.id}
            className={rowClassName}
            onMouseEnter={(e) => handleRowEnter(e, row)}
            onMouseLeave={handleRowLeave}
            onClick={(e) => handleRowClick(e, row)}
            data-testid={TableBodyTestId.row(row.id)}
            data-chromatic="ignore"
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <StyledCell
                  className={kebabCase(cell.column.columnDef.id ?? '')}
                  key={cell.id}
                  width={
                    hasCustomColumnSize(cell.column.id, props.table.options.columns)
                      ? cell.column.getSize()
                      : undefined
                  }
                  data-selectable={!!enableRowSelection}
                  data-expandable={
                    enableExpanding && (row.getCanExpand() || row.depth > 0)
                  }
                >
                  <CellWrapper className="table-cell-wrapper">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </CellWrapper>
                </StyledCell>
              );
            })}
          </tr>
        );
      })}
      <Padding padding={paddingBottom} />
    </tbody>
  );
}

type PaddingProps = { padding: number };
/**
 * This component is needed to avoid some weird flickering when scrolling large number of items
 * Solution extracted from @tanstack/react-table examples repository
 */
function Padding({ padding }: PaddingProps) {
  if (padding < 1) return null;
  return (
    <tr>
      <StyledPaddingCell $height={padding} />
    </tr>
  );
}
