import {
  KEYPAY_AMENDMENT_CREATED,
  KEYPAY_AMENDMENT_IN_PROGRESS,
  KEYPAY_AMENDMENT_PARTIAL_SUCCESS,
  KEYPAY_AMENDMENT_SUCCESS,
  KEYPAY_AMENDMENT_FAILED,
  AMENDMENTS_APPLIED_TO_PAYROLL,
  AMENDMENT_APPROVED_STATUS,
  PAYRUN_AMENDMENT_TYPE_ID,
  AMENDMENT_DECLINED_STATUS,
  MAX_NUMBER_VALUE,
} from "../constants";
import { AmendmentType } from "../types";
import { isPrecise, mustBeArray } from "./utilities";
import { getAmendmentStatusId } from "./generalAmendment";

type AmendmentKeypayStatusBadge = {
  text?: string;
  appliedClass?: string;
};

export const getKeypayAmendmentStatus = (amendmentKeypayStatus: number) => {
  let badgeDetails: AmendmentKeypayStatusBadge = {};
  switch (amendmentKeypayStatus) {
    case KEYPAY_AMENDMENT_CREATED:
      badgeDetails = {
        text: "",
        appliedClass: "",
      };
      break;
    case KEYPAY_AMENDMENT_IN_PROGRESS:
      badgeDetails = {
        text: "In Progress",
        appliedClass: "bg-badge-primary",
      };
      break;
    case KEYPAY_AMENDMENT_PARTIAL_SUCCESS:
      badgeDetails = {
        text: "Partially Completed",
        appliedClass: "bg-badge-warning",
      };
      break;
    case KEYPAY_AMENDMENT_SUCCESS:
      badgeDetails = {
        text: "Completed",
        appliedClass: "bg-badge-success",
      };
      break;
    case KEYPAY_AMENDMENT_FAILED:
      badgeDetails = {
        text: "Failed",
        appliedClass: "bg-badge-danger",
      };
      break;
    default:
      break;
  }

  return badgeDetails;
};

export const isAmendmentAutomated = ({ amendment }: any) => {
  return amendment?.isIntegrated;
};

export const isAmendmentAppliedToPayroll = ({ amendment }: any) => {
  return AMENDMENTS_APPLIED_TO_PAYROLL.includes(
    amendment?.amendmentKeypayStatusId
  );
};

export const allAmendmentsAppliedToPayroll = ({
  list,
}: {
  list: Array<any>;
}) => {
  return !list.some(
    (o) =>
      isAmendmentAutomated({ amendment: o }) && //check if isIntegrated is true or false for the amendment
      AMENDMENT_APPROVED_STATUS.includes(getAmendmentStatusId(o)) && // Just the approved amendments will be applied to keypay
      !isAmendmentAppliedToPayroll({ amendment: o })
  );
};

export const amendmentIsAssociatedWithPayrun = ({ amendment }: any) => {
  if (amendment?.amendmentTypeId === PAYRUN_AMENDMENT_TYPE_ID) return true;
  return false;
};

export const amendmentHasTemplateUrl = ({ list, id }: any) => {
  let selectedChangeType = mustBeArray(list).find((o) => o.id === id);
  return selectedChangeType?.templateUrl ?? null;
};

// Returns true if amendment has been declined/rejected (amdendmentStatusID == 4)
export const amendmentIsRejected = ({ amendment }: any) => {
  return getAmendmentStatusId(amendment) === AMENDMENT_DECLINED_STATUS;
};

export const isValidRateAndUnit = (rate: number, unit: number) => {
  if (
    isNaN(rate) ||
    rate < 0 ||
    isNaN(unit) ||
    unit <= 0 ||
    rate > MAX_NUMBER_VALUE ||
    unit > MAX_NUMBER_VALUE ||
    !isPrecise(rate, 2) ||
    !isPrecise(unit, 2)
  )
    return false;
  if (rate === 0 && unit === 0) return false; //Not mandatory to enter both
  return true;
};

export const amendmentsAreIntegratedToPayroll = (list: AmendmentType[]) => {
  return list.some((o: AmendmentType) => o.isIntegrated);
};

/** Displays the user formatted versions of Tax Caclulation Methods (eg. B2 -> B(ii)) */
export const displayTaxCalculationMethod = (method?: string | null) => {
  if (!method) return null;
  switch (method) {
    case "NotApplicable":
      return "Not Applicable";
    case "B2":
      return "B(ii)";
    default:
      return method;
  }
};
