//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listPayrun,
  generateAdhoc,
  listAmendmentTypes,
  listPayrunAmendments,
  listPayrunCompleted,
  listPayschedulePayruns,
  downloadInvoice,
  clearApprovedPayrun,
  earningLinesLookup,
  payCategoryLookUp,
  listArchievedEmployees,
  getPayrun,
  listInvoices,
  payrunHandler,
} from "../actions/payrun";
import {
  fetchPayrollConnectionName,
  fetchExternalBusinessunit,
  fetchExternalPayschedules,
  configurePayscheduleConnection,
  confirmPayrollConnection,
} from "../actions/payrollprocessor";
import {
  companyLookup,
  listCompanyBusinessUnits,
  getCompany,
} from "../actions/companies";
import {
  getBusinessUnit,
  listTaxFees,
  listClientsEmployees,
} from "../actions/businessunits";
import {
  timezoneLookup,
  populateStates,
  cdnLookUpHandler,
} from "../actions/lookup";
import PayRunStatus from "../../Common/payrunStatus";
import axios from "axios";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { getOptionalParameter } from "../../libs/utilities";
import { withRouter } from "../../hooks";

class Payrun extends SwiftContainer {
  listInvoiceSupervisor: any;
  listPayrunsSupervisor: any;
  listPayrunsforPayrollAdmin: any;
  signal = axios.CancelToken.source();

  render() {
    const { Layout, userMode } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        businessUnit={this.props.businessUnit}
        clearApprovedPayrun={this.props.clearApprovedPayrun}
        businessunitDetails={this.props.businessunitDetails}
        listTaxFees={this.props.listTaxFees}
        listClientsEmployees={this.props.listClientsEmployees}
        listAmendmentTypes={this.props.listAmendmentTypes}
        userMode={this.props.userMode}
        downloadInvoice={this.props.downloadInvoice}
        cancelToken={this.signal.token}
        timezoneLookup={timezoneLookup}
        companyLookup={this.props.companyLookup}
        payrunAmendments={this.props.payrunAmendments}
        payrunAmendmentsCount={this.props.payrunAmendmentsCount}
        payrunApproval={this.props.payrunApproval}
        payrunApprovalCount={this.props.payrunApprovalCount}
        payrunRejected={this.props.payrunRejected}
        payrunRejectedCount={this.props.payrunRejectedCount}
        payrunTotal={this.props.payrunTotal}
        payrunTotalCount={this.props.payrunTotalCount}
        payrunCompleted={this.props.payrunCompleted}
        payrunCompletedCount={this.props.payrunCompletedCount}
        payrunPrepare={this.props.payrunPrepare}
        payrunProcess={this.props.payrunProcess}
        payrunPrepareCount={this.props.payrunPrepareCount}
        payrunProcessCount={this.props.payrunProcessCount}
        payrunInvoicePaid={this.props.payrunInvoicePaid}
        payrunInvoiceUnpaid={this.props.payrunInvoiceUnpaid}
        payrunInvoicePaidCount={this.props.payrunInvoicePaidCount}
        payrunInvoiceUnpaidCount={this.props.payrunInvoiceUnpaidCount}
        loading={this.state.loading}
        signal={this.signal}
        // listPayrunExecutive={this.listPayrunExecutive}
        listPayrunsforPayrollAdmin={this.listPayrunsforPayrollAdmin}
        listPayrunsSupervisor={this.listPayrunsSupervisor}
        listInvoiceSupervisor={this.listInvoiceSupervisor}
        listCompanyBusinessUnits={(data: any) =>
          this.executeAction(this.props.listCompanyBusinessUnits, data)
        }
        listPayrun={(data: any) =>
          this.executeAction(this.props.listPayrun, data)
        }
        listPayrunDraft={({ options }: any) => {
          return this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "draft",
                StatusID: PayRunStatus.getStatusID({
                  status: "draft",
                  userMode,
                }),
              },
            },
          });
        }}
        listPayrunAmendments={({ options }: any) => {
          return this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              filter: {
                ...(options && options.filter ? options.filter : {}),
                type: "amendments",
                StatusID: PayRunStatus.getStatusID({
                  status: "amendments",
                  userMode,
                }),
              },
            },
          });
        }}
        listPayrunPrepare={({ options }: any) => {
          return this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "prepare",
                StatusID: PayRunStatus.getStatusID({
                  status: "prepare",
                  userMode,
                }),
              },
            },
          });
        }}
        listPayrunApproval={({ options }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "approval",
                StatusID: PayRunStatus.getStatusID({
                  status: "approval",
                  userMode,
                }),
              },
            },
          })
        }
        listPayrunCompleted={({ options }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "completed",
                StatusID: PayRunStatus.getStatusID({
                  status: "completed",
                  userMode,
                }),
              },
            },
          })
        }
        listPayrunProcess={({ options }: any) => {
          return this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "process",
                StatusID: PayRunStatus.getStatusID({
                  status: "process",
                  userMode,
                }),
              },
            },
          });
        }}
        listPayschedulePayruns={(data: any) =>
          this.executeAction(this.props.listPayschedulePayruns, data)
        }
        listPayrunTotal={({ options, targetEndpoint }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: { ...(options.q ? options.q : {}), type: "total" },
            },
            targetEndpoint,
          })
        }
        listPaidInvoicePayrun={({ options }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "paid",
                StatusID: PayRunStatus.getPaidStatusID(),
              },
            },
          })
        }
        listUnpaidInvoicePayrun={({ options }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "unpaid",
                StatusID: PayRunStatus.getStatusID({
                  status: "invoiceUnpaid",
                  userMode,
                }),
              },
            },
          })
        }
        getBusinessUnit={(data: any) =>
          this.executeAction(this.props.getBusinessUnit, data)
        }
        generateAdhoc={(data: any) =>
          this.executeAction(this.props.generateAdhoc, data)
        }
        fetchPayrollConnectionName={(data: any) =>
          this.executeAction(this.props.fetchPayrollConnectionName, data)
        }
        fetchExternalBusinessunit={(data: any) =>
          this.executeAction(this.props.fetchExternalBusinessunit, data)
        }
        fetchExternalPayschedules={(data: any) =>
          this.executeAction(this.props.fetchExternalPayschedules, data)
        }
        configurePayscheduleConnection={(data: any) =>
          this.executeAction(this.props.configurePayscheduleConnection, data)
        }
        pageClaims={pageClaims}
        member={this.props.member}
        populateStates={this.props.populateStates}
        earningLinesLookup={this.props.earningLinesLookup}
        confirmPayrollConnection={this.props.confirmPayrollConnection}
        payCategoryLookUp={this.props.payCategoryLookUp}
        listArchievedEmployees={this.props.listArchievedEmployees}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        match={{ params: this.props.router.params }}
        getPayrun={this.props.getPayrun}
        listInvoices={this.props.listInvoices}
        getCompany={this.props.getCompany}
        payrunHandler={this.props.payrunHandler}
        listAmendments={this.props.listPayrunAmendments}
        cdnLookUpHandler={this.props.cdnLookUpHandler}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payrunPrepare: getOptionalParameter({
    value1: state.payrun.prepare.list,
    value2: {},
  }),
  payrunPrepareCount: getOptionalParameter({
    value1: state.payrun.prepare.count,
    value2: 0,
  }),
  payrunTotal: getOptionalParameter({
    value1: state.payrun.total.list,
    value2: {},
  }),
  payrunTotalCount: getOptionalParameter({
    value1: state.payrun.total.count,
    value2: 0,
  }),
  payrunProcess: getOptionalParameter({
    value1: state.payrun.process.list,
    value2: [],
  }),
  payrunProcessCount: getOptionalParameter({
    value1: state.payrun.process.count,
    value2: 0,
  }),
  payrunAmendments: getOptionalParameter({
    value1: state.payrun.amendment.list,
    value2: [],
  }),
  payrunAmendmentsCount: getOptionalParameter({
    value1: state.payrun.amendment.count,
    value2: 0,
  }),
  payrunApproval: getOptionalParameter({
    value1: state.payrun.approval.list,
    value2: [],
  }),
  payrunRejected: state.payrun.rejected.list || [],
  payrunRejectedCount: state.payrun.rejected.count || 0,
  payrunApprovalCount: state.payrun.approval.count || 0,
  payrunCompleted: state.payrun.completed.list || [],
  payrunCompletedCount: state.payrun.completed.count || 0,
  payrunInvoicePaid: state.payrun.paid.list || [],
  payrunInvoicePaidCount: state.payrun.paid.count || 0,
  payrunInvoiceUnpaid: state.payrun.unpaid.list || [],
  payrunInvoiceUnpaidCount: state.payrun.unpaid.count || 0,
  businessunitDetails: state.businessUnit.detail || {},
  userMode: "",
  businessUnit: state.businessUnit && state.businessUnit.detail,
  member: state.member,
});

const mapDispatchToProps = {
  listPayrun,
  listAmendmentTypes,
  listClientsEmployees,
  listPayrunAmendments,
  downloadInvoice,
  listPayrunCompleted,
  listPayschedulePayruns,
  listTaxFees,
  companyLookup,
  listCompanyBusinessUnits,
  timezoneLookup,
  getBusinessUnit,
  generateAdhoc,
  fetchPayrollConnectionName,
  fetchExternalBusinessunit,
  fetchExternalPayschedules,
  configurePayscheduleConnection,
  clearApprovedPayrun,
  populateStates,
  earningLinesLookup,
  confirmPayrollConnection,
  payCategoryLookUp,
  listArchievedEmployees,
  updateHeader,
  updateAuthorizationClaim,
  getPayrun,
  listInvoices,
  getCompany,
  payrunHandler,
  cdnLookUpHandler,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payrun));
