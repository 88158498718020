//@ts-nocheck
import React from "react";
import { Tabs } from "antd";
import PayRunDisplayCard from "../../../components/payrun/PayRunDisplayCard";
import PayrunList from "../../../components/payrun/list";
import {
  getDifferenceInDays,
  mustBeArray,
  findAuthorizationClaims,
  delay,
  errorDisplay,
  updateAuthorizationClaims,
  getUserArea,
  filteredByLabel,
  requestUrlBuilder,
  getCurrentCardGrid,
  checkClaimsAuthorization,
  getDefaultPage,
  getDefaultPerPage,
  getConditionalResponse,
  conditionalParameter,
  errorHandler,
  listHasMoreData,
  getPaginationFromPersonalization,
  getPageSizeFromPersonalization,
  getSortOrderPersonalization,
  getSortColumnFromPersonalization,
  getPerPageFromPersonalization,
  getGridPaginationFromPersonalization,
  getGridRowFromPersonalization,
  getGridColFromPersonalization,
  getGridSortOrderPersonalization,
  getGridSortColumnFromPersonalization,
  getListPaginationTypeFromPersonalization,
  getListSortingTypeFromPersonalization,
  getListIsPinnedFromPersonalization,
  getListIsGridableFromPersonalization,
  trimWhiteSpaces,
  validateUsersAccessRights,
  isComponentVisible,
} from "../../../../libs";
import _ from "lodash";
import {
  MULTI_TAB_ACTIVE_KEY_INDEX,
  LIST_PAGINATION_TYPE_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  SORT_ORDER_INDEX,
  SORT_COLUMN_INDEX,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
  GRID_VIEW_VALUE,
  GRID_SORT_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  GRID_COLUMN_INDEX,
  GRID_ROW_INDEX,
  GRID_PAGE_NUMBER_INDEX,
  SORTING_NOT_REQUIRED,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  TABLE_PERSONALIZATIONS,
  GRID_PERSONALIZATIONS,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  SINGLE_LINE_GRID_VALUE,
  STRING_PERSONALIZATION,
} from "../../../../constants/defaultClaims";
import {
  PAYRUN_GRID_OPTIONS,
  PAYRUN_FIELD_OPTIONS,
} from "../../../../constants/sortingOptions";
import { PAYRUN_TYPES_OPTIONS } from "../../../../constants/options";
import axios from "axios";
import MultiTabContainer from "../../../../Common/ClaimContainers/MultiTabContainer";
import FilterContainer from "../../../../Common/ClaimContainers/FilterContainer";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import ChildTabContainer from "../../../../Common/ClaimContainers/ChildTabContainer";
import SearchIconButton from "../../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../../Common/ClaimComponents/Button/LinkButton";
import TabHeader from "../../../../Common/tabHeader";
import PayRunStatus from "../../../../Common/payrunStatus";
import ScrollablePayrunList from "../../../components/payrun/scrollingPayrunList";
import { If, Loading } from "../../../../Common/ui";
import PayrunSingleLineGridDisplay from "../../../components/payrun/payrunSingleLineGridDisplay";
import { PayRunIconCustom } from "../../../../Common/customicons";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import AmendmentDueMessage from "../../../components/payrun/amendmentDueMessage";
import userRoles from "../../../../constants/userRoles";
import APIHandler from "../../../../constants/apiUrl";
import { ADHOC_PAYRUN_ID } from "../../../../constants/payrun";
import SingleFieldTextBoxFilter from "../../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import AmendmentSummaryList from "../../../components/payrun/Amendments/AmendmentSummaryList";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  PAYRUN_CREATE_SIMPLE_BUTTON,
  PAYRUN_FILTER_CONTAINER,
  PAYRUN_MULTI_TAB_CONTAINER,
} from "../../../../constants";
import { withRouter } from "../../../../hooks";

const TabPane = Tabs.TabPane;

type State = any;

class PayrollProviderPayrun extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      activeKey: "1",
      payrunTypeId: 3,
      options: PAYRUN_TYPES_OPTIONS,
      payrunTypes: [
        {
          type: "draft",
          updateClaimName: "payrunDraftStatusGeneralTable",
          gridClaimName: "payrunDraftStatusGeneralGrid",
          actionName: this.props.listPayrunDraft,
        },
        {
          type: "amendment",
          updateClaimName: "payrunAmendmentStatusGeneralTable",
          gridClaimName: "payrunAmendmentStatusGeneralGrid",
          actionName: this.props.listPayrunAmendments,
        },
        {
          type: "prepare",
          updateClaimName: "payrunPrepareStatusGeneralTable",
          gridClaimName: "payrunPrepareStatusGeneralGrid",
          actionName: this.props.listPayrunPrepare,
        },
        {
          type: "approval",
          updateClaimName: "payrunApprovalStatusGeneralTable",
          gridClaimName: "payrunApprovalStatusGeneralGrid",
          actionName: this.props.listPayrunApproval,
        },
        {
          type: "process",
          updateClaimName: "payrunProcessStatusGeneralTable",
          gridClaimName: "payrunProcessStatusGeneralGrid",
          actionName: this.props.listPayrunProcess,
        },
        {
          type: "completed",
          updateClaimName: "payrunCompletedStatusGeneralTable",
          gridClaimName: "payrunCompletedStatusGeneralGrid",
          actionName: this.props.listPayrunProcess,
        },
        {
          type: "rejected",
          updateClaimName: "payrunRejectedStatusGeneralTable",
          gridClaimName: "payrunRejectedStatusGeneralGrid",
          actionName: this.props.listPayrun,
        },
      ],
      amendmentPagination: 1,
      amendmentPerPage: 10,
      amendmentTotal: 0,
      amendmentList: [],
      amendmentListLoading: false,
      amendmentAddable: false,
      amendmentGridable: false,
      amendmentGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      amendmentGridView: false,
      amendmentIsPinned: "false",
      amendmentSortingType: "notrequired",
      amendmentHasMoreData: false,
      amendmentSortInfo: {},
      amendmentGridSortOption: PAYRUN_GRID_OPTIONS,
      amendmentSelectedGridSort: undefined,
      amendmentFieldOptions: PAYRUN_FIELD_OPTIONS,
      //prepare
      preparePagination: 1,
      preparePerPage: 10,
      prepareTotal: 0,
      prepareList: [],
      prepareListLoading: false,
      prepareAddable: false,
      prepareGridable: false,
      prepareGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      prepareGridView: false,
      prepareIsPinned: "false",
      prepareSortingType: "notrequired",
      prepareHasMoreData: false,
      prepareSortInfo: {},
      prepareGridSortOption: PAYRUN_GRID_OPTIONS,
      prepareSelectedGridSort: undefined,
      prepareFieldOptions: PAYRUN_FIELD_OPTIONS,
      //approval
      approvalPagination: 1,
      approvalPerPage: 10,
      approvalTotal: 0,
      approvalList: [],
      approvalListLoading: false,
      approvalAddable: false,
      approvalGridable: false,
      approvalGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      approvalGridView: false,
      approvalIsPinned: "false",
      approvalSortingType: "notrequired",
      approvalHasMoreData: false,
      approvalSortInfo: {},
      approvalGridSortOption: PAYRUN_GRID_OPTIONS,
      approvalSelectedGridSort: undefined,
      approvalFieldOptions: PAYRUN_FIELD_OPTIONS,
      // process
      processPagination: 1,
      processPerPage: 10,
      processTotal: 0,
      processList: [],
      processListLoading: false,
      processAddable: false,
      processGridable: false,
      processGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      processGridView: false,
      processIsPinned: "false",
      processSortingType: "notrequired",
      processHasMoreData: false,
      processSortInfo: {},
      processGridSortOption: PAYRUN_GRID_OPTIONS,
      processSelectedGridSort: undefined,
      processFieldOptions: PAYRUN_FIELD_OPTIONS,
      // Completed
      completedPagination: 1,
      completedPerPage: 10,
      completedTotal: 0,
      completedList: [],
      completedListLoading: false,
      completedAddable: false,
      completedGridable: false,
      completedGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      completedGridView: false,
      completedIsPinned: "false",
      completedSortingType: "notrequired",
      completedHasMoreData: false,
      completedSortInfo: {},
      completedGridSortOption: PAYRUN_GRID_OPTIONS,
      completedSelectedGridSort: undefined,
      completedFieldOptions: PAYRUN_FIELD_OPTIONS,
      // rejected
      rejectedPagination: 1,
      rejectedPerPage: 10,
      rejectedTotal: 0,
      rejectedList: [],
      rejectedListLoading: false,
      rejectedAddable: false,
      rejectedGridable: false,
      rejectedGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      rejectedGridView: false,
      rejectedIsPinned: "false",
      rejectedSortingType: "notrequired",
      rejectedHasMoreData: false,
      rejectedSortInfo: {},
      rejectedGridSortOption: PAYRUN_GRID_OPTIONS,
      rejectedSelectedGridSort: undefined,
      rejectedFieldOptions: PAYRUN_FIELD_OPTIONS,
      singleLineGridView: false,
      pageLoad: true,
      listLoading: true,
      totalPayrunList: [],
      isAmendmentDueSoon: false,
      // draft payruns
      draftPagination: 1,
      draftPerPage: 10,
      draftTotal: 0,
      draftList: [],
      draftListLoading: false,
      draftAddable: false,
      draftGridable: false,
      draftGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      draftGridView: false,
      draftIsPinned: "false",
      draftSortingType: "notrequired",
      draftHasMoreData: false,
      draftSortInfo: {},
      draftGridSortOption: PAYRUN_GRID_OPTIONS,
      draftSelectedGridSort: undefined,
      draftFieldOptions: PAYRUN_FIELD_OPTIONS,
      filters: {},
      viewAmendmentSummary: false,
      amendmentSummaryList: [],
      activePayrun: {},
    };
    this.onTabChange = this.onTabChange.bind(this);
    this.getTableSortInfo = this.getTableSortInfo.bind(this);
  }

  componentDidMount() {
    const { dashboardView, pageClaims } = this.props;
    // Multi tab
    const payRunMultiTabContainer = _.find(
      mustBeArray(this.props.pageClaims?.components),
      (o) => o.name === PAYRUN_MULTI_TAB_CONTAINER
    );
    if (dashboardView) {
      switch (this.props.type) {
        case "amendment":
          this.handleAmendmentList({ payRunMultiTabContainer });
          break;
        case "prepare":
          this.handlePrepareList({ payRunMultiTabContainer });
          break;
        case "approval":
          this.handleApprovalList({ payRunMultiTabContainer });
          break;
        case "process":
          this.handleProcessList({ payRunMultiTabContainer });
          break;
        case "completed":
          this.handleCompletedList({ payRunMultiTabContainer });
          break;
        case "rejected":
          this.handleRejectedList({ payRunMultiTabContainer });
          break;
        case "draft":
          this.handleDraftList({ payRunMultiTabContainer });
          break;
        default:
          break;
      }
      this.setState({ pageLoad: false });
    } else {
      //TODO: Replace with requireSecureComponent higher order component
      if (!validateUsersAccessRights({ claim: pageClaims })) return;
      this.props.updateHeader({
        header: {
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayRunList,
          title: "Pay Runs",
          module: "",
          enableBack: false,
          entity: "payrun",
          action: checkClaimsAuthorization({
            component: PAYRUN_CREATE_SIMPLE_BUTTON,
            claims: this.props.pageClaims && this.props.pageClaims.components,
          }),
        },
      });
      this.setState({ pageLoad: false });
      // Filter Container
      const payrunNameTextboxFilter = findAuthorizationClaims({
        claims: this.props.pageClaims && this.props.pageClaims.components,
        name: "payrunNameTextboxFilter",
      });
      this.setState({
        filters: {
          name:
            payrunNameTextboxFilter &&
              payrunNameTextboxFilter.personalization &&
              payrunNameTextboxFilter.personalization.value
              ? payrunNameTextboxFilter.personalization.value
              : "",
        },
      });

      // active key
      const activeKey =
        payRunMultiTabContainer &&
        payRunMultiTabContainer.personalizations &&
        payRunMultiTabContainer.personalizations[MULTI_TAB_ACTIVE_KEY_INDEX] &&
        payRunMultiTabContainer.personalizations[MULTI_TAB_ACTIVE_KEY_INDEX]
          .value;

      if (activeKey) {
        this.setState({ activeKey });
      }

      // Payrun list containers
      delay(200).then(() => {
        this.handleAmendmentList({ payRunMultiTabContainer });
        this.handlePrepareList({ payRunMultiTabContainer });
        this.handleApprovalList({ payRunMultiTabContainer });
        this.handleProcessList({ payRunMultiTabContainer });
        this.handleCompletedList({ payRunMultiTabContainer });
        this.handleRejectedList({ payRunMultiTabContainer });
        this.handleDraftList({ payRunMultiTabContainer });
      });
    }
  }

  handleDraftList = ({ payRunMultiTabContainer }) => {
    const payrunDraftStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({
          data: payRunMultiTabContainer,
          field: "components",
        })
      ),
      name: "payrunDraftStatusListContainer",
    });
    const draftGeneralTable = findAuthorizationClaims({
      claims: conditionalParameter({
        data: payrunDraftStatusListContainer,
        field: "components",
      }),
      name: "payrunDraftStatusGeneralTable",
    });
    const draftGeneralGrid = findAuthorizationClaims({
      claims: conditionalParameter({
        data: payrunDraftStatusListContainer,
        field: "components",
      }),
      name: "payrunDraftStatusGeneralGrid",
    });

    const draftPaginationType =
      payrunDraftStatusListContainer &&
      payrunDraftStatusListContainer.personalizations &&
      payrunDraftStatusListContainer.personalizations[
      LIST_PAGINATION_TYPE_INDEX
      ] &&
      payrunDraftStatusListContainer.personalizations[
        LIST_PAGINATION_TYPE_INDEX
      ].value;
    const draftSortingType =
      payrunDraftStatusListContainer &&
      payrunDraftStatusListContainer.personalizations &&
      payrunDraftStatusListContainer.personalizations[
      LIST_SORTING_REQUIRED_INDEX
      ] &&
      payrunDraftStatusListContainer.personalizations[
        LIST_SORTING_REQUIRED_INDEX
      ].value;
    const draftIsPinned =
      payrunDraftStatusListContainer &&
      payrunDraftStatusListContainer.personalizations &&
      payrunDraftStatusListContainer.personalizations[LIST_PINNABLE_INDEX] &&
      payrunDraftStatusListContainer.personalizations[LIST_PINNABLE_INDEX]
        .value;

    this.setState({
      draftPaginationType,
      draftSortingType,
      draftIsPinned,
      draftGridable: conditionalParameter({
        data: payrunDraftStatusListContainer,
        field: "gridable",
      }),
    });

    // if singlelinegrid

    if (
      mustBeArray(
        conditionalParameter({
          data: payrunDraftStatusListContainer,
          field: "personalizations",
        })
      )[0] &&
      payrunDraftStatusListContainer.personalizations[0].value ===
      SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "draft",
        notUpdate: true,
        pageLoad: true,
      });
      this.setState({ draftSingleLineGridView: true });
    }

    // If Table
    this.handleDraftPayrunTable({
      payrunDraftStatusListContainer,
      draftGeneralTable,
      draftPaginationType,
    });

    // If Grid
    this.handleDraftPayrunGrid({
      payrunDraftStatusListContainer,
      draftGeneralGrid,
      draftPaginationType,
    });
  };

  handleDraftPayrunTable = ({
    payrunDraftStatusListContainer,
    draftGeneralTable,
    draftPaginationType,
  }) => {
    if (
      draftGeneralTable &&
      [1, 2].includes(Number(draftGeneralTable.authorizationStatusId))
    ) {
      const draftPagination = getPaginationFromPersonalization({
        personalizations: draftGeneralTable.personalizations,
      });
      const pageSize = getPageSizeFromPersonalization({
        personalizations: draftGeneralTable.personalizations,
      });
      const SortOrder = getSortOrderPersonalization({
        personalizations: draftGeneralTable.personalizations,
      });
      const SortColumn = getSortColumnFromPersonalization({
        personalizations: draftGeneralTable.personalizations,
      });

      this.setState(
        {
          draftPerPage: getPerPageFromPersonalization({
            paginationType: draftPaginationType,
            pageSize,
          }),
          draftPagination,
          draftSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            payrunDraftStatusListContainer &&
            mustBeArray(payrunDraftStatusListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(payrunDraftStatusListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (draftPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                type: "draft",
                notUpdate: true,
                pageLoad: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "draft",
                notUpdate: true,
                pageLoad: true,
              });
            }
          }
        }
      );
    }
  };

  handleDraftPayrunGrid = ({
    payrunDraftStatusListContainer,
    draftGeneralGrid,
    draftPaginationType,
  }) => {
    if (
      draftGeneralGrid &&
      [1, 2].includes(Number(draftGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = getGridPaginationFromPersonalization({
        personalizations: draftGeneralGrid.personalizations,
      });
      const row = getGridRowFromPersonalization({
        personalizations: draftGeneralGrid.personalizations,
      });
      const col = getGridColFromPersonalization({
        personalizations: draftGeneralGrid.personalizations,
      });
      const SortOrder = getGridSortOrderPersonalization({
        personalizations: draftGeneralGrid.personalizations,
      });
      const SortColumn = getGridSortColumnFromPersonalization({
        personalizations: draftGeneralGrid.personalizations,
      });

      this.setState(
        {
          draftGrid: {
            page: getDefaultPage({ page: pageNumber }),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          draftSelectedGridSort:
            _.find(
              this.state.draftGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.draftGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            payrunDraftStatusListContainer &&
            mustBeArray(payrunDraftStatusListContainer.personalizations)[0] &&
            mustBeArray(payrunDraftStatusListContainer.personalizations)[0]
              .value === GRID_VIEW_VALUE
          ) {
            this.setState({ draftGridView: true });
            // this.draftFetchMoreData({ draftIsPaginated: true });
            if (draftPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                type: "draft",
                notUpdate: true,
                pageLoad: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "draft",
                notUpdate: true,
                pageLoad: true,
              });
            }
          }
        }
      );
    }
  };

  handleAmendmentList = ({ payRunMultiTabContainer }) => {
    const payrunAmendmentStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        conditionalParameter({
          data: payRunMultiTabContainer,
          field: "components",
        })
      ),
      name: "payrunAmendmentStatusListContainer",
    });
    const amendmentGeneralTable = findAuthorizationClaims({
      claims: conditionalParameter({
        data: payrunAmendmentStatusListContainer,
        field: "components",
      }),
      name: "payrunAmendmentStatusGeneralTable",
    });
    const amendmentGeneralGrid = findAuthorizationClaims({
      claims: conditionalParameter({
        data: payrunAmendmentStatusListContainer,
        field: "components",
      }),
      name: "payrunAmendmentStatusGeneralGrid",
    });

    const amendmentPaginationType = getListPaginationTypeFromPersonalization({
      listContainer: payrunAmendmentStatusListContainer,
    });
    const amendmentSortingType = getListSortingTypeFromPersonalization({
      listContainer: payrunAmendmentStatusListContainer,
    });
    const amendmentIsPinned = getListIsPinnedFromPersonalization({
      listContainer: payrunAmendmentStatusListContainer,
    });

    this.setState({
      amendmentPaginationType,
      amendmentSortingType,
      amendmentIsPinned,
      amendmentGridable: getListIsGridableFromPersonalization({
        listContainer: payrunAmendmentStatusListContainer,
      }),
    });

    // if singlelinegrid

    if (
      mustBeArray(
        conditionalParameter({
          data: payrunAmendmentStatusListContainer,
          field: "personalizations",
        })
      )[0].value === SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "amendment",
        notUpdate: true,
      });
      this.setState({ amendmentSingleLineGridView: true });
    }

    // If Table
    this.handleAmendmentPayrunTable({
      amendmentGeneralTable,
      amendmentPaginationType,
      payrunAmendmentStatusListContainer,
    });

    // If Grid
    this.handleAmendmentPayrunGrid({
      amendmentGeneralGrid,
      amendmentPaginationType,
      payrunAmendmentStatusListContainer,
    });
  };

  handleAmendmentPayrunTable = ({
    amendmentGeneralTable,
    amendmentPaginationType,
    payrunAmendmentStatusListContainer,
  }) => {
    if (
      amendmentGeneralTable &&
      [1, 2].includes(Number(amendmentGeneralTable.authorizationStatusId))
    ) {
      const amendmentPagination = amendmentGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            amendmentGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            amendmentGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = amendmentGeneralTable.personalizations
        ? Number(
          amendmentGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
        )
        : 20;
      const SortOrder = amendmentGeneralTable.personalizations
        ? amendmentGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = amendmentGeneralTable.personalizations
        ? amendmentGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;
      this.setState(
        {
          amendmentPerPage:
            amendmentPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          amendmentPagination,
          amendmentSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            payrunAmendmentStatusListContainer &&
            mustBeArray(payrunAmendmentStatusListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(payrunAmendmentStatusListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (amendmentPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                pageLoad: true,
                type: "amendment",
                notUpdate: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "amendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  handleAmendmentPayrunGrid = ({
    amendmentGeneralGrid,
    amendmentPaginationType,
    payrunAmendmentStatusListContainer,
  }) => {
    if (
      amendmentGeneralGrid &&
      [1, 2].includes(Number(amendmentGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = amendmentGeneralGrid.personalizations
        ? Number(
          amendmentGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
        )
        : 1;
      const row = amendmentGeneralGrid.personalizations
        ? Number(amendmentGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = amendmentGeneralGrid.personalizations
        ? Number(amendmentGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = amendmentGeneralGrid.personalizations
        ? amendmentGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = amendmentGeneralGrid.personalizations
        ? amendmentGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          amendmentGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          amendmentSelectedGridSort:
            _.find(
              this.state.amendmentGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.amendmentGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            payrunAmendmentStatusListContainer &&
            mustBeArray(
              payrunAmendmentStatusListContainer.personalizations
            )[0] &&
            mustBeArray(payrunAmendmentStatusListContainer.personalizations)[0]
              .value === GRID_VIEW_VALUE
          ) {
            this.setState({ amendmentGridView: true });
            if (amendmentPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                pageLoad: true,
                type: "amendment",
                notUpdate: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "amendment",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  handlePrepareList = ({ payRunMultiTabContainer }) => {
    // Prepare
    const payrunPrepareStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        payRunMultiTabContainer && payRunMultiTabContainer.components
      ),
      name: "payrunPrepareStatusListContainer",
    });
    const prepareGeneralTable = findAuthorizationClaims({
      claims:
        payrunPrepareStatusListContainer &&
        payrunPrepareStatusListContainer.components,
      name: "payrunPrepareStatusGeneralTable",
    });
    const prepareGeneralGrid = findAuthorizationClaims({
      claims:
        payrunPrepareStatusListContainer &&
        payrunPrepareStatusListContainer.components,
      name: "payrunPrepareStatusGeneralGrid",
    });

    const preparePaginationType =
      payrunPrepareStatusListContainer &&
      payrunPrepareStatusListContainer.personalizations &&
      payrunPrepareStatusListContainer.personalizations[
      LIST_PAGINATION_TYPE_INDEX
      ] &&
      payrunPrepareStatusListContainer.personalizations[
        LIST_PAGINATION_TYPE_INDEX
      ].value;
    const prepareSortingType =
      payrunPrepareStatusListContainer &&
      payrunPrepareStatusListContainer.personalizations &&
      payrunPrepareStatusListContainer.personalizations[
      LIST_SORTING_REQUIRED_INDEX
      ] &&
      payrunPrepareStatusListContainer.personalizations[
        LIST_SORTING_REQUIRED_INDEX
      ].value;
    const prepareIsPinned =
      payrunPrepareStatusListContainer &&
      payrunPrepareStatusListContainer.personalizations &&
      payrunPrepareStatusListContainer.personalizations[LIST_PINNABLE_INDEX] &&
      payrunPrepareStatusListContainer.personalizations[LIST_PINNABLE_INDEX]
        .value;

    this.setState({
      preparePaginationType,
      prepareSortingType,
      prepareIsPinned,
      prepareGridable:
        payrunPrepareStatusListContainer &&
        payrunPrepareStatusListContainer.gridable,
    });

    // if singlelinegrid
    if (
      mustBeArray(
        payrunPrepareStatusListContainer &&
        payrunPrepareStatusListContainer.personalizations
      )[0].value === SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "prepare",
        notUpdate: true,
      });
      this.setState({ prepareSingleLineGridView: true });
    }
    if (
      prepareGeneralTable &&
      [1, 2].includes(Number(prepareGeneralTable.authorizationStatusId))
    ) {
      const preparePagination = prepareGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            prepareGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            prepareGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = prepareGeneralTable.personalizations
        ? Number(
          prepareGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
        )
        : 20;
      const SortOrder = prepareGeneralTable.personalizations
        ? prepareGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = prepareGeneralTable.personalizations
        ? prepareGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;
      this.setState(
        {
          preparePerPage:
            preparePaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          preparePagination,
          prepareSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            payrunPrepareStatusListContainer &&
            mustBeArray(payrunPrepareStatusListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(payrunPrepareStatusListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (preparePaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                pageLoad: true,
                type: "prepare",
                notUpdate: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "prepare",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
    // If Grid
    if (
      prepareGeneralGrid &&
      [1, 2].includes(Number(prepareGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = prepareGeneralGrid.personalizations
        ? Number(
          prepareGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
        )
        : 1;
      const row = prepareGeneralGrid.personalizations
        ? Number(prepareGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = prepareGeneralGrid.personalizations
        ? Number(prepareGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = prepareGeneralGrid.personalizations
        ? prepareGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = prepareGeneralGrid.personalizations
        ? prepareGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          prepareGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          prepareSelectedGridSort:
            _.find(
              this.state.prepareGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.prepareGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            payrunPrepareStatusListContainer &&
            mustBeArray(payrunPrepareStatusListContainer.personalizations)[0] &&
            mustBeArray(payrunPrepareStatusListContainer.personalizations)[0]
              .value === GRID_VIEW_VALUE
          ) {
            this.setState({ prepareGridView: true });
            // this.prepareFetchMoreData({ prepareIsPaginated: true });
            if (preparePaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                pageLoad: true,
                type: "prepare",
                notUpdate: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "prepare",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  handleApprovalList = ({ payRunMultiTabContainer }) => {
    // Approval
    const payrunApprovalStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        payRunMultiTabContainer && payRunMultiTabContainer.components
      ),
      name: "payrunApprovalStatusListContainer",
    });
    const approvalGeneralTable = findAuthorizationClaims({
      claims:
        payrunApprovalStatusListContainer &&
        payrunApprovalStatusListContainer.components,
      name: "payrunApprovalStatusGeneralTable",
    });
    const approvalGeneralGrid = findAuthorizationClaims({
      claims:
        payrunApprovalStatusListContainer &&
        payrunApprovalStatusListContainer.components,
      name: "payrunApprovalStatusGeneralGrid",
    });

    const approvalPaginationType =
      payrunApprovalStatusListContainer &&
      payrunApprovalStatusListContainer.personalizations &&
      payrunApprovalStatusListContainer.personalizations[
      LIST_PAGINATION_TYPE_INDEX
      ] &&
      payrunApprovalStatusListContainer.personalizations[
        LIST_PAGINATION_TYPE_INDEX
      ].value;
    const approvalSortingType =
      payrunApprovalStatusListContainer &&
      payrunApprovalStatusListContainer.personalizations &&
      payrunApprovalStatusListContainer.personalizations[
      LIST_SORTING_REQUIRED_INDEX
      ] &&
      payrunApprovalStatusListContainer.personalizations[
        LIST_SORTING_REQUIRED_INDEX
      ].value;
    const approvalIsPinned =
      payrunApprovalStatusListContainer &&
      payrunApprovalStatusListContainer.personalizations &&
      payrunApprovalStatusListContainer.personalizations[LIST_PINNABLE_INDEX] &&
      payrunApprovalStatusListContainer.personalizations[LIST_PINNABLE_INDEX]
        .value;

    this.setState({
      approvalPaginationType,
      approvalSortingType,
      approvalIsPinned,
      approvalGridable:
        payrunApprovalStatusListContainer &&
        payrunApprovalStatusListContainer.gridable,
    });

    // if singlelinegrid
    if (
      mustBeArray(
        payrunApprovalStatusListContainer &&
        payrunApprovalStatusListContainer.personalizations
      )[0].value === SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "approval",
        notUpdate: true,
      });
      this.setState({ approvalSingleLineGridView: true });
    }
    if (
      approvalGeneralTable &&
      [1, 2].includes(Number(approvalGeneralTable.authorizationStatusId))
    ) {
      const approvalPagination = approvalGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            approvalGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            approvalGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = approvalGeneralTable.personalizations
        ? Number(
          approvalGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
        )
        : 20;
      const SortOrder = approvalGeneralTable.personalizations
        ? approvalGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = approvalGeneralTable.personalizations
        ? approvalGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;
      this.setState(
        {
          approvalPerPage:
            approvalPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          approvalPagination,
          approvalSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            payrunApprovalStatusListContainer &&
            mustBeArray(payrunApprovalStatusListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(payrunApprovalStatusListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (approvalPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                pageLoad: true,
                type: "approval",
                notUpdate: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "approval",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
    // If Grid
    if (
      approvalGeneralGrid &&
      [1, 2].includes(Number(approvalGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = approvalGeneralGrid.personalizations
        ? Number(
          approvalGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
        )
        : 1;
      const row = approvalGeneralGrid.personalizations
        ? Number(approvalGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = approvalGeneralGrid.personalizations
        ? Number(approvalGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = approvalGeneralGrid.personalizations
        ? approvalGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = approvalGeneralGrid.personalizations
        ? approvalGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          approvalGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          approvalSelectedGridSort:
            _.find(
              this.state.approvalGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.approvalGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            payrunApprovalStatusListContainer &&
            mustBeArray(
              payrunApprovalStatusListContainer.personalizations
            )[0] &&
            mustBeArray(payrunApprovalStatusListContainer.personalizations)[0]
              .value === GRID_VIEW_VALUE
          ) {
            this.setState({ approvalGridView: true });
            if (approvalPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                pageLoad: true,
                type: "approval",
                notUpdate: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "approval",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  handleProcessList = ({ payRunMultiTabContainer }) => {
    // Process
    const payrunProcessStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        payRunMultiTabContainer && payRunMultiTabContainer.components
      ),
      name: "payrunProcessStatusListContainer",
    });
    const processGeneralTable = findAuthorizationClaims({
      claims:
        payrunProcessStatusListContainer &&
        payrunProcessStatusListContainer.components,
      name: "payrunProcessStatusGeneralTable",
    });
    const processGeneralGrid = findAuthorizationClaims({
      claims:
        payrunProcessStatusListContainer &&
        payrunProcessStatusListContainer.components,
      name: "payrunProcessStatusGeneralGrid",
    });

    const processPaginationType =
      payrunProcessStatusListContainer &&
      payrunProcessStatusListContainer.personalizations &&
      payrunProcessStatusListContainer.personalizations[
      LIST_PAGINATION_TYPE_INDEX
      ] &&
      payrunProcessStatusListContainer.personalizations[
        LIST_PAGINATION_TYPE_INDEX
      ].value;
    const processSortingType =
      payrunProcessStatusListContainer &&
      payrunProcessStatusListContainer.personalizations &&
      payrunProcessStatusListContainer.personalizations[
      LIST_SORTING_REQUIRED_INDEX
      ] &&
      payrunProcessStatusListContainer.personalizations[
        LIST_SORTING_REQUIRED_INDEX
      ].value;
    const processIsPinned =
      payrunProcessStatusListContainer &&
      payrunProcessStatusListContainer.personalizations &&
      payrunProcessStatusListContainer.personalizations[LIST_PINNABLE_INDEX] &&
      payrunProcessStatusListContainer.personalizations[LIST_PINNABLE_INDEX]
        .value;

    this.setState({
      processPaginationType,
      processSortingType,
      processIsPinned,
      processGridable:
        payrunProcessStatusListContainer &&
        payrunProcessStatusListContainer.gridable,
    });

    // if singlelinegrid
    if (
      mustBeArray(
        payrunProcessStatusListContainer &&
        payrunProcessStatusListContainer.personalizations
      )[0].value === SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "process",
        notUpdate: true,
      });
      this.setState({ processSingleLineGridView: true });
    }
    if (
      processGeneralTable &&
      [1, 2].includes(Number(processGeneralTable.authorizationStatusId))
    ) {
      const processPagination = processGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            processGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            processGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = processGeneralTable.personalizations
        ? Number(
          processGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
        )
        : 20;
      const SortOrder = processGeneralTable.personalizations
        ? processGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = processGeneralTable.personalizations
        ? processGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;
      this.setState(
        {
          processPerPage:
            processPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          processPagination,
          processSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            payrunProcessStatusListContainer &&
            mustBeArray(payrunProcessStatusListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(payrunProcessStatusListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (processPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                pageLoad: true,
                type: "process",
                notUpdate: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "process",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
    // If Grid
    if (
      processGeneralGrid &&
      [1, 2].includes(Number(processGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = processGeneralGrid.personalizations
        ? Number(
          processGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
        )
        : 1;
      const row = processGeneralGrid.personalizations
        ? Number(processGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = processGeneralGrid.personalizations
        ? Number(processGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = processGeneralGrid.personalizations
        ? processGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = processGeneralGrid.personalizations
        ? processGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          processGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          processSelectedGridSort:
            _.find(
              this.state.processGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.processGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            payrunProcessStatusListContainer &&
            mustBeArray(payrunProcessStatusListContainer.personalizations)[0] &&
            mustBeArray(payrunProcessStatusListContainer.personalizations)[0]
              .value === GRID_VIEW_VALUE
          ) {
            this.setState({ processGridView: true });
            if (processPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                pageLoad: true,
                type: "process",
                notUpdate: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "process",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  handleCompletedList = ({ payRunMultiTabContainer }) => {
    // Completed
    const payrunCompletedStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(
        payRunMultiTabContainer && payRunMultiTabContainer.components
      ),
      name: "payrunCompletedStatusListContainer",
    });
    const completedGeneralTable = findAuthorizationClaims({
      claims:
        payrunCompletedStatusListContainer &&
        payrunCompletedStatusListContainer.components,
      name: "payrunCompletedStatusGeneralTable",
    });
    const completedGeneralGrid = findAuthorizationClaims({
      claims:
        payrunCompletedStatusListContainer &&
        payrunCompletedStatusListContainer.components,
      name: "payrunCompletedStatusGeneralGrid",
    });

    const completedPaginationType =
      payrunCompletedStatusListContainer &&
      payrunCompletedStatusListContainer.personalizations &&
      payrunCompletedStatusListContainer.personalizations[
      LIST_PAGINATION_TYPE_INDEX
      ] &&
      payrunCompletedStatusListContainer.personalizations[
        LIST_PAGINATION_TYPE_INDEX
      ].value;
    const completedSortingType =
      payrunCompletedStatusListContainer &&
      payrunCompletedStatusListContainer.personalizations &&
      payrunCompletedStatusListContainer.personalizations[
      LIST_SORTING_REQUIRED_INDEX
      ] &&
      payrunCompletedStatusListContainer.personalizations[
        LIST_SORTING_REQUIRED_INDEX
      ].value;
    const completedIsPinned =
      payrunCompletedStatusListContainer &&
      payrunCompletedStatusListContainer.personalizations &&
      payrunCompletedStatusListContainer.personalizations[
      LIST_PINNABLE_INDEX
      ] &&
      payrunCompletedStatusListContainer.personalizations[LIST_PINNABLE_INDEX]
        .value;

    this.setState({
      completedPaginationType,
      completedSortingType,
      completedIsPinned,
      completedGridable:
        payrunCompletedStatusListContainer &&
        payrunCompletedStatusListContainer.gridable,
    });

    // if singlelinegrid
    if (
      mustBeArray(
        payrunCompletedStatusListContainer &&
        payrunCompletedStatusListContainer.personalizations
      )[0].value === SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "completed",
        notUpdate: true,
      });
      this.setState({ completedSingleLineGridView: true });
    }
    if (
      completedGeneralTable &&
      [1, 2].includes(Number(completedGeneralTable.authorizationStatusId))
    ) {
      const completedPagination = completedGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            completedGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            completedGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = completedGeneralTable.personalizations
        ? Number(
          completedGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
        )
        : 20;
      const SortOrder = completedGeneralTable.personalizations
        ? completedGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = completedGeneralTable.personalizations
        ? completedGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;
      this.setState(
        {
          completedPerPage:
            completedPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          completedPagination,
          completedSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            payrunCompletedStatusListContainer &&
            mustBeArray(payrunCompletedStatusListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(payrunCompletedStatusListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (completedPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                pageLoad: true,
                type: "completed",
                notUpdate: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "completed",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
    // If Grid
    if (
      completedGeneralGrid &&
      [1, 2].includes(Number(completedGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = completedGeneralGrid.personalizations
        ? Number(
          completedGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
        )
        : 1;
      const row = completedGeneralGrid.personalizations
        ? Number(completedGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = completedGeneralGrid.personalizations
        ? Number(completedGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = completedGeneralGrid.personalizations
        ? completedGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = completedGeneralGrid.personalizations
        ? completedGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          completedGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          completedSelectedGridSort:
            _.find(
              this.state.completedGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.completedGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            payrunCompletedStatusListContainer &&
            mustBeArray(
              payrunCompletedStatusListContainer.personalizations
            )[0] &&
            mustBeArray(payrunCompletedStatusListContainer.personalizations)[0]
              .value === GRID_VIEW_VALUE
          ) {
            this.setState({ completedGridView: true });
            if (completedPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                pageLoad: true,
                type: "completed",
                notUpdate: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "completed",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };

  handleRejectedList = ({ payRunMultiTabContainer }) => {
    // Rejected
    const payrunRejectedStatusListContainer = findAuthorizationClaims({
      claims: mustBeArray(payRunMultiTabContainer?.components),
      name: "payrunRejectedStatusListContainer",
    });
    const rejectedGeneralTable = findAuthorizationClaims({
      claims: payrunRejectedStatusListContainer?.components,
      name: "payrunRejectedStatusGeneralTable",
    });
    const rejectedGeneralGrid = findAuthorizationClaims({
      claims: payrunRejectedStatusListContainer?.components,
      name: "payrunRejectedStatusGeneralGrid",
    });
    const rejectedPaginationType =
      payrunRejectedStatusListContainer?.personalizations?.[
        LIST_PAGINATION_TYPE_INDEX
      ]?.value;
    const rejectedSortingType =
      payrunRejectedStatusListContainer?.personalizations?.[
        LIST_SORTING_REQUIRED_INDEX
      ]?.value;
    const rejectedIsPinned =
      payrunRejectedStatusListContainer?.personalizations?.[LIST_PINNABLE_INDEX]
        ?.value;

    this.setState({
      rejectedPaginationType,
      rejectedSortingType,
      rejectedIsPinned,
      rejectedGridable: payrunRejectedStatusListContainer?.gridable,
    });

    // Rejected
    // if singlelinegrid
    if (
      mustBeArray(payrunRejectedStatusListContainer?.personalizations)?.[0]
        ?.value === SINGLE_LINE_GRID_VALUE
    ) {
      this.listSingleLineGridPayruns({
        options: {},
        type: "rejected",
        notUpdate: true,
      });
      this.setState({ rejectedSingleLineGridView: true });
    }
    if (isComponentVisible({ claim: rejectedGeneralTable })) {
      const rejectedPagination = getDefaultPage({
        page: rejectedGeneralTable?.personalizations?.[PAGE_NUMBER_INDEX]
          ?.value,
      });
      const pageSize = getDefaultPerPage({
        perPage:
          rejectedGeneralTable?.personalizations?.[PAGE_SIZE_INDEX]?.value,
        defaultPerPage: 20,
      });
      const SortOrder =
        rejectedGeneralTable?.personalizations?.[SORT_ORDER_INDEX]?.value ??
        null;
      const SortColumn =
        rejectedGeneralTable?.personalizations?.[SORT_COLUMN_INDEX]?.value ??
        null;
      this.setState(
        {
          rejectedPerPage:
            rejectedPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize }),
          rejectedPagination,
          rejectedSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(payrunRejectedStatusListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (rejectedPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPayrun({
                pageLoad: true,
                type: "rejected",
                notUpdate: true,
              });
            } else {
              this.listPayruns({
                options: {},
                type: "rejected",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: rejectedGeneralGrid })) {
      const pageNumber = rejectedGeneralGrid.personalizations
        ? Number(
          rejectedGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
        )
        : 1;
      const row = rejectedGeneralGrid.personalizations
        ? Number(rejectedGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = rejectedGeneralGrid.personalizations
        ? Number(rejectedGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = rejectedGeneralGrid.personalizations
        ? rejectedGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = rejectedGeneralGrid.personalizations
        ? rejectedGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          rejectedGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          rejectedSelectedGridSort: _.find(
            this.state.rejectedGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            mustBeArray(
              payrunRejectedStatusListContainer?.personalizations
            )?.[0]?.value === GRID_VIEW_VALUE
          ) {
            this.setState({ rejectedGridView: true });
            if (rejectedPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPayrun({
                pageLoad: true,
                type: "rejected",
                notUpdate: true,
              });
            } else {
              this.payrunFetchMoreData({
                isPaginated: true,
                type: "rejected",
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };
  getPayrunTotalList = () => {
    this.props
      .listPayrunTotal({
        options: { page: 1 },
        CancelToken: this.signal.token,
      })
      .then((resp) => {
        this.setState({ listLoading: false });

        if (resp.status) {
          this.setState({ totalPayrunList: mustBeArray(resp.data) });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  handleFilterChange = ({ label, value }) => {
    this.setState({ filters: { ...this.state.filters, [label]: value } });
  };

  handleFilter = () => {
    this.updatePersonalization("payrunNameTextboxFilter", true);

    this.setState(
      {
        amendmentPagination: 1,
        amendmentGrid: { ...this.state.amendmentGrid, page: 1 },
        amendmentListLoading: true,

        preparePagination: 1,
        prepareGrid: { ...this.state.prepareGrid, page: 1 },
        prepareListLoading: true,

        approvalPagination: 1,
        approvalGrid: { ...this.state.approvalGrid, page: 1 },
        approvalListLoading: true,

        processPagination: 1,
        processGrid: { ...this.state.processGrid, page: 1 },
        processListLoading: true,

        completedPagination: 1,
        completedGrid: { ...this.state.completedGrid, page: 1 },
        completedListLoading: true,

        rejectedPagination: 1,
        rejectedGrid: { ...this.state.rejectedGrid, page: 1 },
        rejectedListLoading: true,
      },
      () => {
        _.forEach(this.state.payrunTypes, (payrunTypes) => {
          return this.fetchPayrunTypesList({ ...payrunTypes });
        });
      }
    );
  };

  resetFilter = () => {
    this.setState({ filters: {} }, () => {
      this.handleFilter();
    });
  };

  fetchPayrunTypesList = ({
    type,
    updateClaimName,
    actionName,
    notUpdate,
    gridClaimName,
  }) => {
    let tempFilters = this.getFilters();
    let options = { page: 1, q: { ...tempFilters } };
    let claimName;

    if (this.state[`${type}GridView`]) {
      options = {
        ...options,
        per_page: this.getPayrunGridPageSize({ type }),
        ...this.getPayrunGridSortInfo({ type }),
      };
      claimName = gridClaimName;
    } else {
      options = {
        ...options,
        per_page: this.state[`${type}PerPage`],
        ...this.getTableSortInfo({ type }),
      };
      claimName = updateClaimName;
    }
    if (type === "rejected") {
      options.q = { ...options.q, type: "rejected", isRejected: 1 };
    }
    actionName({
      options: options,

      cancelToken: this.signal.token,

      targetEndpoint: this.props.targetEndpoint,
    }).then((resp) => {
      this.setState({ [`${type}ListLoading`]: false });

      if (resp.status) {
        this.setState({
          [`${type}List`]: mustBeArray(resp.data),
          [`${type}Total`]: resp.total,
        });
      } else {
        errorDisplay(resp?.data?.validationErrors);
      }
    });
    delay(300).then(() => {
      if (!notUpdate) {
        this.updatePersonalization(claimName);
      }
    });
  };

  getFilters = () => {
    const { filters } = this.state;
    return filters?.name
      ? { ...filters, name: trimWhiteSpaces(filters.name) }
      : {};
  };

  listSingleLineGridPayruns = ({ options, type, pageLoad }) => {
    const claimAndAction = this.getActionAndClaimName({ type, gridView: true });
    let actionName = claimAndAction.actionName;
    let newOptions = {
      page: getDefaultPage({
        page: conditionalParameter({ data: options, field: "page" }),
      }),
      per_page: 20,
    };

    if (type === "rejected") {
      newOptions.q = { ...newOptions.q, isRejected: 1, type: "rejected" };
    }
    actionName({
      options: newOptions,

      cancelToken: this.signal.token,

      targetEndpoint: this.props.targetEndpoint,
    }).then((resp) => {
      this.setState({ [`${type}ListLoading`]: false });

      if (resp.status) {
        this.updateSystemGeneratedAsActiveTab({
          type,
          pageLoad,
          data: resp.data,
        });

        if (
          Number(this.state[`${type}Pagination`]) !== 1 &&
          _.isEmpty(mustBeArray(resp.data))
        ) {
          this.listSingleLineGridPayruns({ options: { page: 1 }, type });
        } else {
          this.setState({
            [`${type}List`]: mustBeArray(resp.data),
            [`${type}Total`]: resp.total,
          });
        }
        if (
          userRoles.CompanyArea.includes(getUserArea()) &&
          type === "amendment"
        ) {
          let payrunAmendments = mustBeArray(resp.data);
          if (!_.isEmpty(payrunAmendments)) {
            let isAmendmentDueSoon = false;
            payrunAmendments.forEach((data) => {
              const dueDateDays = getDifferenceInDays({
                value: data.ammendmentDueDateTime,
                format: "MM/DD/YYYY hh:mm:ss A",
              });
              isAmendmentDueSoon = getConditionalResponse({
                condition: [0, 1, 2, 3].includes(dueDateDays),
                resp1: true,
                resp2: isAmendmentDueSoon,
              });
            });

            this.setState({ hasActivePayrun: true, isAmendmentDueSoon });
          }
        }
      } else {
        errorHandler({ response: resp, hasValidationErrors: true });
      }
    });
  };

  updateSystemGeneratedAsActiveTab = ({ type, data, pageLoad }) => {
    if (type === "draft" && !_.isEmpty(mustBeArray(data)) && pageLoad) {
      this.setState({ activeKey: "7" });
    }
  };

  listPayruns = ({ options, type, notUpdate, pageLoad }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState[`${type}Pagination`] = options.page;
          prevState[`${type}SortInfo`] = {
            field: options.field,
            order: options.order,
          };
        }
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        const claimAndAction = this.getActionAndClaimName({
          type,
          gridView: false,
        });
        let updateClaimName = claimAndAction.updateClaimName;
        let actionName = claimAndAction.actionName;
        let newOptions = {
          page: getDefaultPage({ page: this.state[`${type}Pagination`] }),

          per_page: getDefaultPerPage({
            perPage: this.state[`${type}PerPage`],
          }),

          ...this.getTableSortInfo({ type }),
        };

        newOptions.q = getConditionalResponse({
          condition: this.getFilters(),
          resp1: this.getFilters(),
          resp2: {},
        });
        newOptions.q = getConditionalResponse({
          condition: type === "rejected",
          resp1: { ...newOptions.q, isRejected: 1, type: "rejected" },
          resp2: newOptions.q,
        });
        actionName({
          options: newOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
        }).then((resp) => {
          this.setState({ [`${type}ListLoading`]: false });

          if (resp.status) {
            this.updateSystemGeneratedAsActiveTab({
              type,
              pageLoad,
              data: resp.data,
            });

            if (
              Number(this.state[`${type}Pagination`]) !== 1 &&
              _.isEmpty(mustBeArray(resp.data))
            ) {
              this.setState({ [`${type}Pagination`]: 1 }, () => {
                this.listPayruns({ options: {}, type });
              });
            } else {
              this.setState({
                [`${type}List`]: mustBeArray(resp.data),
                [`${type}Total`]: resp.total,
              });
            }
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(updateClaimName);
          }
        });
      }
    );
  };

  listScrollingPayrun = ({
    options = {},
    pageLoad = false,
    type,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state[`${type}hasMoreData`]) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState[`${type}sortInfo`] = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState[`${type}Pagination`] =
            prevState[`${type}Total`] ===
              mustBeArray(prevState[`${type}List`]).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...this.getTableSortInfo({ type }),
          q: { ...tempFilters },
        };
        if (type === "rejected") {
          tempOptions.q = { ...tempOptions.q, type: "rejected", isRejected: 1 };
        }
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state[`${type}Pagination`]) *
              Number(this.state[`${type}PerPage`]),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state[`${type}Pagination`],
            per_page: this.state[`${type}PerPage`],
          };
        }

        const claimAndAction = this.getActionAndClaimName({
          type,
          gridView: false,
        });
        let updateClaimName = claimAndAction.updateClaimName;
        let actionName = claimAndAction.actionName;
        actionName({
          options: tempOptions,
          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
        }).then((resp) => {
          this.setState({ [`${type}ListLoading`]: false });

          if (resp.status) {
            this.updateSystemGeneratedAsActiveTab({
              type,
              pageLoad,
              data: resp.data,
            });

            if (
              Number(this.state[`${type}Pagination`]) !== 1 &&
              _.isEmpty(mustBeArray(resp.data))
            ) {
              this.setState({ [`${type}Pagination`]: 1 }, () => {
                this.listScrollingPayrun({ pageLoad: true, type });
              });
            } else {
              this.setState(
                {
                  [`${type}List`]: pageLoad
                    ? mustBeArray(resp.data)
                    : [...this.state[`${type}List`], ...mustBeArray(resp.data)],
                  [`${type}Total`]: resp.total,
                },
                () => {
                  this.setState({
                    [`${type}HasMoreData`]:
                      this.state[`${type}List`].length < resp.total
                        ? true
                        : false,
                  });
                }
              );
            }
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(updateClaimName);
          }
        });
      }
    );
  };

  // payrun
  listScrollingGridPayrun = ({ type, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state[`${type}HasMoreData`]) return false;

    this.setState(
      {
        [`${type}ListLoading`]: true,
        [`${type}Grid`]: {
          ...this.state[`${type}Grid`],
          page: !pageLoad
            ? Number(
              this.state[`${type}Grid`] && this.state[`${type}Grid`].page
            ) + 1
            : Number(
              this.state[`${type}Grid`] && this.state[`${type}Grid`].page
            ),
        },
      },
      () => {
        const grid = this.state[`${type}Grid`];
        const { page } = grid;

        const per_page = this.getPayrunGridPageSize({ type });

        let sortingParams = this.getPayrunGridSortInfo({ type });

        const claimAndAction = this.getActionAndClaimName({
          type,
          gridView: true,
        });
        let updateClaimName = claimAndAction.updateClaimName;
        let actionName = claimAndAction.actionName;
        let newOptions = {
          page: pageLoad ? 1 : page,
          per_page: pageLoad ? Number(page) * per_page : per_page,

          ...sortingParams,
          q: this.getFilters(),
        };
        if (type === "rejected") {
          newOptions.q = { ...newOptions.q, type: "rejected", isRejected: 1 };
        }
        actionName({
          options: newOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
        }).then((response) => {
          this.setState({ [`${type}ListLoading`]: false });

          if (response.status) {
            this.updateSystemGeneratedAsActiveTab({
              type,
              pageLoad,
              data: response.data,
            });

            if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
              this.setState({ [`${type}Grid`]: { ...grid, page: 1 } }, () => {
                this.listScrollingGridPayrun({ pageLoad: true, type });
              });
            } else {
              this.setState(
                (prevState) => {
                  const data = mustBeArray(response.data);
                  prevState[`${type}List`] = pageLoad
                    ? data
                    : prevState[`${type}List`].concat(data);

                  prevState[`${type}HasMoreData`] =
                    response.total > prevState[`${type}List`].length
                      ? true
                      : false;

                  prevState[`${type}Total`] = response.total;
                  return prevState;
                },
                () => {
                  delay(300).then(() => {
                    if (!notUpdate) {
                      this.updatePersonalization(updateClaimName);
                    }
                  });
                }
              );
            }
          }
        });
      }
    );
  };

  payrunFetchMoreData = ({
    isPaginated = false,
    type,
    notUpdate,
    pageLoad,
  }) => {
    this.setState({ [`${type}ListLoading`]: true });

    const { page } = this.state[`${type}Grid`];

    const per_page = this.getPayrunGridPageSize({ type });

    let sortingParams = this.getPayrunGridSortInfo({ type });

    const claimAndAction = this.getActionAndClaimName({ type, gridView: true });
    let updateClaimName = claimAndAction.updateClaimName;
    let actionName = claimAndAction.actionName;

    let newOptions = { page, per_page, ...sortingParams, q: this.getFilters() };
    if (type === "rejected") {
      newOptions.q = { ...newOptions.q, type: "rejected", isRejected: 1 };
    }
    actionName({
      options: newOptions,

      cancelToken: this.signal.token,

      targetEndpoint: this.props.targetEndpoint,
    }).then((response) => {
      if (response.status) {
        this.updateSystemGeneratedAsActiveTab({
          type,
          pageLoad,
          data: response.data,
        });

        if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
          this.setState(
            { [`${type}Grid`]: { ...this.state[`${type}Grid`], page: 1 } },
            () => {
              this.payrunFetchMoreData({ isPaginated, type });
            }
          );
        } else {
          this.setState(
            (prevState) => {
              const data = mustBeArray(response.data);
              if (isPaginated) {
                prevState[`${type}List`] = data;
              } else {
                prevState[`${type}List`] =
                  prevState[`${type}List`].concat(data);
                prevState[`${type}HasMoreData`] = listHasMoreData({
                  data,
                  currentList: prevState[`${type}List`],
                  currentTotal: prevState[`${type}Total`],
                });
                prevState[`${type}Grid`] = listHasMoreData({
                  data,
                  currentList: prevState[`${type}List`],
                  currentTotal: prevState[`${type}Total`],
                })
                  ? {
                    ...prevState[`${type}Grid`],
                    page: prevState[`${type}Grid`].page + 1,
                  }
                  : prevState[`${type}Grid`];
              }

              prevState[`${type}Total`] = response.total;
              prevState[`${type}ListLoading`] = false;
              return prevState;
            },
            () => {
              this.updateWithDelay({
                timeDelay: 300,
                component: updateClaimName,
                notUpdate,
              });
            }
          );
        }
      }
    });
  };

  updateWithDelay = ({ timeDelay, component, notUpdate }) => {
    if (!notUpdate) {
      delay(timeDelay).then(() => {
        this.updatePersonalization(component);
      });
    }
  };

  getActionAndClaimName = ({ type, gridView }) => {
    let updateClaimName = "";
    let actionName;
    let containerName = "";
    switch (type) {
      case "amendment":
        updateClaimName = `payrunAmendmentStatusGeneral${gridView ? "Grid" : "Table"
          }`;
        containerName = `payrunAmendmentStatusListContainer`;

        actionName = this.props.listPayrunAmendments;
        break;
      case "prepare":
        updateClaimName = `payrunPrepareStatusGeneral${gridView ? "Grid" : "Table"
          }`;

        actionName = this.props.listPayrunPrepare;
        containerName = `payrunPrepareStatusListContainer`;
        break;
      case "approval":
        updateClaimName = `payrunApprovalStatusGeneral${gridView ? "Grid" : "Table"
          }`;

        actionName = this.props.listPayrunApproval;
        containerName = `payrunApprovalStatusListContainer`;
        break;
      case "process":
        updateClaimName = `payrunProcessStatusGeneral${gridView ? "Grid" : "Table"
          }`;

        actionName = this.props.listPayrunProcess;
        containerName = `payrunProcessStatusListContainer`;
        break;
      case "completed":
        updateClaimName = `payrunCompletedStatusGeneral${gridView ? "Grid" : "Table"
          }`;

        actionName = this.props.listPayrunCompleted;
        containerName = `payrunCompletedStatusListContainer`;
        break;
      case "rejected":
        updateClaimName = `payrunRejectedStatusGeneral${gridView ? "Grid" : "Table"
          }`;

        actionName = this.props.listPayrun;
        containerName = `payrunRejectedStatusListContainer`;
        break;
      case "draft":
        updateClaimName = `payrunDraftStatusGeneral${gridView ? "Grid" : "Table"
          }`;

        actionName = this.props.listPayrunDraft;
        containerName = `payrunDraftStatusListContainer`;
        break;
      default:
        break;
    }
    return { updateClaimName, actionName, containerName };
  };

  listPayrunGrid = ({ page, type }) => {
    this.setState(
      (prevState) => {
        prevState[`${type}Grid`].page = page;
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state[`${type}Grid`] && this.state[`${type}Grid`].page,
          per_page: this.getPayrunGridPageSize({ type }),

          ...this.getPayrunGridSortInfo({ type }),
        };

        if (this.getFilters()) {
          tempOptions.q = this.getFilters();
        }
        if (type === "rejected") {
          tempOptions.q = { ...tempOptions.q, type: "rejected", isRejected: 1 };
        }

        const claimAndAction = this.getActionAndClaimName({
          type,
          gridView: true,
        });
        let updateClaimName = claimAndAction.updateClaimName;
        let actionName = claimAndAction.actionName;
        actionName({
          options: tempOptions,

          cancelToken: this.signal.token,

          targetEndpoint: this.props.targetEndpoint,
        }).then((resp) => {
          this.setState({ [`${type}ListLoading`]: false });

          if (resp.status) {
            this.setState({
              [`${type}List`]: mustBeArray(resp.data),
              [`${type}Total`]: resp.total,
            });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
        delay(300).then(() => {
          this.updatePersonalization(updateClaimName);
        });
      }
    );
  };

  getPayrunGridSortInfo = ({ type }) => {
    if (
      this.state[`${type}Grid`]?.sortInfo?.field &&
      this.state[`${type}Grid`]?.sortInfo?.order &&
      this.state[`${type}SortingType`] !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state[`${type}Grid`].sortInfo.field]:
            this.state[`${type}Grid`].sortInfo.order,
        },
      };
    } else if (
      this.state[`${type}Grid`]?.sortInfo?.order &&
      this.state[`${type}SortingType`] !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state[`${type}Grid`].sortInfo.order,
        },
      };
    } else return {};
  };

  handlePayrunGridChange = ({ gridView, type }) => {
    if (this.state[`${type}GridView`] === gridView) {
      return false;
    }

    this.setState(
      {
        [`${type}GridView`]: gridView,
        [`${type}List`]: [],
        [`${type}ListLoading`]: true,
      },
      () => {
        const claimAndAction = this.getActionAndClaimName({
          type,
          gridView: true,
        });
        let updateClaimName = claimAndAction.containerName;

        this.updatePersonalization(updateClaimName);
        if (!gridView) {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingPayrun({ pageLoad: true, options: {}, type });
          } else {
            this.listPayruns({ options: {}, type });
          }
        } else {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGridPayrun({ pageLoad: true, options: {}, type });
          } else {
            this.payrunFetchMoreData({ isPaginated: true, type });
          }
        }
      }
    );
  };

  payrunUpdatePageSize = ({ value, type }) => {
    if (this.state[`${type}GridView`]) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState[`${type}Grid`].row = Number(row);
          prevState[`${type}Grid`].col = Number(col);
          prevState[`${type}Grid`].page = 1;
          return prevState;
        },
        () => {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGridPayrun({ pageLoad: true, options: {}, type });
          } else {
            this.payrunFetchMoreData({ isPaginated: true, type });
          }
        }
      );
    } else {
      this.setState(
        { [`${type}PerPage`]: Number(value), [`${type}Pagination`]: 1 },
        () => {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingPayrun({ pageLoad: true, options: {}, type });
          } else {
            this.listPayruns({ options: {}, type });
          }
        }
      );
    }
  };

  payrunUpdateSortOrder = ({ selectedGridSort, type }) => {
    this.setState(
      (prevState) => {
        prevState[`${type}SelectedGridSort`] = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState[`${type}GridSortOption`],
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState[`${type}Grid`] = {
            ...prevState[`${type}Grid`],
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
          this.listScrollingGridPayrun({ pageLoad: true, options: {}, type });
        } else {
          this.payrunFetchMoreData({ isPaginated: true, type });
        }
      }
    );
  };

  handlePayrunAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/payrun/${data && data.payrunId}`);
        break;
      case "edit":
        this.handleEdit(data);
        break;
      case "viewAmendmentSummary":
        return this.handleViewAmendmentSummary(data);
      default:
        break;
    }
  };

  handleViewAmendmentSummary = (data) => {
    this.setState({ activePayrun: data });

    this.props
      .listAmendments({
        id: data && data.payrunId,

        options: { per_page: 500, page: 1 },
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            amendmentSummaryList: mustBeArray(resp.data),
            viewAmendmentSummary: true,
          });
        }
      });
  };

  handleAmendmentSummaryClose = () => {
    this.setState({
      amendmentSummaryList: [],
      viewAmendmentSummary: false,
      activePayrun: {},
    });
  };

  payrunUpdateGridField = ({ field, value, type }) => {
    this.setState(
      (prevState) => {
        if (prevState[`${type}Grid`].sortInfo[field] === value) {
          prevState[`${type}Grid`].sortInfo[field] = "";
        } else {
          prevState[`${type}Grid`].sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.payrunFetchMoreData({ isPaginated: true, type });
      }
    );
  };

  getPayrunGridPageSize = ({ type }) => {
    const row = this.state[`${type}Grid`]?.row;
    const col = this.state[`${type}Grid`]?.col;

    return this.state[`${type}PaginationType`] === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };

  updatePayrunGridField = ({ field, value, type }) => {
    this.setState(
      (prevState) => {
        if (prevState[`${type}Grid`].sortInfo[field] === value) {
          prevState[`${type}Grid`].sortInfo[field] = "";
        } else {
          prevState[`${type}Grid`].sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.payrunFetchMoreData({ isPaginated: true, type });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    const { member, pageClaims, dashboardView } = this.props;
    if (dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member?.details?.authorizationDOM
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const payrunFilterContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: PAYRUN_FILTER_CONTAINER,
      });
      const payrunMultiTabContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: PAYRUN_MULTI_TAB_CONTAINER,
      });
      const payrunAmendmentStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunAmendmentStatusListContainer",
      });
      const payrunPrepareStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunPrepareStatusListContainer",
      });
      const payrunApprovalStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunApprovalStatusListContainer",
      });
      const payrunProcessStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunProcessStatusListContainer",
      });
      const payrunCompletedStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunCompletedStatusListContainer",
      });
      const payrunDraftStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunDraftStatusListContainer",
      });
      const payrunRejectedStatusListContainer = findAuthorizationClaims({
        claims: payrunMultiTabContainer?.components,
        name: "payrunRejectedStatusListContainer",
      });
      let updatedDOM = [];
      switch (field) {
        case PAYRUN_MULTI_TAB_CONTAINER:
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims?.components,
            name: field,
          });
          break;
        case "payrunAmendmentStatusGeneralTable":
          if (payrunAmendmentStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.amendmentPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.amendmentPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.amendmentSortInfo &&
              this.state.amendmentSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.amendmentSortInfo &&
              this.state.amendmentSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunAmendmentStatusGeneralGrid":
          if (payrunAmendmentStatusListContainer.personalizable === "true") {
            const { amendmentGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${amendmentGrid && amendmentGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${amendmentGrid && amendmentGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${amendmentGrid && amendmentGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              amendmentGrid &&
              amendmentGrid.sortInfo &&
              amendmentGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              amendmentGrid &&
              amendmentGrid.sortInfo &&
              amendmentGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunAmendmentStatusListContainer":
          if (payrunAmendmentStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.amendmentGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.amendmentPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.amendmentSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.amendmentIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;

        // prepare
        case "payrunPrepareStatusGeneralTable":
          if (payrunPrepareStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.preparePagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.preparePerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.prepareSortInfo && this.state.prepareSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.prepareSortInfo && this.state.prepareSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunPrepareStatusGeneralGrid":
          if (payrunPrepareStatusListContainer.personalizable === "true") {
            const { prepareGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${prepareGrid && prepareGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${prepareGrid && prepareGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${prepareGrid && prepareGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              prepareGrid && prepareGrid.sortInfo && prepareGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              prepareGrid && prepareGrid.sortInfo && prepareGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunPrepareStatusListContainer":
          if (payrunPrepareStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.prepareGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.preparePaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.prepareSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.prepareIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;

        // approval
        case "payrunApprovalStatusGeneralTable":
          if (payrunApprovalStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.approvalPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.approvalPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.approvalSortInfo && this.state.approvalSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.approvalSortInfo && this.state.approvalSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunApprovalStatusGeneralGrid":
          if (payrunApprovalStatusListContainer.personalizable === "true") {
            const { approvalGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${approvalGrid && approvalGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${approvalGrid && approvalGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${approvalGrid && approvalGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              approvalGrid &&
              approvalGrid.sortInfo &&
              approvalGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              approvalGrid &&
              approvalGrid.sortInfo &&
              approvalGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunApprovalStatusListContainer":
          if (payrunApprovalStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.approvalGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.approvalPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.approvalSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.approvalIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;

        // process
        case "payrunProcessStatusGeneralTable":
          if (payrunProcessStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.processPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.processPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.processSortInfo && this.state.processSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.processSortInfo && this.state.processSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunProcessStatusGeneralGrid":
          if (payrunProcessStatusListContainer.personalizable === "true") {
            const { processGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${processGrid && processGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${processGrid && processGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${processGrid && processGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              processGrid && processGrid.sortInfo && processGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              processGrid && processGrid.sortInfo && processGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunProcessStatusListContainer":
          if (payrunProcessStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.processGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.processPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.processSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.processIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;

        // Completed
        case "payrunCompletedStatusGeneralTable":
          if (payrunCompletedStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.completedPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.completedPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.completedSortInfo &&
              this.state.completedSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.completedSortInfo &&
              this.state.completedSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunCompletedStatusGeneralGrid":
          if (payrunCompletedStatusListContainer.personalizable === "true") {
            const { completedGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${completedGrid && completedGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${completedGrid && completedGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${completedGrid && completedGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              completedGrid &&
              completedGrid.sortInfo &&
              completedGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              completedGrid &&
              completedGrid.sortInfo &&
              completedGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunCompletedStatusListContainer":
          if (payrunCompletedStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.completedGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.completedPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.completedSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.completedIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;

        // Draft
        case "payrunDraftStatusGeneralTable":
          if (payrunDraftStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.draftPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.draftPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.draftSortInfo && this.state.draftSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.draftSortInfo && this.state.draftSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunDraftStatusGeneralGrid":
          if (payrunDraftStatusListContainer.personalizable === "true") {
            const { draftGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${draftGrid && draftGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${draftGrid && draftGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${draftGrid && draftGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              draftGrid && draftGrid.sortInfo && draftGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              draftGrid && draftGrid.sortInfo && draftGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunDraftStatusListContainer":
          if (payrunDraftStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.draftGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.draftPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.draftSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.draftIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;

        // rejected
        case "payrunRejectedStatusGeneralTable":
          if (payrunRejectedStatusListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.rejectedPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.rejectedPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.rejectedSortInfo && this.state.rejectedSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.rejectedSortInfo && this.state.rejectedSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunRejectedStatusGeneralGrid":
          if (payrunRejectedStatusListContainer.personalizable === "true") {
            const { rejectedGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${rejectedGrid && rejectedGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${rejectedGrid && rejectedGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${rejectedGrid && rejectedGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              rejectedGrid &&
              rejectedGrid.sortInfo &&
              rejectedGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              rejectedGrid &&
              rejectedGrid.sortInfo &&
              rejectedGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunRejectedStatusListContainer":
          if (payrunRejectedStatusListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.rejectedGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.rejectedPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.rejectedSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.rejectedIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunNameTextboxFilter":
          if (payrunFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value =
              (this.state.filters && this.state.filters.name) || "";
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims && this.props.pageClaims.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent && updatedComponent.id,
          name: updatedComponent && updatedComponent.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };
      }

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update,
        });
      });
    } catch (err) {
      return err;
    }
  };

  getTableSortInfo = ({ type }) => {
    const sortInfo = `${type}SortInfo`;

    if (
      this.state[sortInfo]?.field &&
      this.state[sortInfo]?.order &&
      this.state[`${type}SortingType`] !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state[sortInfo].field]: this.state[sortInfo].order,
        },
      };
    } else return {};
  };

  handleEdit = ({ data }) => {
    this.props.router.navigate(`/payrun/${data?.payrunId}`);
  };

  onTabChange(key) {
    this.setState({ activeKey: key }, () => {
      this.updatePersonalization(PAYRUN_MULTI_TAB_CONTAINER);
    });
  }
  getActiveFilters = () => {
    let activeFilters = [];

    let filtersApplied = this.getFilters();
    if (filtersApplied && filtersApplied.payrunTypeId) {
      activeFilters.push(
        Number(filtersApplied.payrunTypeId) === ADHOC_PAYRUN_ID
          ? "Ad-hoc"
          : "scheduled"
      );
    }
    return activeFilters;
  };
  render() {
    const { pageClaims, dashboardView } = this.props;
    const {
      activeKey,
      draftListLoading,
      draftList,
      draftPagination,
      draftSortInfo,
      draftPerPage,
      draftTotal,
      draftGrid,
      draftGridable,
      draftGridView,
      draftFieldOptions,
      draftSortingType,
      draftPaginationType,
      draftIsPinned,
      draftHasMoreData,
      amendmentListLoading,
      amendmentList,
      amendmentPagination,
      amendmentSortInfo,
      amendmentPerPage,
      amendmentTotal,
      amendmentGrid,
      amendmentGridable,
      amendmentGridView,
      amendmentFieldOptions,
      amendmentSortingType,
      amendmentPaginationType,
      amendmentIsPinned,
      amendmentHasMoreData,
      prepareListLoading,
      prepareList,
      preparePagination,
      prepareSortInfo,
      preparePerPage,
      prepareTotal,
      prepareGrid,
      prepareGridable,
      prepareGridView,
      prepareFieldOptions,
      prepareSortingType,
      preparePaginationType,
      prepareIsPinned,
      prepareHasMoreData,
      approvalListLoading,
      approvalList,
      approvalPagination,
      approvalSortInfo,
      approvalPerPage,
      approvalTotal,
      approvalGrid,
      approvalGridable,
      approvalGridView,
      approvalFieldOptions,
      approvalSortingType,
      approvalPaginationType,
      approvalIsPinned,
      approvalHasMoreData,
      processListLoading,
      processList,
      processPagination,
      processSortInfo,
      processPerPage,
      processTotal,
      processGrid,
      processGridable,
      processGridView,
      processFieldOptions,
      processSortingType,
      processPaginationType,
      processIsPinned,
      processHasMoreData,
      completedListLoading,
      completedList,
      completedPagination,
      completedSortInfo,
      completedPerPage,
      completedTotal,
      completedGrid,
      completedGridable,
      completedGridView,
      completedFieldOptions,
      completedSortingType,
      completedPaginationType,
      completedIsPinned,
      completedHasMoreData,
      rejectedListLoading,
      rejectedList,
      rejectedPagination,
      rejectedSortInfo,
      rejectedPerPage,
      rejectedTotal,
      rejectedGrid,
      rejectedGridable,
      rejectedGridView,
      rejectedFieldOptions,
      rejectedSortingType,
      rejectedPaginationType,
      rejectedIsPinned,
      rejectedHasMoreData,
      amendmentSingleLineGridView,
      prepareSingleLineGridView,
      approvalSingleLineGridView,
      processSingleLineGridView,
      completedSingleLineGridView,
      rejectedSingleLineGridView,
      draftSingleLineGridView,
    } = this.state;
    const payrunTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === PAYRUN_MULTI_TAB_CONTAINER
    );

    // Tabs
    const amendmentStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunAmendmentStatusTabContainer"
    );
    const prepareStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunPrepareStatusTabContainer"
    );
    const approvalStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunApprovalStatusTabContainer"
    );
    const processStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunProcessStatusTabContainer"
    );
    const completedStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunCompletedStatusTabContainer"
    );
    const rejectedStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunRejectedStatusTabContainer"
    );
    const draftStatusTabContainer = _.find(
      mustBeArray(payrunTabContainer?.components),
      (o) => o.name === "payrunDraftStatusTabContainer"
    );

    //Child Tabs
    const amendmentStatusChildTabContainer = _.find(
      mustBeArray(amendmentStatusTabContainer?.components),
      (o) => o.name === "payrunAmendmentStatusChildTabContainer"
    );
    const prepareStatusChildTabContainer = _.find(
      mustBeArray(prepareStatusTabContainer?.components),
      (o) => o.name === "payrunPrepareStatusChildTabContainer"
    );
    const approvalStatusChildTabContainer = _.find(
      mustBeArray(approvalStatusTabContainer?.components),
      (o) => o.name === "payrunApprovalStatusChildTabContainer"
    );
    const processStatusChildTabContainer = _.find(
      mustBeArray(processStatusTabContainer?.components),
      (o) => o.name === "payrunProcessStatusChildTabContainer"
    );
    const completedStatusChildTabContainer = _.find(
      mustBeArray(completedStatusTabContainer?.components),
      (o) => o.name === "payrunCompletedStatusChildTabContainer"
    );
    const rejectedStatusChildTabContainer = _.find(
      mustBeArray(rejectedStatusTabContainer?.components),
      (o) => o.name === "payrunRejectedStatusChildTabContainer"
    );
    const draftStatusChildTabContainer = _.find(
      mustBeArray(draftStatusTabContainer?.components),
      (o) => o.name === "payrunDraftStatusChildTabContainer"
    );

    // Card Grid

    const amendmentCardGrid = getCurrentCardGrid({
      grid: this.state.amendmentGrid,
    });

    const prepareCardGrid = getCurrentCardGrid({
      grid: this.state.prepareGrid,
    });

    const approvalCardGrid = getCurrentCardGrid({
      grid: this.state.approvalGrid,
    });

    const processCardGrid = getCurrentCardGrid({
      grid: this.state.processGrid,
    });

    const completedCardGrid = getCurrentCardGrid({
      grid: this.state.completedGrid,
    });

    const rejectedCardGrid = getCurrentCardGrid({
      grid: this.state.rejectedGrid,
    });

    const draftCardGrid = getCurrentCardGrid({ grid: this.state.draftGrid });

    // Titles

    const activeFilters = this.getActiveFilters();
    const payrunBaseUrl = APIHandler.constructEndpoint({
      endpoint: `PAYRUN_URL`,
    });
    const amendmentTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        StatusID: PayRunStatus.getStatusID({
          status: "amendments",
        }),
      },
    });
    const prepareTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        StatusID: PayRunStatus.getStatusID({
          status: "prepare",
        }),
      },
    });
    const approvalTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        StatusID: PayRunStatus.getStatusID({
          status: "approval",
        }),
      },
    });
    const processTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        StatusID: PayRunStatus.getStatusID({
          status: "process",
        }),
      },
    });
    const completedTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        StatusID: PayRunStatus.getStatusID({
          status: "completed",
        }),
      },
    });
    const draftTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        StatusID: PayRunStatus.getStatusID({
          status: "draft",
        }),
      },
    });
    const rejectedTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
        isRejected: 1,
      },
    });

    const payrunMapper = {
      amendment: {
        name: "payrunAmendmentStatusListContainer",
        claims: amendmentStatusChildTabContainer,
        isPinned: amendmentIsPinned,
        gridable: amendmentGridable,
        gridView: amendmentGridView,
        grid: amendmentGrid,
        per_page: amendmentPerPage,
        generalTableComponentName: "payrunAmendmentStatusGeneralTable",
        gridComponentName: "payrunAmendmentStatusGeneralGrid",
        fieldOptions: amendmentFieldOptions,
        sortingType: amendmentSortingType,
        paginationType: amendmentPaginationType,
        list: amendmentList,
        listLoading: amendmentListLoading,
        hasMoreData: amendmentHasMoreData,
        total: amendmentTotal,
        current: amendmentPagination,
        sortInfo: amendmentSortInfo,
        cardGrid: amendmentCardGrid,
        viewComponent: "payrunAmendmentStatusDetailView",
        pinnedComponentName: "amendmentIsPinned",
        singleLineGridView: amendmentSingleLineGridView,
      },
      prepare: {
        name: "payrunPrepareStatusListContainer",
        claims: prepareStatusChildTabContainer,
        isPinned: prepareIsPinned,
        gridable: prepareGridable,
        gridView: prepareGridView,
        grid: prepareGrid,
        per_page: preparePerPage,
        generalTableComponentName: "payrunPrepareStatusGeneralTable",
        gridComponentName: "payrunPrepareStatusGeneralGrid",
        fieldOptions: prepareFieldOptions,
        sortingType: prepareSortingType,
        paginationType: preparePaginationType,
        list: prepareList,
        listLoading: prepareListLoading,
        hasMoreData: prepareHasMoreData,
        total: prepareTotal,
        current: preparePagination,
        sortInfo: prepareSortInfo,
        cardGrid: prepareCardGrid,
        viewComponent: "payrunPrepareStatusDetailView",
        pinnedComponentName: "prepareIsPinned",
        singleLineGridView: prepareSingleLineGridView,
      },
      approval: {
        name: "payrunApprovalStatusListContainer",
        claims: approvalStatusChildTabContainer,
        isPinned: approvalIsPinned,
        gridable: approvalGridable,
        gridView: approvalGridView,
        grid: approvalGrid,
        per_page: approvalPerPage,
        generalTableComponentName: "payrunApprovalStatusGeneralTable",
        gridComponentName: "payrunApprovalStatusGeneralGrid",
        fieldOptions: approvalFieldOptions,
        sortingType: approvalSortingType,
        paginationType: approvalPaginationType,
        list: approvalList,
        listLoading: approvalListLoading,
        hasMoreData: approvalHasMoreData,
        total: approvalTotal,
        current: approvalPagination,
        sortInfo: approvalSortInfo,
        cardGrid: approvalCardGrid,
        viewComponent: "payrunApprovalStatusDetailView",
        pinnedComponentName: "approvalIsPinned",
        singleLineGridView: approvalSingleLineGridView,
      },
      process: {
        name: "payrunProcessStatusListContainer",
        claims: processStatusChildTabContainer,
        isPinned: processIsPinned,
        gridable: processGridable,
        gridView: processGridView,
        grid: processGrid,
        per_page: processPerPage,
        generalTableComponentName: "payrunProcessStatusGeneralTable",
        gridComponentName: "payrunProcessStatusGeneralGrid",
        fieldOptions: processFieldOptions,
        sortingType: processSortingType,
        paginationType: processPaginationType,
        list: processList,
        listLoading: processListLoading,
        hasMoreData: processHasMoreData,
        total: processTotal,
        current: processPagination,
        sortInfo: processSortInfo,
        cardGrid: processCardGrid,
        viewComponent: "payrunProcessStatusDetailView",
        pinnedComponentName: "processIsPinned",
        singleLineGridView: processSingleLineGridView,
      },
      completed: {
        name: "payrunCompletedStatusListContainer",
        claims: completedStatusChildTabContainer,
        isPinned: completedIsPinned,
        gridable: completedGridable,
        gridView: completedGridView,
        grid: completedGrid,
        per_page: completedPerPage,
        generalTableComponentName: "payrunCompletedStatusGeneralTable",
        gridComponentName: "payrunCompletedStatusGeneralGrid",
        fieldOptions: completedFieldOptions,
        sortingType: completedSortingType,
        paginationType: completedPaginationType,
        list: completedList,
        listLoading: completedListLoading,
        hasMoreData: completedHasMoreData,
        total: completedTotal,
        current: completedPagination,
        sortInfo: completedSortInfo,
        cardGrid: completedCardGrid,
        viewComponent: "payrunCompletedStatusDetailView",
        pinnedComponentName: "completedIsPinned",
        singleLineGridView: completedSingleLineGridView,
      },
      rejected: {
        name: "payrunRejectedStatusListContainer",
        claims: rejectedStatusChildTabContainer,
        isPinned: rejectedIsPinned,
        gridable: rejectedGridable,
        gridView: rejectedGridView,
        grid: rejectedGrid,
        per_page: rejectedPerPage,
        generalTableComponentName: "payrunRejectedStatusGeneralTable",
        gridComponentName: "payrunRejectedStatusGeneralGrid",
        fieldOptions: rejectedFieldOptions,
        sortingType: rejectedSortingType,
        paginationType: rejectedPaginationType,
        list: rejectedList,
        listLoading: rejectedListLoading,
        hasMoreData: rejectedHasMoreData,
        total: rejectedTotal,
        current: rejectedPagination,
        sortInfo: rejectedSortInfo,
        cardGrid: rejectedCardGrid,
        viewComponent: "payrunRejectedStatusDetailView",
        pinnedComponentName: "rejectedIsPinned",
        singleLineGridView: rejectedSingleLineGridView,
      },
      draft: {
        name: "payrunDraftStatusListContainer",
        claims: draftStatusChildTabContainer,
        isPinned: draftIsPinned,
        gridable: draftGridable,
        gridView: draftGridView,
        grid: draftGrid,
        per_page: draftPerPage,
        generalTableComponentName: "payrunDraftStatusGeneralTable",
        gridComponentName: "payrunDraftStatusGeneralGrid",
        fieldOptions: draftFieldOptions,
        sortingType: draftSortingType,
        paginationType: draftPaginationType,
        list: draftList,
        listLoading: draftListLoading,
        hasMoreData: draftHasMoreData,
        total: draftTotal,
        current: draftPagination,
        sortInfo: draftSortInfo,
        cardGrid: draftCardGrid,
        viewComponent: "payrunDraftStatusDetailView",
        pinnedComponentName: "draftIsPinned",
        singleLineGridView: draftSingleLineGridView,
      },
    };
    return this.state.pageLoad ? (
      <Loading />
    ) : (
      <React.Fragment>
        <AmendmentSummaryList
          visible={this.state.viewAmendmentSummary}
          list={this.state.amendmentSummaryList}
          payrun={this.state.activePayrun}
          handleAmendmentSummaryClose={this.handleAmendmentSummaryClose}
        />
        <If
          condition={dashboardView}
          then={
            <ListContainer
              listPayrunTotal={this.props.listPayrunTotal}
              singleLineGridView={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].singleLineGridView
              }
              singleLineGridTitle={
                <div className="flex items-start">
                  <PayRunIconCustom />
                  <span className="font-bold text-base ml-2"> Pay Runs</span>
                </div>
              }
              SingleLineGridDisplayComponent={PayrunSingleLineGridDisplay}
              dashboardView={dashboardView}
              name={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].name
              }
              claims={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].claims
              }
              isPinned={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].isPinned
              }
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].gridable
              }
              gridView={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].gridView
              }
              grid={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].grid
              }
              per_page={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].per_page
              }
              GeneralTablePaginatedComponent={PayrunList}
              GeneralTableScrollableComponent={ScrollablePayrunList}
              generalTableComponentName={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].generalTableComponentName
              }
              gridComponentName={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].gridComponentName
              }
              handleAddAction={this.handlePayrunCreate}
              handleGridChange={(options) =>
                this.handlePayrunGridChange({
                  ...options,
                  type: this.props.type,
                })
              }
              fieldOptions={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].fieldOptions
              }
              updateGridField={(options) =>
                this.updatePayrunGridField({
                  ...options,
                  type: this.props.type,
                })
              }
              sortingType={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].sortingType
              }
              paginationType={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].paginationType
              }
              list={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].list
              }
              listLoading={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].listLoading
              }
              listGrid={(page) =>
                this.listPayrunGrid({ page, type: this.props.type })
              }
              listScrollingGrid={(options) =>
                this.listScrollingGridPayrun({
                  ...options,
                  type: this.props.type,
                })
              }
              hasMoreData={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].hasMoreData
              }
              gridActionComponentName={"UserCompanyViewDetailLink"}
              handleGridAction={(options) =>
                this.handlePayrunAction({ ...options, type: this.props.type })
              }
              listScrollingGeneralTable={(options) =>
                this.listScrollingPayrun({ ...options, type: this.props.type })
              }
              total={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].total
              }
              current={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].current
              }
              sortInfo={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].sortInfo
              }
              updatePageSize={(value) =>
                this.payrunUpdatePageSize({ value, type: this.props.type })
              }
              listGeneralTable={({ options }) =>
                this.listPayruns({ options, type: this.props.type })
              }
              tableActionComponentName={"userCompanyViewDetailLink"}
              gridPageSize={this.getPayrunGridPageSize({
                type: this.props.type,
              })}
              cardGrid={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].cardGrid
              }
              viewComponent={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].viewComponent
              }
              pinnedComponentName={
                payrunMapper[this.props.type] &&
                payrunMapper[this.props.type].pinnedComponentName
              }
              showAddAndGrid={true}
              DisplayCardComponent={PayRunDisplayCard}
              handleActions={(options) =>
                this.handlePayrunActions({ ...options, type: this.props.type })
              }
              handleEdit={(data) =>
                this.handleEdit({ data, type: this.props.type })
              }
              componentTitle={this.props.componentTitle}
              filterApplied={this.props.filterApplied}
              targetEndpoint={this.props.targetEndpoint}
              handleViewAmendmentSummary={this.handleViewAmendmentSummary}
              rowIdParam="payrunId"
            />
          }
          else={
            <div className="tab-top" gutter={DEFAULT_GUTTER}>
              {this.state.hasActivePayrun &&
                [userRoles.CompanyArea].includes(getUserArea()) && (
                  <div>
                    <AmendmentDueMessage
                      isAmendmentDueSoon={this.state.isAmendmentDueSoon}
                    />
                  </div>
                )}
              {![userRoles.CompanyArea].includes(getUserArea()) && (
                <FilterContainer
                  name={PAYRUN_FILTER_CONTAINER}
                  claims={this.props.pageClaims}
                >
                  <SingleFieldTextBoxFilter
                    placeholder="Keywords"
                    name="payrunNameTextboxFilter"
                    claims={this.props.claims}
                    value={this.state.filters && this.state.filters.name}
                    onChange={(value) =>
                      this.handleFilterChange({ label: "name", value })
                    }
                    onSearch={this.handleFilter}
                    className="mb-[15px] w-250 mr-[1px]"
                  />
                  <SearchIconButton
                    onClick={this.handleFilter}
                    name="payrunTypeFilter"
                    claims={this.props.claims}
                  />
                  <LinkButton
                    buttonText="Reset Filter"
                    className="color-primary hand"
                    onClick={this.resetFilter}
                    name="payrunResetFilter"
                    claims={this.props.claims}
                  />
                </FilterContainer>
              )}
              <MultiTabContainer
                name={PAYRUN_MULTI_TAB_CONTAINER}
                claims={pageClaims}
                activeKey={activeKey}
                handleTabChange={this.onTabChange}
              >
                {isComponentVisible({ claim: draftStatusTabContainer }) &&
                  !_.isEmpty(draftList) && (
                    <TabPane
                      tab={
                        <TabHeader
                          title="System Generated"
                          total={draftTotal}
                        />
                      }
                      key={7}
                    >
                      <ChildTabContainer
                        name="payrunDraftStatusChildTabContainer"
                        claims={draftStatusTabContainer}
                      >
                        <ListContainer
                          name="payrunDraftStatusListContainer"
                          claims={draftStatusChildTabContainer}
                          isPinned={draftIsPinned}
                          updatePinnedComponent={this.updatePinnedComponent}
                          gridable={draftGridable}
                          gridView={draftGridView}
                          grid={draftGrid}
                          per_page={draftPerPage}
                          GeneralTablePaginatedComponent={PayrunList}
                          GeneralTableScrollableComponent={PayrunList}
                          generalTableComponentName={
                            "payrunDraftStatusGeneralTable"
                          }
                          gridComponentName={"payrunDraftStatusGeneralGrid"}
                          handleAddAction={this.handlePayrunCreate}
                          handleGridChange={(options) =>
                            this.handlePayrunGridChange({
                              ...options,
                              type: "draft",
                            })
                          }
                          fieldOptions={draftFieldOptions}
                          updateGridField={(options) =>
                            this.updatePayrunGridField({
                              ...options,
                              type: "draft",
                            })
                          }
                          sortingType={draftSortingType}
                          paginationType={draftPaginationType}
                          list={draftList}
                          listLoading={draftListLoading}
                          listGrid={(page) =>
                            this.listPayrunGrid({ page, type: "draft" })
                          }
                          listScrollingGrid={(options) =>
                            this.listScrollingGridPayrun({
                              ...options,
                              type: "draft",
                            })
                          }
                          hasMoreData={draftHasMoreData}
                          handleGridAction={this.handlePayrunAction}
                          listScrollingGeneralTable={(options) =>
                            this.listScrollingPayrun({
                              ...options,
                              type: "draft",
                            })
                          }
                          total={draftTotal}
                          current={draftPagination}
                          sortInfo={draftSortInfo}
                          updatePageSize={(value) =>
                            this.payrunUpdatePageSize({
                              value,
                              type: "draft",
                            })
                          }
                          listGeneralTable={({ options }) =>
                            this.listPayruns({ options, type: "draft" })
                          }
                          gridPageSize={this.getPayrunGridPageSize({
                            type: "draft",
                          })}
                          cardGrid={draftCardGrid}
                          pinnedComponentName="draftIsPinned"
                          showAddAndGrid={true}
                          DisplayCardComponent={PayRunDisplayCard}
                          handleActions={(options) =>
                            this.handlePayrunActions({
                              ...options,
                              type: "draft",
                            })
                          }
                          handleEdit={(data) =>
                            this.handleEdit({ data, type: "draft" })
                          }
                          singleLineGridView={draftSingleLineGridView}
                          SingleLineGridDisplayComponent={
                            PayrunSingleLineGridDisplay
                          }
                          componentTitle="Pay Runs System Generated"
                          hasNoDuplicateEntity={true}
                          filterApplied={filteredByLabel({
                            filters: activeFilters,
                          })}
                          targetEndpoint={draftTargetEndpoint}
                          viewComponent="payrunDraftStatusEdit"
                          isDraft={true}
                          handleViewAmendmentSummary={
                            this.handleViewAmendmentSummary
                          }
                          rowIdParam="payrunId"
                        />
                      </ChildTabContainer>
                    </TabPane>
                  )}
                {isComponentVisible({ claim: amendmentStatusTabContainer }) && (
                  <TabPane
                    tab={
                      <TabHeader
                        title="Pending Amendments"
                        total={amendmentTotal}
                      />
                    }
                    key={1}
                  >
                    <ChildTabContainer
                      name="payrunAmendmentStatusChildTabContainer"
                      claims={amendmentStatusTabContainer}
                    >
                      <ListContainer
                        name="payrunAmendmentStatusListContainer"
                        claims={amendmentStatusChildTabContainer}
                        isPinned={amendmentIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={amendmentGridable}
                        gridView={amendmentGridView}
                        grid={amendmentGrid}
                        per_page={amendmentPerPage}
                        GeneralTablePaginatedComponent={PayrunList}
                        GeneralTableScrollableComponent={ScrollablePayrunList}
                        generalTableComponentName={
                          "payrunAmendmentStatusGeneralTable"
                        }
                        gridComponentName={"payrunAmendmentStatusGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handlePayrunGridChange({
                            ...options,
                            type: "amendment",
                          })
                        }
                        fieldOptions={amendmentFieldOptions}
                        updateGridField={(options) =>
                          this.updatePayrunGridField({
                            ...options,
                            type: "amendment",
                          })
                        }
                        sortingType={amendmentSortingType}
                        paginationType={amendmentPaginationType}
                        list={amendmentList}
                        listLoading={amendmentListLoading}
                        listGrid={(page) =>
                          this.listPayrunGrid({
                            page,
                            type: "amendment",
                          })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridPayrun({
                            ...options,
                            type: "amendment",
                          })
                        }
                        hasMoreData={amendmentHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={(options) =>
                          this.handlePayrunAction({
                            ...options,
                            type: "amendment",
                          })
                        }
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingPayrun({
                            ...options,
                            type: "amendment",
                          })
                        }
                        total={amendmentTotal}
                        current={amendmentPagination}
                        sortInfo={amendmentSortInfo}
                        updatePageSize={(value) =>
                          this.payrunUpdatePageSize({
                            value,
                            type: "amendment",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listPayruns({ options, type: "amendment" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getPayrunGridPageSize({
                          type: "amendment",
                        })}
                        cardGrid={amendmentCardGrid}
                        // editComponent="payrunAmendmentStatusDetailView"
                        viewComponent="payrunAmendmentStatusDetailView"
                        pinnedComponentName="amendmentIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={PayRunDisplayCard}
                        handleActions={(options) =>
                          this.handlePayrunActions({
                            ...options,
                            type: "amendment",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "amendment" })
                        }
                        singleLineGridView={amendmentSingleLineGridView}
                        SingleLineGridDisplayComponent={
                          PayrunSingleLineGridDisplay
                        }
                        componentTitle="Pay Runs Pending Amendments"
                        hasNoDuplicateEntity={true}
                        filterApplied={filteredByLabel({
                          filters: activeFilters,
                        })}
                        targetEndpoint={amendmentTargetEndpoint}
                        handleViewAmendmentSummary={
                          this.handleViewAmendmentSummary
                        }
                        rowIdParam="payrunId"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
                {isComponentVisible({ claim: prepareStatusTabContainer }) && (
                  <TabPane
                    tab={
                      <TabHeader title="Pending Prepare" total={prepareTotal} />
                    }
                    key={2}
                  >
                    <ChildTabContainer
                      name="payrunPrepareStatusChildTabContainer"
                      claims={prepareStatusTabContainer}
                    >
                      <ListContainer
                        name="payrunPrepareStatusListContainer"
                        claims={prepareStatusChildTabContainer}
                        isPinned={prepareIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={prepareGridable}
                        gridView={prepareGridView}
                        grid={prepareGrid}
                        per_page={preparePerPage}
                        GeneralTablePaginatedComponent={PayrunList}
                        GeneralTableScrollableComponent={ScrollablePayrunList}
                        generalTableComponentName={
                          "payrunPrepareStatusGeneralTable"
                        }
                        gridComponentName={"payrunPrepareStatusGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handlePayrunGridChange({
                            ...options,
                            type: "prepare",
                          })
                        }
                        fieldOptions={prepareFieldOptions}
                        updateGridField={(options) =>
                          this.updatePayrunGridField({
                            ...options,
                            type: "prepare",
                          })
                        }
                        sortingType={prepareSortingType}
                        paginationType={preparePaginationType}
                        list={prepareList}
                        listLoading={prepareListLoading}
                        listGrid={(page) =>
                          this.listPayrunGrid({
                            page,
                            type: "prepare",
                          })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridPayrun({
                            ...options,
                            type: "prepare",
                          })
                        }
                        hasMoreData={prepareHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={this.handlePayrunAction}
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingPayrun({
                            ...options,
                            type: "prepare",
                          })
                        }
                        total={prepareTotal}
                        current={preparePagination}
                        sortInfo={prepareSortInfo}
                        updatePageSize={(value) =>
                          this.payrunUpdatePageSize({
                            value,
                            type: "prepare",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listPayruns({ options, type: "prepare" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getPayrunGridPageSize({
                          type: "prepare",
                        })}
                        cardGrid={prepareCardGrid}
                        // editComponent="payrunPrepareStatusDetailView"
                        viewComponent="payrunPrepareStatusDetailView"
                        pinnedComponentName="prepareIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={PayRunDisplayCard}
                        handleActions={(options) =>
                          this.handlePayrunActions({
                            ...options,
                            type: "prepare",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "prepare" })
                        }
                        singleLineGridView={prepareSingleLineGridView}
                        SingleLineGridDisplayComponent={
                          PayrunSingleLineGridDisplay
                        }
                        componentTitle="Pay Runs Pending Prepare"
                        hasNoDuplicateEntity={true}
                        filterApplied={filteredByLabel({
                          filters: activeFilters,
                        })}
                        targetEndpoint={prepareTargetEndpoint}
                        handleViewAmendmentSummary={
                          this.handleViewAmendmentSummary
                        }
                        rowIdParam="payrunId"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
                {isComponentVisible({ claim: approvalStatusTabContainer }) && (
                  <TabPane
                    tab={
                      <TabHeader
                        title="Pending Approval"
                        total={approvalTotal}
                      />
                    }
                    key={3}
                  >
                    <ChildTabContainer
                      name="payrunApprovalStatusChildTabContainer"
                      claims={approvalStatusTabContainer}
                    >
                      <ListContainer
                        name="payrunApprovalStatusListContainer"
                        claims={approvalStatusChildTabContainer}
                        isPinned={approvalIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={approvalGridable}
                        gridView={approvalGridView}
                        grid={approvalGrid}
                        per_page={approvalPerPage}
                        GeneralTablePaginatedComponent={PayrunList}
                        GeneralTableScrollableComponent={PayrunList}
                        generalTableComponentName={
                          "payrunApprovalStatusGeneralTable"
                        }
                        gridComponentName={"payrunApprovalStatusGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handlePayrunGridChange({
                            ...options,
                            type: "approval",
                          })
                        }
                        fieldOptions={approvalFieldOptions}
                        updateGridField={(options) =>
                          this.updatePayrunGridField({
                            ...options,
                            type: "approval",
                          })
                        }
                        sortingType={approvalSortingType}
                        paginationType={approvalPaginationType}
                        list={approvalList}
                        listLoading={approvalListLoading}
                        listGrid={(page) =>
                          this.listPayrunGrid({
                            page,
                            type: "approval",
                          })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridPayrun({
                            ...options,
                            type: "approval",
                          })
                        }
                        hasMoreData={approvalHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={this.handlePayrunAction}
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingPayrun({
                            ...options,
                            type: "approval",
                          })
                        }
                        total={approvalTotal}
                        current={approvalPagination}
                        sortInfo={approvalSortInfo}
                        updatePageSize={(value) =>
                          this.payrunUpdatePageSize({
                            value,
                            type: "approval",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listPayruns({ options, type: "approval" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getPayrunGridPageSize({
                          type: "approval",
                        })}
                        cardGrid={approvalCardGrid}
                        // editComponent="payrunApprovalStatusDetailView"
                        viewComponent="payrunApprovalStatusDetailView"
                        pinnedComponentName="approvalIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={PayRunDisplayCard}
                        handleActions={(options) =>
                          this.handlePayrunActions({
                            ...options,
                            type: "approval",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "approval" })
                        }
                        singleLineGridView={approvalSingleLineGridView}
                        SingleLineGridDisplayComponent={
                          PayrunSingleLineGridDisplay
                        }
                        componentTitle="Pay Runs Pending Approval"
                        hasNoDuplicateEntity={true}
                        filterApplied={filteredByLabel({
                          filters: activeFilters,
                        })}
                        targetEndpoint={approvalTargetEndpoint}
                        handleViewAmendmentSummary={
                          this.handleViewAmendmentSummary
                        }
                        rowIdParam="payrunId"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
                {isComponentVisible({ claim: processStatusTabContainer }) && (
                  <TabPane
                    tab={
                      <TabHeader title="Pending Process" total={processTotal} />
                    }
                    key={4}
                  >
                    <ChildTabContainer
                      name="payrunProcessStatusChildTabContainer"
                      claims={processStatusTabContainer}
                    >
                      <ListContainer
                        name="payrunProcessStatusListContainer"
                        claims={processStatusChildTabContainer}
                        isPinned={processIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={processGridable}
                        gridView={processGridView}
                        grid={processGrid}
                        per_page={processPerPage}
                        GeneralTablePaginatedComponent={PayrunList}
                        GeneralTableScrollableComponent={PayrunList}
                        generalTableComponentName={
                          "payrunProcessStatusGeneralTable"
                        }
                        gridComponentName={"payrunProcessStatusGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handlePayrunGridChange({
                            ...options,
                            type: "process",
                          })
                        }
                        fieldOptions={processFieldOptions}
                        updateGridField={(options) =>
                          this.updatePayrunGridField({
                            ...options,
                            type: "process",
                          })
                        }
                        sortingType={processSortingType}
                        paginationType={processPaginationType}
                        list={processList}
                        listLoading={processListLoading}
                        listGrid={(page) =>
                          this.listPayrunGrid({
                            page,
                            type: "process",
                          })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridPayrun({
                            ...options,
                            type: "process",
                          })
                        }
                        hasMoreData={processHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={this.handlePayrunAction}
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingPayrun({
                            ...options,
                            type: "process",
                          })
                        }
                        total={processTotal}
                        current={processPagination}
                        sortInfo={processSortInfo}
                        updatePageSize={(value) =>
                          this.payrunUpdatePageSize({
                            value,
                            type: "process",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listPayruns({ options, type: "process" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getPayrunGridPageSize({
                          type: "process",
                        })}
                        cardGrid={processCardGrid}
                        viewComponent="payrunProcessStatusDetailView"
                        pinnedComponentName="processIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={PayRunDisplayCard}
                        handleActions={(options) =>
                          this.handlePayrunActions({
                            ...options,
                            type: "process",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "process" })
                        }
                        singleLineGridView={processSingleLineGridView}
                        SingleLineGridDisplayComponent={
                          PayrunSingleLineGridDisplay
                        }
                        componentTitle="Pay Runs Pending Process"
                        hasNoDuplicateEntity={true}
                        filterApplied={filteredByLabel({
                          filters: activeFilters,
                        })}
                        targetEndpoint={processTargetEndpoint}
                        handleViewAmendmentSummary={
                          this.handleViewAmendmentSummary
                        }
                        rowIdParam="payrunId"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
                {isComponentVisible({ claim: completedStatusTabContainer }) && (
                  <TabPane
                    tab={<TabHeader title="Completed" total={completedTotal} />}
                    key={5}
                  >
                    <ChildTabContainer
                      name="payrunCompletedStatusChildTabContainer"
                      claims={completedStatusTabContainer}
                    >
                      <ListContainer
                        name="payrunCompletedStatusListContainer"
                        claims={completedStatusChildTabContainer}
                        isPinned={completedIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={completedGridable}
                        gridView={completedGridView}
                        grid={completedGrid}
                        per_page={completedPerPage}
                        GeneralTablePaginatedComponent={PayrunList}
                        GeneralTableScrollableComponent={PayrunList}
                        generalTableComponentName={
                          "payrunCompletedStatusGeneralTable"
                        }
                        gridComponentName={"payrunCompletedStatusGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handlePayrunGridChange({
                            ...options,
                            type: "completed",
                          })
                        }
                        fieldOptions={completedFieldOptions}
                        updateGridField={(options) =>
                          this.updatePayrunGridField({
                            ...options,
                            type: "completed",
                          })
                        }
                        sortingType={completedSortingType}
                        paginationType={completedPaginationType}
                        list={completedList}
                        listLoading={completedListLoading}
                        listGrid={(page) =>
                          this.listPayrunGrid({
                            page,
                            type: "completed",
                          })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridPayrun({
                            ...options,
                            type: "completed",
                          })
                        }
                        hasMoreData={completedHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={this.handlePayrunAction}
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingPayrun({
                            ...options,
                            type: "completed",
                          })
                        }
                        total={completedTotal}
                        current={completedPagination}
                        sortInfo={completedSortInfo}
                        updatePageSize={(value) =>
                          this.payrunUpdatePageSize({
                            value,
                            type: "completed",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listPayruns({ options, type: "completed" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getPayrunGridPageSize({
                          type: "completed",
                        })}
                        cardGrid={completedCardGrid}
                        viewComponent="payrunCompletedStatusDetailView"
                        pinnedComponentName="completedIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={PayRunDisplayCard}
                        handleActions={(options) =>
                          this.handlePayrunActions({
                            ...options,
                            type: "completed",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "completed" })
                        }
                        singleLineGridView={completedSingleLineGridView}
                        SingleLineGridDisplayComponent={
                          PayrunSingleLineGridDisplay
                        }
                        componentTitle="Pay Runs Completed"
                        hasNoDuplicateEntity={true}
                        filterApplied={filteredByLabel({
                          filters: activeFilters,
                        })}
                        targetEndpoint={completedTargetEndpoint}
                        handleViewAmendmentSummary={
                          this.handleViewAmendmentSummary
                        }
                        rowIdParam="payrunId"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}

                {isComponentVisible({ claim: rejectedStatusTabContainer }) && (
                  <TabPane
                    tab={
                      <TabHeader
                        title="Rejected"
                        total={rejectedTotal}
                        type="danger"
                      />
                    }
                    key={6}
                  >
                    <ChildTabContainer
                      name="payrunRejectedStatusChildTabContainer"
                      claims={rejectedStatusTabContainer}
                    >
                      <ListContainer
                        name="payrunRejectedStatusListContainer"
                        claims={rejectedStatusChildTabContainer}
                        isPinned={rejectedIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={rejectedGridable}
                        gridView={rejectedGridView}
                        grid={rejectedGrid}
                        per_page={rejectedPerPage}
                        GeneralTablePaginatedComponent={PayrunList}
                        GeneralTableScrollableComponent={PayrunList}
                        generalTableComponentName={
                          "payrunRejectedStatusGeneralTable"
                        }
                        gridComponentName={"payrunRejectedStatusGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handlePayrunGridChange({
                            ...options,
                            type: "rejected",
                          })
                        }
                        fieldOptions={rejectedFieldOptions}
                        updateGridField={(options) =>
                          this.updatePayrunGridField({
                            ...options,
                            type: "rejected",
                          })
                        }
                        sortingType={rejectedSortingType}
                        paginationType={rejectedPaginationType}
                        list={rejectedList}
                        listLoading={rejectedListLoading}
                        listGrid={(page) =>
                          this.listPayrunGrid({
                            page,
                            type: "rejected",
                          })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridPayrun({
                            ...options,
                            type: "rejected",
                          })
                        }
                        hasMoreData={rejectedHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={this.handlePayrunAction}
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingPayrun({
                            ...options,
                            type: "rejected",
                          })
                        }
                        total={rejectedTotal}
                        current={rejectedPagination}
                        sortInfo={rejectedSortInfo}
                        updatePageSize={(value) =>
                          this.payrunUpdatePageSize({
                            value,
                            type: "rejected",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listPayruns({ options, type: "rejected" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getPayrunGridPageSize({
                          type: "rejected",
                        })}
                        cardGrid={rejectedCardGrid}
                        viewComponent="payrunRejectedStatusDetailView"
                        pinnedComponentName="rejectedIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={PayRunDisplayCard}
                        handleActions={(options) =>
                          this.handlePayrunActions({
                            ...options,
                            type: "rejected",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "rejected" })
                        }
                        singleLineGridView={rejectedSingleLineGridView}
                        SingleLineGridDisplayComponent={
                          PayrunSingleLineGridDisplay
                        }
                        componentTitle="Pay Runs Rejected"
                        hasNoDuplicateEntity={true}
                        filterApplied={filteredByLabel({
                          filters: activeFilters,
                        })}
                        targetEndpoint={rejectedTargetEndpoint}
                        handleViewAmendmentSummary={
                          this.handleViewAmendmentSummary
                        }
                        rowIdParam="payrunId"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
              </MultiTabContainer>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PayrollProviderPayrun);
