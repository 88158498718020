import _ from "lodash";
import React, { Fragment } from "react";
import { If } from "../Common/ui";
import {
  AmendmentStatusBadge,
  KeypayStatusBadge,
  YesNoPartialStatus,
} from "../Common/amendments";
import {
  CURRENT_EXTERNAL_EARLINGLINES_INDEX,
  PREVIOUS_EXTERNAL_EARLINGLINES_INDEX,
  TERMINATION_CHANGE_TYPE_ID,
} from "../constants";
import { useAuthDom } from "../hooks";
import {
  getPayrunColumnTitle,
  getVarianceClassName,
  getVarianceDisplayValue,
} from "./payrun";
import { getFormattedDate, renderReoccurringEndDate } from "./timeHandlers";
import {
  currencyFormater,
  getAmendmentTypeName,
  getColumnSortOrder,
  isEmpty,
  mustBeArray,
  parseItems,
} from "./utilities";
import { getVarianceSDColWidth, getVarianceStartEndDate } from "./variance";
import { AmendmentType } from "../types";
import { PendingAmountValue } from "../Organization/components/payrun/Amendments/pending-amount-value";
import { getAmendmentStatusId, getAmendmentStatusInPPSId, getAmendmentTaskTypeId } from "./generalAmendment";

export const getGeneralAmendmentColumns = ({
  sortInfo,
  type,
  dashboardView,
}: {
  sortInfo: any;
  type: string;
  dashboardView?: boolean;
}) => {
  const { isUserCompanyAdmin } = useAuthDom();
  const commonColumns = [
    {
      title: "Type",
      dataIndex: "amendmentTaskTypeName",
      sorter: !dashboardView,
      sortOrder: getColumnSortOrder({
        sortInfo,
        name: "amendmentTaskTypeName",
      }),
      width: "6%",
      ellipsis: true,
    },
    {
      title: "Sub Type",
      dataIndex: "amendmentSubTypeName",
      sortOrder: getColumnSortOrder({
        sortInfo,
        name: "amendmentSubTypeName",
      }),
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Business Unit",
      dataIndex: "businessUnitName",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Applies To",
      dataIndex: "assignTo",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      dataType: "currency",
      width: "8%",
      ellipsis: true,
      render: (data: number | null, record: AmendmentType) => {
        //TODO: Replace calculated value with BE returned totalAmount https://expedo.atlassian.net/browse/GC-870  */
        const totalAmount = (record.amount ?? 0) * (record.units ?? 0);
        if (getAmendmentTaskTypeId(record) === TERMINATION_CHANGE_TYPE_ID)
          return "N/A";
        return (
          <PendingAmountValue
            value={totalAmount}
            isIntegrated={record.isIntegrated}
            amendmentTaskTypeId={getAmendmentTaskTypeId(record)}
            amendmentKeypayStatusId={record.amendmentKeypayStatusId}
          />
        );
      },
    },
    {
      title: "Expiry",
      dataIndex: "schedule",
      render: (_data: any, record: any) => {
        return renderReoccurringEndDate(record);
      },
      width: "7%",
      ellipsis: true,
    },
    {
      title: "Requested By",
      dataIndex: "requestedBy",
      dataType: "requestedBy",
      sorter: !dashboardView,
      sortOrder: getColumnSortOrder({ sortInfo, name: "requestedBy" }),
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Requested Date",
      dataType: "date",
      dataIndex: "requestedDate",
      sorter: !dashboardView,
      sortOrder: getColumnSortOrder({ sortInfo, name: "requestedDate" }),
      width: "8%",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "amendmentStatusName",
      render: (_data: string, record: any) => {
        return <AmendmentStatusBadge statusID={getAmendmentStatusId(record)} />;
      },
      width: "7%",
    },
    {
      title: "Amendment Type",
      dataIndex: "amendmentTypeId",
      render: (data: any, record: any) => {
        return getAmendmentTypeName(record?.amendmentTypeId);
      },
      width: type === "pending" ? "12%" : "9%",
      ellipsis: true,
    },
  ];
  return type === "pending"
    ? commonColumns
    : [
      ...commonColumns,
      {
        ellipsis: true,
        title: "Applied?",
        dataIndex: "applied",
        render: (_data: any, record: any) => (
          <If
            condition={isUserCompanyAdmin()}
            then={
              <KeypayStatusBadge
                statusInPPSID={getAmendmentStatusInPPSId(record)}
              />
            }
            else={
              <YesNoPartialStatus
                statusInPPSID={getAmendmentStatusInPPSId(record)}
              />
            }
          />
        ),
      },
    ];
};

// Earninglines List columns
export const getEarninglinesColumn = ({
  businessunitDetails,
  invoice,
  showVariance,
  showRejectionReason,
  payrun,
  highLightVarianceValue,
}: any) => {
  let column;

  if (showVariance) {
    if (!isEmpty(invoice)) {
      column = [
        {
          title: getPayrunColumnTitle({
            payrun: payrun,
            showRejectionReason,
          }),
          dataIndex: "externalEmployeeName",
          key: "name",
          width: "25%",
        },
        {
          title: "Previous GST",
          dataIndex: "",
          key: "previousTaxes",
          width: "17%",
          align: "right",
          render: (record: any) =>
            currencyFormater(
              _.sumBy(
                mustBeArray(
                  record.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                    ?.fees
                ),
                (o) => {
                  return o.taxFeeAmount;
                }
              ),
              businessunitDetails?.country
            ),
        },
        {
          title: "Previous Amount",
          dataIndex: "",
          key: "previousGrossPayment",
          align: "right",
          width: "17%",
          render: (record: any) =>
            currencyFormater(
              record?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                ?.totalEmployeeChargeable,
              businessunitDetails?.country
            ),
        },
        {
          title: "GST",
          dataIndex: "",
          key: "taxes",
          width: "13%",
          align: "right",
          render: (record: any) =>
            currencyFormater(
              _.sumBy(
                mustBeArray(
                  record.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
                    ?.fees
                ),
                (o) => {
                  return o.taxFeeAmount;
                }
              ),
              businessunitDetails?.country
            ),
        },
        {
          title: "Amount",
          dataIndex: "",
          key: "grossPayment",
          align: "right",
          width: "17%",
          render: (record: any) =>
            currencyFormater(
              record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
                ?.totalEmployeeChargeable,
              businessunitDetails?.country
            ),
        },
        {
          title: "Variance %",
          dataIndex: "variance",
          key: "variancePercent",
          align: "right",
          width: "11%",
          render: (data: any, record: any) => (
            <span
              className={getVarianceClassName({
                data: record.variance,
                highLightVarianceValue: highLightVarianceValue,
              })}
            >{`${getVarianceDisplayValue({ data })}`}</span>
          ),
        },
      ];
    } else {
      column = [
        {
          title: getPayrunColumnTitle({
            payrun: payrun,
            showRejectionReason,
          }),
          dataIndex: "externalEmployeeName",
          key: "name",
          width: "42%",
        },
        {
          title: "Previous Amount",
          dataIndex: "",
          key: "previousGrossPayment",
          align: "right",
          width: "21%",
          render: (record: any) =>
            `${currencyFormater(
              record?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                ?.totalEmployeeChargeable,
              businessunitDetails?.country
            )}`,
        },
        {
          title: "Amount",
          dataIndex: "",
          key: "grossPayment",
          align: "right",
          width: "21%",
          render: (record: any) =>
            `${currencyFormater(
              record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
                ?.totalEmployeeChargeable,
              businessunitDetails?.country
            )}`,
        },
        {
          title: "Variance %",
          dataIndex: "variance",
          key: "variancePercent",
          align: "right",
          width: "16%",

          render: (record: any) => (
            <span
              className={
                Math.abs(record) >= Number(highLightVarianceValue)
                  ? Number(record) > 0
                    ? "color-success"
                    : "color-danger"
                  : ""
              }
            >{`${record === "NaN"
              ? "N/A"
              : record < 0
                ? `(${Math.abs(record)})`
                : record
              }`}</span>
          ),
        },
      ];
    }
  } else {
    if (isEmpty(invoice)) {
      column = [
        {
          title: getPayrunColumnTitle({
            payrun: payrun,
            showRejectionReason,
          }),
          dataIndex: "externalEmployeeName",
          key: "name",
          width: "46%",
        },
        {
          title: "Amount",
          dataIndex: "",
          key: "grossPayment",
          align: "right",
          render: (record: any) =>
            `${currencyFormater(
              record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
                ?.totalEmployeeChargeable,
              businessunitDetails?.country
            )}`,
        },
      ];
    } else {
      column = [
        {
          title: getPayrunColumnTitle({
            payrun: payrun,
            showRejectionReason,
          }),
          dataIndex: "externalEmployeeName",
          key: "name",
          width: "50%",
        },
        {
          title: "GST",
          dataIndex: "",
          key: "taxes",
          width: "22%",
          align: "right",
          render: (record: any) =>
            currencyFormater(
              _.sumBy(
                mustBeArray(
                  record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
                    ?.fees
                ),
                (o) => {
                  return o.taxFeeAmount;
                }
              ),
              businessunitDetails?.country
            ),
        },
        {
          title: "Amount",
          dataIndex: "",
          key: "grossPayment",
          width: "23%",
          align: "right",
          render: (record: any) =>
            `${currencyFormater(
              record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
                ?.totalEmployeeChargeable,
              businessunitDetails?.country
            )}`,
        },
      ];
    }
  }
  return column;
};

// Varince Table
export const getVarianceColumns = ({ totalAvailablePeriod, periods }: any) => {
  let columns: any = [
    {
      title: `Employee`,
      dataIndex: "externalEmployeeName",
      key: "name",
      width: totalAvailablePeriod === 6 ? "30%" : "32%",
    },
  ];
  // Add columns for each cycles depending on number of cycles available (Max 6)
  let index: any = totalAvailablePeriod;
  if (!isNaN(index)) {
    while (index !== 0) {
      columns = columns.concat([
        {
          title: (
            <React.Fragment>
              <div>{`Pay Run Period ${index}`}</div>
              <div className="text-[10px] font-normal">
                {getVarianceStartEndDate({ summaryIndex: index, periods })}
              </div>
            </React.Fragment>
          ),
          key: index,
          align: "right",
          render: () => {
            return <React.Fragment />;
          },
        },
      ]);
      index = index - 1;
    }
  }
  // Add variance and SD column
  columns = columns.concat([
    {
      title: "Variance",
      key: "variance",
      align: "right",
      width: "10%",
    },
    {
      title: "Standard Deviation",
      key: "SD",
      align: "right",
      width: `${getVarianceSDColWidth({ totalPeriod: totalAvailablePeriod })}%`,
    },
  ]);

  return columns;
};

// Amendment Action History
export const amendmentActionHistoryColumns = [
  {
    title: "Date",
    key: "date",
    render: (record: any, i: any) => {
      return (
        <span key={i}>
          {getFormattedDate({
            date: record?.changeStatusDateTime,
            format: "DD/MM/YYYY h:mm a",
          })}
        </span>
      );
    },
  },
  {
    title: "User",
    key: "user",
    render: (record: any, i: any) => {
      return <span key={i}>{parseItems(record?.userInfo)?.UserName}</span>;
    },
  },
  {
    title: "Action",
    key: "action",
    render: (record: any, i: any) => {
      return <span key={i}>{record?.amendmentStatusName}</span>;
    },
  },
];

// Pay run change log columns
export const payrunChangeLogColumns = [
  {
    title: "Date",
    key: "date",
    width: "22%",
    render: (record: any, i: any) => {
      return (
        <span key={i}>
          {getFormattedDate({
            date: record?.changeStatusDateTime,
            format: "DD/MM/YYYY h:mm a",
          })}
        </span>
      );
    },
  },
  {
    title: "User",
    key: "user",
    width: "22%",
    render: (record: any, i: any) => {
      const userInfo = parseItems(record?.userInfo);
      return (
        <span key={i}>
          {/** Show First name and last name if values exist, otherwise show email address */}
          <If
            condition={!userInfo?.FirstName && !userInfo?.LastName}
            then={userInfo?.UserName}
            else={
              <>
                {userInfo?.FirstName} {userInfo?.LastName}
              </>
            }
          />
        </span>
      );
    },
  },
  {
    title: "Action",
    key: "payRunStatusName",
    width: "25%",
    render: (record: any, i: any) => {
      return <span key={i}>{record?.payRunStatusName}</span>;
    },
  },
  {
    title: "Note",
    key: "details",
    width: "31%",
    render: (record: any, i: any) => {
      const reason = parseItems(record?.details)?.Reason;
      const category = parseItems(record?.details)?.ErrorCategory;
      return (
        <Fragment key={i}>
          {category && (
            <div className="mb-1">
              <b>Category:</b> {category}
            </div>
          )}
          {reason && (
            <div>
              <b>Reason:</b> {reason}
            </div>
          )}
        </Fragment>
      );
    },
  },
];
